import React from 'react'

function MoreInfoLink(props) {
    const {text, icon, style} = props

    return (

        <div className='moreInfo' style={style}>
            <div className='moreInfoContent'>
                <p>{text}</p>
                <i className={icon}></i>
            </div>
        </div>
    )
}

export default MoreInfoLink