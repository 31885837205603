import React from 'react';
import { Link } from 'react-router-dom';

function SidebarItem(props) {
    return(
        <Link to={`/travadm/${props.path}`}>
            <div className='sidebarItem'>
                <div className='sidebarIconWrapper'>
                    <i className={props.icon}></i>
                </div>
                <div className='sidebarTextWrapper'>
                    <h1>{props.title}</h1>
                </div>
            </div>
        </Link>
    );
}

export default SidebarItem;