import React, { useEffect } from 'react'
import { useContext } from 'react'
import { Context } from '../../../../context/Context'
import ListItemActions from '../../../base/ListItemActions'



function PropertiesListItem(props) {
    const {allRoomTypes} = useContext(Context)
    const {id, fileType, city, isPublished, name, type, slug} = props

    function statusTag() {
        if(isPublished) {
            return(
                <div className='propertyStatusTag publishedTag'>
                    <p>Published</p>
                </div>
            )
        } else {
            return(
                <div className='propertyStatusTag draftTag'>
                    <p>Draft</p>
                </div>
            )
        }
    }

    return (
            <tr className='holTypeItem'>
                <td colSpan='2'>{name}</td>
                <td>{type}</td>
                <td>{city ? city : '-'}</td>
                <td>{0}</td>
                <td>{statusTag()}</td>
                <td><ListItemActions itemId={id} slug={slug} fileType={fileType} actionType='properties'/></td>
            </tr>
    )

}

export default PropertiesListItem;