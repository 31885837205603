import React, {useContext} from 'react'
import { useLocation, Link } from 'react-router-dom'
import { Context } from '../../context/Context'
import ReactTooltip from 'react-tooltip'


function ListItemActions(props) {

    const {
        sleep, 
        images, 
        getAllBatchImages, 
        deleteImageBatchItem, 
        updatePropertyType, 
        deletePropertyType, 
        updateHolidayType, 
        deleteHolidayType, 
        deleteCountry, 
        deleteAtoll, 
        deleteIsland, 
        deleteRoomTypes, 
        deleteProperty, 
        updateProperty, 
        deleteAmenity,
        deleteAmenityCategories, 
        getAllRoomTypes, 
        deleteTransportType,
        deletePackage,
        allRoomTypes
    } = useContext(Context)
    const path = useLocation().pathname

    const { actionType, itemId, slug, fileType } = props

    function actionButtons() {
        const editAction =
        <Link to={`${path}/${slug}/edit`}>
            <div data-tip='Edit' data-place='bottom' className='editAction'>
                <i className="ri-pencil-fill"></i>
            </div>
        </Link>

        const deleteAction = 
        <div data-tip='Delete' data-place='bottom'  className='deleteAction' onClick={handleDeleteClick}>
            <i className="ri-delete-bin-fill"></i>
        </div> 

        const restoreAction = 
        <div data-tip='Restore' data-place='bottom' className='restoreAction' onClick={handleRestoreClick}>
            <i className="ri-restart-fill"></i>
        </div>

        if (path.includes('trash')) {
            return (
                <>
                    {restoreAction}
                    {deleteAction}
                </>
            )
        }  else if(actionType==='amenitiesCategory') {
            return (
                <>
                    {deleteAction}
                </>
            )
        }else if(actionType==='transport') {
            return (
                <>
                    {deleteAction}
                </>
            )
        }else{
            return (
                <>
                    {editAction}
                    {deleteAction}
                </>
            )
        }
    }

function handleDeleteClick(event) {
        event.preventDefault()
        if (actionType === 'propertyTypes') {
            if(path.includes('trash')) {
                if (window.confirm('Are you sure you want to permanently delete this item?')) {
                    deletePropertyType(itemId)
                }
            } else {
                if (window.confirm('Are you sure you want to move this item to trash?')) {
                    updatePropertyType(itemId, null, null, null, true)
                }
            }
        } else if (actionType === 'holidayTypes') {
            if(path.includes('trash')) {
                if (window.confirm('Are you sure you want to permanently delete this item?')) {
                    deleteHolidayType(itemId, fileType)
                }
            } else {
                if (window.confirm('Are you sure you want to move this item to trash?')) {
                    updateHolidayType(itemId, null, null, null, null, null, null, true)
                }
            }
        } else if (actionType === 'countries') {
            if (window.confirm('Are you sure you want to permanently delete this item?')) {
                deleteCountry(itemId)
            }
        } else if (actionType === 'atolls') {
            if (window.confirm('Are you sure you want to permanently delete this item?')) {
                deleteAtoll(itemId)
            }
        } else if (actionType === 'islands') {
            if (window.confirm('Are you sure you want to permanently delete this item?')) {
                deleteIsland(itemId)
            }
        } else if (actionType === 'properties') {
            if(path.includes('trash')) {
                if (window.confirm('Are you sure you want to permanently delete this item?')) {
                    getAllBatchImages(itemId, 'properties')
                    if(images.length > 0) {
                        images.forEach(async (image)  => {
                            deleteImageBatchItem(image.id, image.fileType, 'properties')
                            await sleep(1000)
                        })
                    }
                    getAllRoomTypes(itemId)
                    if(allRoomTypes.length > 0) {
                        allRoomTypes.forEach(async (room)  => {
                            deleteRoomTypes(room.id, room.fileType)
                        })
                    }

                    deleteProperty(itemId, fileType)
                }
            } else {
                if (window.confirm('Are you sure you want to move this item to trash?')) {
                    updateProperty({propId: itemId ,isTrash: true})
                }
            }
        }
        else if (actionType === "amenities"){
            if(window.confirm('Are you sure you want to permanently delete this Item?')){
                deleteAmenity(itemId)
            }
        }
        else if (actionType === "amenitiesCategory"){
            if(window.confirm('Are you sure you want to permanently delete this Item?')){
                deleteAmenityCategories(itemId)
            }
        }
        else if (actionType === "transport"){
            if(window.confirm('Are you sure you want to permanently delete this Item?')){
                deleteTransportType(itemId)
            }
        }
        else if (actionType === "packages"){
            if(window.confirm('Are you sure you want to permanently delete this Item?')){
                deletePackage(itemId)
            }
        }
    }

    function handleRestoreClick() {
        if (actionType === 'propertyTypes') {
            if (window.confirm('Are you sure you want to restore this item?')) {
                updatePropertyType(itemId, null, null, null, false)
            }
        } else if (actionType === 'holidayTypes') {
            if (window.confirm('Are you sure you want to restore this item?')) {
                updateHolidayType(itemId, null, null, null, null, null, null, false)
            }
        } else if (actionType === 'properties') {
            if (window.confirm('Are you sure you want to restore this item?')) {
                updateProperty({propId: itemId, isTrash:false})
            }
        }
    }
    

    return(
        <div className='listItemActionsBar'>
            {actionButtons()}
            <ReactTooltip />
        </div>

        
    )
}

export default ListItemActions;