import React, {useContext} from 'react';
import { useLocation} from 'react-router-dom'

import {Context} from '../../context/Context'

function AdminHeader(props) {
    const location = useLocation()
    const path = location.pathname;

    const {handleLogout, user} = useContext(Context)

    const titleText = () => {
        if(path.includes("users")) {
            return("User")
        } else if (path.includes("properties")) {
            return("Properties")
        } else if (path.includes("property-types")) {
            return("Property Type")
        } else if (path.includes('holiday-types')) {
            return("Holiday Type")
        } else if (path.includes('locations')) {
            return("Locations")
        } else if (path.includes('amenities')) {
            return("Amenities")
        } else if (path.includes('maldives')) {
            return("Maldives")
        } else {
            return("")
        }
    }

    function handleClick(event) {
        event.preventDefault()
        if(window.confirm('Are you sure you want to sign out?')) {
            handleLogout()
        }
    }

    return(
        <header className='adminHeader'>
            <div className='adminHeaderStart'>
                <div className='adminHeaderLogo'>
                    <img className='adminLogo' src={process.env.PUBLIC_URL + '/resources/TravLogoWhite.png'} alt='logo'/>
                </div>
                <div className='adminHeaderTitle'>
                    <h1>{`${titleText()} Management`}</h1>
                </div>
            </div>
            <div className='adminHeaderEnd'>
                <div className='headerUserName'>
                <h1>{user.displayName}</h1>
                    <button className='formButton headerButton' onClick={handleClick}>Sign Out</button>
                </div>

            </div>
        </header>
    );
}

export default AdminHeader;