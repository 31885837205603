import React  from 'react'

function LoadingCard(props) {

    return(
        <div className='cardLoading'>
        </div>
    )
}

export default LoadingCard;