import React, {useContext} from 'react'
import AtollsListItem from './AtollsListItem'
import { Context } from '../../../../../context/Context';




function AtollsList() {

    const { allAtolls  } = useContext(Context)


    const atollItems = allAtolls.map(item => {
        return (
            <AtollsListItem
             key={item.id} 
             id={item.id} 
             name={item.name} 
             country={item.country} 
             code={item.code} 
             abbrev={item.abbreviation} 
             type={item.type}
             slug={item.slug} />
        )
    })

    return (
        <div className='propTypeList'>
            <table>
                <thead>
                    <tr>
                        <th colSpan='2'>Name</th>
                        <th>Abbr</th>
                        <th>Code</th>
                        <th>Country</th>
                        <th>Type</th>
                    </tr>
                </thead>
                <tbody>
                    {atollItems}
                </tbody>
            </table>
        </div>
    )
}

export default AtollsList;