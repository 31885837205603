import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Context } from '../../../context/Context'

function PropertyCard(props) {
    const {contextLoad, allProperties} = useContext(Context)
    const {name, rating, slug, imageUrl, property, price} = props
    const [packageImage, setPackageImage] = useState('')
    const [packagePropName, setPackagePropName] = useState('')

    useEffect(() => {
        if (property) {
            const currProperty = allProperties.find(item => item.id == property)
            setPackageImage(currProperty.downloadUrl)
            setPackagePropName(currProperty.name)
        }
    }, [allProperties])

    function starRatingDisplay() {
        const stars = []
        if(rating) {
            for(var i = 0; i < parseInt(rating); i++){
                stars.push(<i className="ri-star-fill" key={i}></i>)
            }
            return (stars)
        }
    }

    return(
        <Link to={property ? `/packages/${slug}` : `/properties/${slug}`} target='_blank'>
        <div className='propertyCardContainer'>
            <div className='cardInfo'>
                <p>{name}</p>
                {
                    property &&
                    <div className='packPropName'>
                        <p>{packagePropName}</p>
                    </div>
                }
                {
                property ?
                <div className='packagePrice'>
                    <p>{price}</p>
                </div>
                :
                <div className='propRating'>
                    {starRatingDisplay()}
                </div>
                }
            </div>
            <img src={packageImage ? packageImage : imageUrl ? imageUrl : `${process.env.PUBLIC_URL}/resources/placeholderImage.png`} alt=''/>
        </div>
        </Link>
    )

}

export default PropertyCard;