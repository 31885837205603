import React, { useState } from 'react'
import nationalities from '../propertiespage/Nationalities'
import emailjs from 'emailjs-com';



function PackageBookingForm(props) {

    const {
        name,
        price,
        startDate,
        endDate,
        propName,
        setFormToggle
    } = props

    const [fullName, setFullName] = useState('')
    const [email, setEmail] = useState('')
    const [contactNo, setContactNo] = useState('')
    const [nationality, setNationality] = useState('default')
    const [adults, setAdults] = useState('')
    const [children, setChildren] = useState('')
    const [infants, setInfants] = useState('')

    const asterisk = <span style={{ color: 'red', fontSize: '13px' }}> * </span>

    function handleNameChange(event) {
        const { value } = event.target
        setFullName(value)
    }

    function handleEmailChange(event) {
        const { value } = event.target
        setEmail(value)
    }

    function handleContactChange(event) {
        const { value } = event.target

        setContactNo(value)
    }

    const nationalityItems = nationalities.map((item, index) => {
        return (
            <option key={index} value={item}>{item}</option>
        )
    })

    function handleNationalityChange(event) {
        const { value } = event.target
        setNationality(value)
    }

    function handleAdultChange(event) {
        const { value } = event.target
        setAdults(value)
    }

    function handleChildrenChange(event) {
        const { value } = event.target
        setChildren(value)
    }

    function handleInfantChange(event) {
        const { value } = event.target
        setInfants(value)
    }

    function handleSubmitClick(event) {
        event.preventDefault()

        let data = {
            name: name,
            duration: `${startDate} - ${endDate}`,
            price: price,
            propName: propName,
            fullName: fullName,
            email: email,
            contactNo: contactNo,
            nationality: nationality,
            adults: adults,
            children: children,
            infants: infants
        }

        if (fullName && email && contactNo && adults && nationality) {
            emailjs.send('default_service', 'template_el3wuuj', data, 'user_IHT7ECH7AG4jARD9J1jpt')
            .then(
                (response) => {
                    console.log(response.status, response.text)
                    window.alert('Your booking inquiry has been submitted successfuly!')
                    setFormToggle(false)
                },

                (err) => {
                    console.log(err)
                    window.alert('An error occured, please reload the page and try again.')
                    setFormToggle(false)
                }
            );
        }
    }

    return (
        <div>
            <div className='formTitle'>
                <div className='closeBar'>
                    <i className="ri-close-circle-line" onClick={() => { setFormToggle(false) }}></i>
                </div >
                <h2>Booking Inquiry for <br /><span>{name}<br />{propName}<br /></span></h2>
            </div>
            <form className='bookingForm'>
                <div>
                    <div className='bookingFormFull'>
                        <label>Full Name{asterisk}</label>
                        <input type='text' className='formInput' onChange={handleNameChange} value={fullName} />
                    </div>
                    <div className='bookingFormRow'>
                        <div className='bookingFormHalf'>
                            <label>Email Address{asterisk}</label>
                            <input type='email' className='formInput' onChange={handleEmailChange} value={email} />
                        </div>
                        <div className='bookingFormHalf'>
                            <label>Contact Number{asterisk}</label>
                            <input type='tel' className='formInput' onChange={handleContactChange} value={contactNo} />
                        </div>
                    </div>
                    <div className='bookingFormRow'>
                        <div className='bookingFormTri'>
                            <label>Adults{asterisk}</label>
                            <input type='number' className='formInput cordNumber' onChange={handleAdultChange} value={adults} />
                            <label className='formInstruction'> 12 years and above.</label>
                        </div>
                        <div className='bookingFormTri'>
                            <label>Children</label>
                            <input type='number' className='formInput cordNumber' onChange={handleChildrenChange} value={children} />
                            <label className='formInstruction'> 2 years to 11 years.</label>
                        </div>
                        <div className='bookingFormTri'>
                            <label>Infants</label>
                            <input type='number' className='formInput cordNumber' onChange={handleInfantChange} value={infants} />
                            <label className='formInstruction'> Below 2 years of age.</label>
                        </div>
                    </div>
                    <div className='bookingFormRow'>
                        <div className='bookingFormFull'>
                            <label>Nationality{asterisk}</label>
                            <select value={nationality} className='formInput' onChange={handleNationalityChange}>
                                <option value='default'>Select Nationality...</option>
                                {nationalityItems}
                            </select>
                        </div>
                    </div>
                    <div className='bookingFormButtonRow'>
                        <button className='formButton bookingButton' onClick={handleSubmitClick}>Submit</button>
                        <button className='formButton cancelBooking' onClick={(e) => {
                            e.preventDefault()
                            setFormToggle(false)
                        }}>Cancel</button>
                    </div>

                </div>
            </form>
        </div>
    )

}

export default PackageBookingForm