import React, { useState, useContext, useEffect } from 'react';
import { useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Context } from '../../../../../context/Context'

function CountriesFormContainer() {

    const [name, setName] = useState('')
    const [code, setCode] = useState('')
    const [countryId, setCountryId] = useState('')
    const [slug, setSlug] = useState('')

    const {createCountry, allCountries, updateCountry, toastNotification} = useContext(Context)
    const history = useHistory()
    const nameRef = useRef(null)
    const codeRef = useRef(null)
    const path = useLocation().pathname
    const slugParam = useParams().slug

    useEffect(() => {
        if(path.includes('edit')) {
            var country = allCountries.find(item=>item.slug === slugParam)
            setName(country.name)
            setCode(country.code)
            setCountryId(country.id)
        }
    }, [])

    const asterisk = <span style={{color: 'red'}}> * </span>

    function handleNameChange(event) {
        const {value} = event.target
        var slugValue = value.replace(/\s+/g, '-').toLowerCase()
        setName(value)
        setSlug(slugValue)
    }

    function handleCodeChange(event) {
        const {value} = event.target
        setCode(value)
    }

    function handleSaveClick(event) {
        event.preventDefault()
        if (!allCountries.find(item=>item.slug === slug)) {
            if (path.includes('edit')) {
                if(window.confirm('Are you sure you want to save changes to this country?')) {
                    updateCountry(countryId, name, code, slug)
                    toastNotification('Changes saved successfully.')
                    history.push('/travadm/locations/countries', null)
                }
            } else {
                if (name && code) {
                    nameRef.current.classList.remove('invalidInput') 
                    codeRef.current.classList.remove('invalidInput')     
                    if (window.confirm('Are you sure you want to add this country?')) {
                        createCountry(name, code.toUpperCase(), slug)
                        history.push('/travadm/locations/countries', null)
                    }
                } else {
                    nameRef.current.classList.add('invalidInput')
                    codeRef.current.classList.add('invalidInput')
                    window.alert('Please ensure all fields highlighted in red contain a value.')
                }
            }
        } else {
            window.alert('This country already exists. Please add a unique country.')

        } 
        
    }


    function handleCancelClick(event) {
        event.preventDefault()
        if (name || code) {
            if (window.confirm('Are you sure you want to cancel adding this property type? All changes will be lost.')) {
                history.push('/travadm/locations/countries', null)
            }
        } else {
            history.push('/travadm/locations/countries', null)
        }
    }


    return (
        <div className='countriesFormContainer'>
            <form>
            <div className='countriesFieldGroup'>
                <div className='countriesInputWrapper countryNameInput'>
                    <label>
                        Country Name{asterisk}:
                    </label>
                    <input 
                        className='formInput' 
                        placeholder='Enter country name (ex: Maldives)'
                        type='text'
                        onChange={handleNameChange}
                        value={name}
                        ref={nameRef}
                    />
                </div>
                <div className='countriesInputWrapper'>

                    <label>
                        Country Code{asterisk}:
                    </label>
                    <input 
                        className='formInput countryCodeInput' 
                        placeholder='Enter Country Code (ex: MV)'
                        type='text'
                        onChange={handleCodeChange}
                        value={code}
                        ref={codeRef}
                    />
                </div>
                </div>
                <div className='userFormButtonRow'>
                    <button className='formButton saveButton' onClick={handleSaveClick} >{path.includes('edit') ? 'Update' : 'Add'}</button>
                    <button className='formButton cancelButton' onClick={handleCancelClick}>Cancel</button>
                </div>
            </form>
            <p>All fields marked with [{asterisk}] are mandatory</p>
        </div>
    )
}

export default CountriesFormContainer;