import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AtollsList from './atolls/AtollsList';
import CountriesList from './countries/CountriesList';
import IslandsList from './islands/IslandsList';
import LocationsMenu from './LocationsMenu';



function LocationsListContainer() {
    return (

        <div className='locationsListContainer'>
            <LocationsMenu />
            <Switch>
                <Route exact path='/travadm/locations/countries'>
                    <CountriesList />
                </Route>

                <Route exact path='/travadm/locations/atolls'>
                    <AtollsList />
                </Route>

                <Route exact path='/travadm/locations/islands'>
                    <IslandsList />
                </Route>
            </Switch>  
        </div>
    )
}


export default LocationsListContainer;