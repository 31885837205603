import React, { useContext, useRef, useState } from 'react'
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { Context } from '../../context/Context';
import MainFooter from '../shared/MainFooter';
import MainHeader from '../shared/MainHeader';
import Modal from 'react-modal';
import BookingForm from './BookingForm';
import { Helmet } from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';
import FbImageLibrary from 'react-fb-image-grid'





function RoomDetails(props) {
    const { getAllRoomTypes, allRoomTypes, setAllRoomTypes, allProperties, allAmenities, allAmenitiesCategories, roomTypeImages, setRoomTypeImages, getAllBatchImages } = useContext(Context)
    const [currentRoomType, setCurrentRoomType] = useState('')
    const [desc, setDesc] = useState('')
    const [parentProp, setParentProp] = useState('')
    const [parentPropObj, setParentPropObj] = useState('')
    const [roomId, setRoomId] = useState('')
    const [name, setName] = useState('')
    const [downloadUrl, setDownloadUrl] = useState('')
    const [adultCap, setAdultCap] = useState('')
    const [childCap, setChildCap] = useState('')
    const [featuredAmenities, setFeatutredAmenities] = useState([])
    const [size, setSize] = useState('')
    const [imageList, setImageList] = useState([])
    // const [amenities, setAmenities] = useState([])
    // const [passedAmenities, setPassedAmenities] = useState([]) 

    const [formToggle, setFormToggle] = useState(false)

    const slugParam = useParams().slug
    const codeParam = useParams().code
    var globalAmenities = useRef()

    useEffect(() => {
        try {
            const prop = allProperties.find(item => item.slug === slugParam)
            if (prop) {
                setParentProp(prop.id)
                setParentPropObj(prop)
                getAllRoomTypes(prop.id)
            }
            return setAllRoomTypes([])
        } catch (e) {
            console.log('A', e)
        }
    }, [allProperties])

    useEffect(() => {
        try {
            const currRoom = allRoomTypes.find(item => item.code === codeParam)

            if (currRoom) {
                setCurrentRoomType(currRoom)
                setRoomId(currRoom.id)
                setName(currRoom.name)
                setDesc(currRoom.desc)
                setDownloadUrl(currRoom.downloadUrl)
                setAdultCap(currRoom.adultCapacity)
                setChildCap(currRoom.childCapacity)
                setSize(currRoom.size)
                getAllBatchImages(currRoom.id, 'properties/roomTypes')

                if (currRoom.featuredAmenities) {
                    var filteredFeatAmenities = []
                    currRoom.featuredAmenities.map(item => filteredFeatAmenities.push(allAmenities.find(amenity => amenity.id === item)))
                    setFeatutredAmenities(filteredFeatAmenities)
                }

                if (currRoom.amenities) {
                    var filteredAmenities = []
                    currRoom.amenities.map(item => filteredAmenities.push(allAmenities.find(amenity => amenity.id === item)))
                    globalAmenities.current = filteredAmenities.filter(item => typeof (item) !== 'undefined')
                    // setAmenities(filteredAmenities)
                    // var currAmenities = getAmenities(currRoom.amenities)
                    // console.log(currAmenities)
                    // setAmenities(currAmenities)                
                }

            }
        } catch (e) {
            console.log('D', e)
        }
    }, [allRoomTypes, allAmenities])

    useEffect(() => {
        const currImages = []
        currImages.push(downloadUrl)
        roomTypeImages.map(item => {
            currImages.push(item.imageUrl)
        })
        setImageList(currImages)
    }, [roomTypeImages])


    function handleBookClick() {
        setFormToggle(true)
    }

    const featuredAmenityElements = featuredAmenities.map(item => {
        return (
            <div className={name ? 'featuredItem' : 'detailLoading'}>
                <FontAwesomeIcon
                    icon={item.icon}
                />
                <p>{item.name}</p>
            </div>
        )
    })

    const amenityElements = allAmenitiesCategories.map(item => {
        try {
            if (globalAmenities.current) {
                const currAmen = globalAmenities.current.filter(a => a.category === item.id)
                if (currAmen.length !== 0) {
                    return (
                        <div key={item.id} className='amenityCatItem'>
                            <h3>{item.name}</h3>
                            {currAmen.map(i => {
                                return (
                                    <div key={i.id} className={name ? 'amenityItem' : 'detailLoading'}>
                                        <FontAwesomeIcon
                                            icon={i.icon}
                                        />
                                        <p>{i.name}</p>
                                    </div>
                                )
                            })}

                        </div>
                    )
                }
            }
        } catch (e) {
            console.log('F', e)
        }
    })



    return (
        <>
            <Modal
                isOpen={formToggle}
                contentLabel="Booking Form"
                ariaHideApp={false}
                className='bookingFormModal'
                overlayClassName="bookingFormOverlay"
            >
                <BookingForm
                    formToggle={formToggle}
                    setFormToggle={setFormToggle}
                    room={currentRoomType}
                    property={parentPropObj}
                />
            </Modal>
            <MainHeader />
            <Helmet>
                <title>{`${name}`}</title>
            </Helmet>
            <div className='roomPageContainer'>
                <p className='propBackLink'><Link to={`/properties/${parentPropObj.slug}`}><i className="ri-arrow-left-line"></i>Back to {parentPropObj.name}</Link></p>
                <div className='roomPageWrapper'>
                    <div className='roomBanner'>
                        <div className='roomDetailSection'>
                            <div className='roomImageWrapper'>
                                <div className='roomImageGrid'>
                                    {/* <PropertiesCarousel propId={roomId} featuredImage={downloadUrl} cat={'properties/roomTypes'} /> */}
                                    <FbImageLibrary images={imageList} renderOverlay={() => 'View Image'} />
                                </div>
                                <div className='roomIntro'>
                                    <h2 className={name ? '' : 'detailLoading'}>{name}</h2>
                                    <div className='roomInformationHighlights'>
                                        {
                                            <div className='roomInfoItems'>
                                                <div className={name ? 'highlightItem' : 'detailLoading'}>
                                                    <i className="ri-shape-fill" />
                                                    <p>{`${size} sq ft`}</p>
                                                </div>
                                                <div className={name ? 'highlightItem' : 'detailLoading'}>
                                                    <i className="ri-user-fill"></i>
                                                    <p>{adultCap && `Sleeps ${adultCap} ${adultCap > 1 ? 'Adults' : 'Adult'}`}</p>
                                                </div>
                                                <div className={name ? 'highlightItem' : 'detailLoading'}>
                                                    <i className="ri-parent-fill"></i>
                                                    <p>{adultCap && `Sleeps ${childCap} ${childCap > 1 ? 'Children' : 'Child'}`}</p>
                                                </div>
                                            </div>
                                        }
                                        <div className='roomFeaturedAmenities'>
                                            {featuredAmenityElements}
                                        </div>
                                    </div>
                                    {
                                        desc ?
                                            <div className='roomDesc'>
                                                <div className='roomDescText'>
                                                    {desc}
                                                </div>
                                            </div>
                                            :
                                            ''
                                    }
                                    <div className='roomDetailButtonRow'>
                                        <button className={name ? 'bookingButton' : 'detailLoading'} onClick={handleBookClick}>Book this room</button>
                                    </div>
                                    <div className='roomAmenitiesSection'>
                                        {
                                            globalAmenities.current ?
                                                <div className={name ? 'roomAmenitiesList' : 'detailLoading'}>
                                                    <div className='amenityHeading'>
                                                        {globalAmenities.current && <h2>Room Amenities</h2>}
                                                    </div>
                                                    <div className='amenityElements'>
                                                        {amenityElements}
                                                    </div>
                                                </div>
                                                :
                                                ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <MainFooter />
        </>
    )
}

export default RoomDetails

