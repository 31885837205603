import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router'
import ImageElement from './ImageElement'


function BatchImageDisplay(props) {
    const path = useLocation().pathname
    const {images, parentId, cat} = props
    var imageElements = []


    imageElements = images.map((image, index) => {
        return (
            <ImageElement key={image.id} id={image.id} imageUrl={image.imageUrl} fileType={image.fileType} index={index} cat={cat}/>
        )
    })

    return (
        <div className='imageBatchContainer'>
            <div className='batchImageDisplayArea formInput'>
                <div  className='batchImageGridCContainer'>
                    {imageElements}
                </div>
            </div>
        </div>
    )
}

export default BatchImageDisplay;