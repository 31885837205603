import React from 'react'
import ListItemActions from '../../../base/ListItemActions'


function TransportListItem(props) {

    const {id, name, slug} = props

    return (
        <>
            <tr className='propertyItem'>
                <td colSpan='2' className='transportFormColumn'>{name}</td>
                <td className='transportSlugColumn'>{slug}</td>
                <td><ListItemActions itemId={id} actionType='transport' slug={slug}/></td>
            </tr>
        </>

    )
}

export default TransportListItem;