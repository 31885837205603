import React from 'react'
import HolTypeList from './HolTypeList'


function HolTypeListContainer() {
    return(
        <div className='propTypeListContainer'>
            <HolTypeList />
        </div>
    )

}

export default HolTypeListContainer;