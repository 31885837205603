import React, {useContext, useEffect, useState} from 'react'
import ListItemActions from '../../../base/ListItemActions';
import TransportListItem from './TransportListItem';
import ReactTooltip from 'react-tooltip'
import { Context } from '../../../../context/Context';

function TransportList() {
    const [addToggle, setAddToggle] = useState(false)
    const [name, setName] = useState('')
    const [slug, setSlug] = useState('')
    const {createTransportType, allTransportTypes} = useContext(Context)

    useEffect(() => {
        if(!addToggle) {
            setName('')
            setSlug('')
        }
    },[addToggle])


    function handleAddClick() {
        setAddToggle(true)
    }

    function handleCancelClick() {
        setAddToggle(false)
        setName('')
        setSlug('')
    }

    function handleSaveClick() {
        const transportObj = {
            transportName: name,
            slug: slug,
        }

        if(!allTransportTypes.filter(item => item.slug === slug).length > 0)
            if (name) {
                createTransportType(transportObj, setAddToggle)
            } else {
                window.alert('Please ensure the name field contains a value before adding a transport item.')
            }
        else {
            window.alert('This transport type already exists.')

        }
    }



    const cancelAction = 
    <div data-tip='Cancel' data-place='bottom'  className='deleteAction' onClick={handleCancelClick}>
        <i className="ri-close-circle-line"></i>
    </div> 

    const saveAction = 
    <div data-tip='Add' data-place='bottom' className='restoreAction' onClick={handleSaveClick}>
        <i className="ri-add-circle-line"></i>
    </div>


    const transportListItems = allTransportTypes.map(item => {
        return(
            <TransportListItem key={item.id} id={item.id} name={item.name} slug={item.slug} />
        )
    })

    console.log(name)
    return (
        <div className='propTypeList'>
            <table>
                <thead>
                    <tr>
                        <th colSpan='2'>Name</th>
                        <th>Slug</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        !addToggle ?
                        <tr className='propertyItem addRow' onClick={handleAddClick}>
                            <td colSpan='2'>Click to add new item...</td>
                            <td></td>
                            <td></td>
                        </tr>
                        :
                        <tr className='propertyItem addRow'>
                            <td colSpan='2' className='transportFormColumn'>
                                <input 
                                className='formInput transportInput' 
                                placeholder='Enter transport name...'
                                type='text'
                                value={name}
                                onChange = {(event) => {
                                    var {value} = event.target
                                    var slugValue = value.replace(/\s+/g, '-').toLowerCase()
                                    setName(value)
                                    setSlug(slugValue)
                                }}
                                />
                            </td>
                            <td className='transportSlugColumn'>{slug}</td>
                            <td>
                            <div className='listItemActionsBar'>
                                {saveAction}
                                {cancelAction}
                                <ReactTooltip />
                            </div>
                            </td>
                        </tr>
                    }
                    {transportListItems}
                </tbody>
            </table>
        </div>
    )
}

export default TransportList;