import React, { useContext, useState, useEffect } from 'react'
import { Context } from '../../../../context/Context'
import HolTypeListItem from './HolTypeListItem'
import {useLocation} from 'react-router-dom'
import ReactTooltip from 'react-tooltip'



function HolTypeList() {
    const location = useLocation()
    const [activeHolidayTypes, setActiveHolidayTypes] = useState([])
    const [trashHolidayTypes, setTrashHolidayTypes] = useState([])

    const {allHolidayTypes} = useContext(Context)

    useEffect(() => {
        var activeHolTypes = []
        var inactiveHolTypes = []

        activeHolTypes = allHolidayTypes.filter(item => !item.isTrash)
        inactiveHolTypes = allHolidayTypes.filter(item => item.isTrash)

        setActiveHolidayTypes(activeHolTypes.sort())
        setTrashHolidayTypes(inactiveHolTypes.sort())
    }, [allHolidayTypes]) 


    const holidayTypes = activeHolidayTypes.map(item  => {
            return(
                <HolTypeListItem key={item.id} id={item.id} imageUrl={item.imageURL} name={item.name} desc={item.description} slug={item.slug} fileType={item.fileType}/>
            )
        }
    )


    const trashedHolidayTypes = trashHolidayTypes.map(item  => {
        return(
            <HolTypeListItem key={item.id} id={item.id} imageUrl={item.imageURL} name={item.name} desc={item.description} slug={item.slug} fileType={item.fileType}/>
        )
    }
)


    return (
        <div className='propTypeList'>
            <table>
                <thead>
                    <tr>
                        <th colSpan='2'>Name</th>
                        <th>Slug</th>
                    </tr>
                </thead>
                <tbody>
                    {!location.pathname.includes('trash') ? holidayTypes : trashedHolidayTypes}
                </tbody>
            </table>
            <ReactTooltip />
        </div>
    )
}

export default HolTypeList;