import React, {useContext, useState, useRef} from 'react'
import { Context } from '../../../../context/Context';
import { useHistory, useLocation, useParams } from 'react-router-dom';


function AmenitiesCatForm(){
    const {createAmenityCategories, allAmenitiesCategories, updateAmenityCategories, toastNotification} = useContext(Context)
    const [name, setName] = useState('')
    //const [id, setID] = useState('')
    const history = useHistory()
    const nameRef = useRef(null)
    const [slug, setSlug] = useState('')
    const path = useLocation().pathname
    //const slugParam = useParams().slug

    function handleSaveClick(event) {
        event.preventDefault()

        
        if(allAmenitiesCategories.find(item=>item.slug===slug)) {
            if (path.includes('edit')) {
                if(window.confirm('Are you sure you want to save changes to the Amenity?')) {
                    updateAmenityCategories(name, slug)
                    toastNotification('Changes saved successfully.')
                    history.push('/travadm/amenities', null)
                }
            } else {
                window.alert('There is already an Amenity Category of this name, please create a new  Amenity Category')
            }
        } else {
            if (name) {
                nameRef.current.classList.remove('invalidInput')       
                if (window.confirm('Are you sure you want to add the new Amenity Category?')) {
                    createAmenityCategories(name, slug)
                    history.push('/travadm/amenities', null)
                }
            } else {
                if (!name){
                    nameRef.current.classList.add('invalidInput')
                    window.alert('Please ensure all fields highlighted in red contain a value.')
                }
                else{
                     window.alert('There is already an Amenity Category of this name, please create a new  Amenity')
    
                }
            }
        }
    }

    function handleNameChange(event) {
        const {value} = event.target
        var slugValue = value.replace(/\s+/g, '-').toLowerCase()
        setName(value)
        setSlug(slugValue)
    }

    function handleCancelClick(event) {
        event.preventDefault()
        if (name) {
            if (window.confirm('Are you sure you want to cancel adding this Amenity Category? All changes will be lost.')) {
                history.push('/travadm/amenities', null)
            }
        } else {
            history.push('/travadm/amenities', null)
        }
    }

    return(
        <div>
            <form style={{marginLeft:10}}>
            <input 
                        className='formInput propTypeInput' 
                        placeholder='Enter Amenity Categories (ex: Wi-Fi)'
                        type='text'
                        onChange={handleNameChange}
                        value={name}
                        ref={nameRef}
                    />

                <button className='formButton saveButton' onClick={handleSaveClick} >Add</button>
                <button className='formButton cancelButton' onClick={handleCancelClick}>Cancel</button>
            </form>
        </div>
    )
}

export default AmenitiesCatForm;