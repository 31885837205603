import React, { useState, useContext, useEffect } from 'react';
import { useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Context } from '../../../../context/Context'

function HolTypeFormContainer() {

    const [image, setImage] = useState('')
    const [imageUrl, setImageUrl] = useState('')
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [slug, setSlug] = useState('')
    const [holTypeId, setHolTypeId] = useState('')
    const [fileType, setFileType] = useState('')
    const {createHolidayType, allHolidayTypes, updateHolidayType, toastNotification} = useContext(Context)
    const history = useHistory()
    const nameRef = useRef(null)
    const imageRef = useRef(null)
    const imageContainerRef  = useRef(null)
    const path = useLocation().pathname
    const slugParam = useParams().slug

    useEffect(()=> {
        console.log(slug)
        if(path.includes('edit')) {
            var holidayType = allHolidayTypes.find(item=>item.slug === slugParam)
            setName(holidayType.name)
            setDescription(holidayType.description)
            setHolTypeId(holidayType.id)
            setFileType(holidayType.fileType)
            setImageUrl(holidayType.imageURL)
        }
    }, [])

    const asterisk = <span style={{color: 'red'}}> * </span>

    function handleNameChange(event) {
        const {value} = event.target
        var slugValue = value.replace(/\s+/g, '-').toLowerCase()
        setName(value)
        setSlug(slugValue)
    }

    function handleDescChange(event) {
        const {value} = event.target
        setDescription(value)
    }

    function handleSaveClick(event) {
        event.preventDefault()
        if(!allHolidayTypes.find(item=>item.slug===slug)) {
            if (path.includes('edit')) {
                if(window.confirm('Are you sure you want to save changes to this holiday type?')) {
                    updateHolidayType(holTypeId, image, fileType, null, name, description, slug)
                    toastNotification('Changes saved successfully.')
                    history.push('/travadm/holiday-types', null)
                }
            } else {
                if (name && image) {
                    nameRef.current.classList.remove('invalidInput')
                    imageContainerRef.current.classList.remove('invalidInput')          
                    if (window.confirm('Are you sure you want to add this holiday type?')) {
                        createHolidayType(image, name, description, slug)
                        history.push('/travadm/holiday-types', null)
                    }
                } else {
                    nameRef.current.classList.add('invalidInput')
                    imageContainerRef.current.classList.add('invalidInput')
                    window.alert('Please ensure all fields highlighted in red contain a value.')
                }
            }
        } else {
            window.alert('This holiday type already exists. Please add a unique holiday type.')
        }
    }


    function handleCancelClick(event) {
        event.preventDefault()
        if (image || name || description) {
            if (window.confirm('Are you sure you want to cancel adding this holiday type? All changes will be lost.')) {
                history.push('/travadm/holiday-types', null)
            }
        } else {
            history.push('/travadm/holiday-types', null)
        }
    }

    function handleImageChange(event) {
        var file = event.target.files[0]
        setImage(file)
        if (event.target.files && event.target.files[0]) {
            var reader = new FileReader();

            reader.onload = e => {
                imageRef.current.src = e.target.result
            }
            reader.readAsDataURL(event.target.files[0])
        }
    }

    return (
        <div className='propTypeFormContainer'>
            <p>All fields marked with [{asterisk}] are mandatory</p>
            <form>
            <div className='propTypeFieldGroup'>
                <div className='formInput holTypeImageContainer' ref={imageContainerRef}>
                    <img
                    className="holTypeImage"
                    src={imageUrl ? imageUrl : process.env.PUBLIC_URL + '/resources/Placeholder.png'}
                    alt=""
                    ref={imageRef}
                    ></img>
                </div>

                <div className="holTypeImageWrapper">
                    <p className="subInstruction">Upload image:{asterisk}</p>
                        <div className="uploadRow">
                            <label htmlFor="file" className="substituteLabel">
                            {`${image || path.includes('edit') ? 'Change' : 'Upload'} Image`}
                            </label>
                            <p className="fileLabel">{image.name}</p>
                        </div>
                        <input
                            type="file"
                            id="file"
                            accept="image/*"
                            className="substituteImage"
                            onChange={handleImageChange}
                        ></input>
                </div>
            </div>    
            <div className='propTypeFieldGroup'>
                    <label>
                        Holiday Type Name{asterisk}:
                    </label>
                    <input 
                        className='formInput propTypeInput' 
                        placeholder='Holiday Type (ex: Honeymoon)'
                        type='text'
                        onChange={handleNameChange}
                        value={name}
                        ref={nameRef}
                    />
                </div>

                <div className='propTypeFieldGroup'>
                    <label>
                        Holiday Type Description:
                    </label>
                    <textarea 
                        className='formInput propTypeTextArea' 
                        placeholder='Type a short description for the holiday type here...'
                        type='text'
                        onChange={handleDescChange}
                        value={description}
                    />
                </div>
                <div className='userFormButtonRow'>
                    <button className='formButton saveButton' onClick={handleSaveClick} >{path.includes('edit') ? 'Update' : 'Add'}</button>
                    <button className='formButton cancelButton' onClick={handleCancelClick}>Cancel</button>
                </div>
            </form>
        </div>
    )
}

export default HolTypeFormContainer;