import React from 'react';

import { Route, Switch } from 'react-router'

import AdminActions from '../../base/AdminActions'
import Amenitiesform from './amenitiesform/Amenitiesform';
import AmenitiesList from './amenitieslist/Amenitieslist';




function AmenitiesContainer() {
    return (
        <div className='propTypeContainer'>
            <AdminActions />
            <Switch>
            <Route exact path='/travadm/amenities' component={AmenitiesList} />
            <Route exact path='/travadm/amenities/add' component={Amenitiesform} />
            <Route exact path='/travadm/amenities/:slug/edit' component={Amenitiesform} />

            </Switch> 
        </div>    
    )
}


export default AmenitiesContainer;