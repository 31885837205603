import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { ContextProvider } from './components/context/Context';
import {BrowserRouter as Router} from 'react-router-dom';


const firebase = require('firebase');
require('firebase/firestore')



var firebaseConfig = {
  apiKey: "AIzaSyAWZBXYvQ4L55p9XRWxkBX9qnfW7Mqcymo",
  authDomain: "travgomaldives-e3ab0.firebaseapp.com",
  databaseURL: "https://travgomaldives-e3ab0.firebaseio.com",
  projectId: "travgomaldives-e3ab0",
  storageBucket: "travgomaldives-e3ab0.appspot.com",
  messagingSenderId: "633748729963",
  appId: "1:633748729963:web:568bd69a7bb865359c412e",
  measurementId: "G-YJB61PLFK7"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

ReactDOM.render(
  <React.StrictMode>
    <ContextProvider>
      <Router>
        <App />
      </Router>
    </ContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
