import React from 'react';
import { Map, GoogleApiWrapper, Marker} from 'google-maps-react';
import { useState } from 'react';
import { useEffect } from 'react';

function GoogleMapsContainer(props) {
    const {long, lat, defaultOptions, style, google} = props
    const [cordinates, setCordinates] = useState({lng: long, lat: lat})
    useEffect(() => {
        setCordinates({lng: long, lat:lat})
    }, [long, lat])
    return (
        <div className='mapWrapper'>
            <Map
                containerStyle={{position: 'relative',width: '100&', height: '400px', borderRadius: '10px'}}
                google={google}
                zoom={15}
                center={{
                    lat: lat,
                    lng: long
                }}
                // defaultOptions={defaultOptions}
                style={style}
                defaultOptions={defaultOptions}
            >  
            <Marker position={cordinates} /><Marker/>
            </Map >
        </div>
    )

}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyAWZBXYvQ4L55p9XRWxkBX9qnfW7Mqcymo'
  })(GoogleMapsContainer);