import React, { useContext } from 'react'
import TransportList from './transportlist/TransportList'

function TransportContainer(props) {

    return (
        <div className='propTypeContainer'>
            <TransportList />
        </div>
    )
}

export default TransportContainer