import React, {useState, useEffect}from 'react';
import { Redirect, Switch } from 'react-router';

import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";


const Context = React.createContext();
const firebase = require('firebase')

function ContextProvider({children}) {
    const [emailError, setEmailError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [user, setUser] = useState('') //Current logged in user
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [emailVerified, setEmailVerified] = useState(false)
    const [toggled, setToggled] = useState(false)
    const [contextLoad, setContextLoad] = useState(false)
    const [allUsers, setAllUsers] = useState([])
    const [userObject, setUserObject] = useState('') //Firebase UserObject for user management
    const [userDetails, setUserDetails] = useState('') //Custom user details stored in firestore
    const [userDetailsLoaded, setUserDetailsLoaded] = useState(false) //User details for user management
    const [allPropertyTypes, setAllPropertyTypes] = useState([])
    const [allHolidayTypes, setAllHolidayTypes] = useState([])
    const [allCountries, setAllCountries] = useState([])
    const [allAtolls, setAllAtolls] = useState([])
    const [allIslands, setAllIslands] = useState([])
    const [allProperties, setAllProperties] = useState([])
    const [allAmenities, setAllAmenities] = useState([])
    const [allAmenitiesCategories, setAllAmenitiesCategories] = useState([])
    const [images, setImages] = useState([])
    const [roomTypeImages, setRoomTypeImages] = useState([])
    const [allRoomTypes, setAllRoomTypes] = useState([])
    const [allTransportTypes, setAllTransportTypes] = useState([])
    const [allRoomTypesLength, setAllRoomTypesLength] = useState(0)
    const [allPackages, setAllPackages] = useState([])

    

    function handleToggle(toggle = null) {
        if (toggle != null) {
            setToggled(toggle)
        } else {
            setToggled(!toggled)
        }
        
    }

    useEffect(() => {
        authListener();
        var presenceRef = firebase.database().ref("disconnectmessage");
        presenceRef.onDisconnect(() => {console.log('Disconnected')})



        firebase
        .firestore()
        .collection('users').orderBy('createdOn', 'desc')
        .onSnapshot(serverUpdate => {
            const users = serverUpdate.docs.map(_doc => {
                const data = _doc.data();
                data['id'] = _doc.id;
                return data;
            });
            setAllUsers(users);
        })


        firebase
        .firestore()
        .collection('propertyTypes')
        .onSnapshot(serverUpdate => {
            const types = serverUpdate.docs.map(_doc => {
                const data = _doc.data();
                data['id'] = _doc.id;
                return data;
            });
            setAllPropertyTypes(types);
        })


        firebase
        .firestore()
        .collection('holidayTypes')
        .onSnapshot(serverUpdate => {
            const types = serverUpdate.docs.map(_doc => {
                const data = _doc.data();
                data['id'] = _doc.id;
                return data;
            });
            setAllHolidayTypes(types);
        })

        firebase
        .firestore()
        .collection('countries').orderBy('name', 'asc')
        .onSnapshot(serverUpdate => {
            const countries = serverUpdate.docs.map(_doc => {
                const data = _doc.data();
                data['id'] = _doc.id;
                return data;
            });
            setAllCountries(countries);
        })

        firebase
        .firestore()
        .collection('atolls').orderBy('name', 'asc')
        .onSnapshot(serverUpdate => {
            const atolls = serverUpdate.docs.map(_doc => {
                const data = _doc.data();
                data['id'] = _doc.id;
                return data;
            });
            setAllAtolls(atolls);
        })


        firebase
        .firestore()
        .collection('islands').orderBy('code', 'asc')
        .onSnapshot(serverUpdate => {
            const islands = serverUpdate.docs.map(_doc => {
                const data = _doc.data();
                data['id'] = _doc.id;
                return data;
            });
            setAllIslands(islands);
        })

        firebase
        .firestore()
        .collection('properties').orderBy('createdOn', 'desc')
        .onSnapshot(serverUpdate => {
            const property = serverUpdate.docs.map(_doc => {
                const data = _doc.data();
                data['id'] = _doc.id;
                return data;
            });
            setAllProperties(property);
            setContextLoad(true)

        })

        firebase
        .firestore()
        .collection('amenities').orderBy('name', 'asc')
        .onSnapshot(serverUpdate => {
            const amenities = serverUpdate.docs.map(_doc => {
                const data = _doc.data();
                data['id'] = _doc.id;
                return data;
            });
            setAllAmenities(amenities);
    
        })

        firebase
        .firestore()
        .collection('amenitiescategories').orderBy('name', 'asc')
        .onSnapshot(serverUpdate => {
            const amenitiesCategories = serverUpdate.docs.map(_doc => {
                const data = _doc.data();
                data['id'] = _doc.id;
                return data;
            });
            setAllAmenitiesCategories(amenitiesCategories);
            
        })

        firebase
        .firestore()
        .collection('transport').orderBy('createdOn', 'desc')
        .onSnapshot(serverUpdate => {
            const transportTypes = serverUpdate.docs.map(_doc => {
                const data = _doc.data();
                data['id'] = _doc.id;
                return data;
            });
            setAllTransportTypes(transportTypes);
        })

        firebase
        .firestore()
        .collection('packages').orderBy('createdOn', 'desc')
        .onSnapshot(serverUpdate => {
            const packages = serverUpdate.docs.map(_doc => {
                const data = _doc.data();
                data['id'] = _doc.id;
                return data;
            });
            setAllPackages(packages)
        })

    },[])

    function authListener() {
        firebase.auth().onAuthStateChanged((user) => {
            if(user){
                setUser(user)
                setIsLoggedIn(true)
                getUserDetails(user.uid)
                if(user.emailVerified) {
                    setEmailVerified(true)
                }
            } else {
                setUser('')
                setIsLoggedIn(false)
            }
        })
    }

    function getUserDetails(uid) {
        var docRef = firebase.firestore().collection('users').doc(uid)

        docRef
        .get()
        .then(userDoc => {
            if(userDoc.exists) {
                setUserDetails(userDoc.data())
                setUserDetailsLoaded(true)
            }
        })
        .catch(error => {
            console.log(error)
        })
    }


    async function sendEmailVerification() {
        const newUser = firebase.auth().currentUser
        if(newUser) {
            if(!newUser.emailVerified){
                await newUser.sendEmailVerification()
            }
        }
    } 

    async function resetPassword(email){
        const auth = firebase.auth()
        await auth.sendPasswordResetEmail(email)
            .catch(error => {
                console.log(error)
                // eslint-disable-next-line default-case
                switch(error.code){
                    case "auth/user-not-found":
                        return(
                            alert("Your email is not registered with us")
                        )
                    
                }
            })
    }
 
    function handleLogin(email, password) {
        firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .catch(err => {
                // eslint-disable-next-line default-case
                switch(err.code){
                    case "auth/invalid-email":
                    case "auth/user-disabled":
                    case "auth/user-not-found":
                        setEmailError(err.message)
                        break;
                    case "auth/wrong-password":
                        setPasswordError(err.message)
                        break;
                }
            })
    }

    function handleLogout() {
        firebase.auth().signOut().then(() => {
                return(
                    <Switch><Redirect to ='/travadm-login' /></Switch>
                )

            }
        )
    }

    function handleRegister(email, password, name, level) {
        const registerUser = firebase.functions().httpsCallable('registerUser')
        registerUser({
            email: email, 
            password: password,
            displayName: name,
        }).then(response => {
            console.log(response)
            const userId = response.data.uid

            firebase
            .firestore()
            .collection('users')
            .doc(userId)
            .set({
                name: name,
                email: email,
                userLevel: level,
                disabled: false,
                createdOn: firebase.firestore.FieldValue.serverTimestamp()
            }).then(() => {
                toastNotification('User added successfully.')
                handleToggle(false)
            })
            .catch(error => {
                console.log('firestore error --- ', error)
            })
        }).catch(error => {
            console.log('error --- ',error)
        })  

    }

    function handleUserDelete(uid) {
        const deleteUser = firebase.functions().httpsCallable('deleteUser')
        deleteUser({
            uid: uid
        })
        .then(() => {
            firebase
            .firestore()
            .collection('users')
            .doc(uid)
            .delete()
            .then(toastNotification('User deleted successfully.'))
        })
        .catch(error => {
            console.log(error)
        })
    }

    function handleUserDisable(uid) {
        const disableUser = firebase.functions().httpsCallable('disableUser')
        disableUser({
            uid: uid
        })
        .then(()=>{
            firebase.firestore().collection('users').doc(uid).update({disabled: true}).then(toastNotification('User disabled successfully.'))
        })
        .catch(error => {
            console.log(error)
        })
    }

    function handleUserEnable(uid) {
        const disableUser = firebase.functions().httpsCallable('enableUser')
        disableUser({
            uid: uid
        })
        .then(()=>{
            firebase.firestore().collection('users').doc(uid).update({disabled: false}).then(toastNotification('User enabled successfully.'))
        })
        .catch(error => {
            console.log(error)
        })
    }

    function getUser(uid) {
        const getUserDetails = firebase.functions().httpsCallable('getUserDetails')
        getUserDetails({
            uid: uid
        })
        .then(response => {
            setUserObject(response.data)
            console.log(response.data)
        })
        .catch(error => {
            console.log(error)
            return null
        })
    }


    function createPropertyType(name, desc, slug) {
        if (name) {
            firebase
            .firestore()
            .collection('propertyTypes')
            .doc()
            .set({
                name: name,
                description: desc,
                slug: slug,
                isTrash: false
            })
            .then(() => {
                toastNotification('Property type added successfully.')
            })
            .catch(error => {
                console.log(error)
            })
        }
    }

    function deletePropertyType(propTypeId) {
        if (propTypeId) {
            firebase
                .firestore()
                .collection('propertyTypes')
                .doc(propTypeId)
                .delete()
                .then(toastNotification('Property type deleted successfully.'))
                .catch(error => {console.log(error)})
        }
    }

    function updatePropertyType(propTypeId, name=null, desc=null, slug=null, isTrash=null) {
        console.log('called', propTypeId)
        if (propTypeId) {
            if(name && slug) {
                firebase.firestore().collection('propertyTypes').doc(propTypeId).update({name: name, slug:slug})
                .catch(error => {
                    console.log(error)
                })
            }

            if(desc){
                firebase.firestore().collection('propertyTypes').doc(propTypeId).update({description:desc})
                .catch(error => {
                    console.log(error)
                })
            }

            if(isTrash !== null) {
                firebase.firestore().collection('propertyTypes').doc(propTypeId).update({isTrash:isTrash}).then(isTrash ? toastNotification('Property type moved to trash successfully.') : toastNotification('Property type restored successfully.'))
                .catch(error => {
                    console.log(error)
                })
            }
        }
    }


    //createHolidayType, allHolidayTypes, updateHolidayType
    function uploadImage(id, file, cat, parentId) {
        console.log('UPLOAD FUNCTION', id, file, cat, parentId)
        if(file && id) {
            const fileType = file.type.split('/').pop();
            const storage = firebase.storage();
            const storageRef = storage.ref(`user-uploads/${cat}/${id}.${fileType}`);
            const task = storageRef.put(file)
            task.on('state_changed', 
                function complete(snapshot) {
                        var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

                        if (percentage === 100) {
                        const interval = setInterval(() => {
                            storageRef.getDownloadURL().then(function (url) {
                                if(cat === 'holiday-types') {
                                    updateHolidayType(id, null, fileType, url, null, null, null, null)
                                } else if (cat === 'properties') {
                                    updateProperty({propId: id, downloadUrl: url, fileType: fileType})
                                } else if(cat === 'properties/roomTypes') {
                                    updateRoomType(id, url, fileType, parentId, '')
                                }
                                clearInterval(interval)
                            }).catch(function (error) {
                                console.log(error.message, error.code)
                            })
                        }, 500)
                    }
                }
            )
        }
    }

    function sleep(milliseconds){
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }


    function uploadImageBatch(image, cat, parentId) {
        const fileType = image.type.split('/').pop();
        const storage = firebase.storage();

        if (image && cat) {
            firebase
            .firestore()
            .collection('imageBatch')
            .add({
                parentId: parentId,
                downloadUrl: '',
                cat: cat,
                fileType: fileType
            })
            .then(item => {
                const storageRef = storage.ref(`user-uploads/${cat}/batch/${item.id}.${fileType}`);
                const task = storageRef.put(image)
                task.on('state_changed', 
                    function complete(snapshot) {
                            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                            if (percentage === 100) {
                            const interval = setInterval(() => {
                                storageRef.getDownloadURL().then(function (url) {
                                    updateImageBatch({downloadUrl: url, id: item.id})
                                    if(cat === 'properties') {
                                        setImages(prevState => [...prevState, {id:item.id, imageUrl: url, fileType: fileType, status: 'new'}])
                                    } else if (cat === 'properties/roomTypes') {
                                        setRoomTypeImages(prevState => [...prevState, {id:item.id, imageUrl: url, fileType: fileType, status: 'new'}])
                                    }
                                    clearInterval(interval)
                                }).catch(function (error) {
                                    console.log(error.message, error.code)
                                })
                            }, 500)
                        }
                    }
                )
            })
            .catch(error => {
                console.log(error)
            })
        }
    }

    async function updateImageBatch(imageBatchObj) {
        if (imageBatchObj.id) {
            if (imageBatchObj.downloadUrl) {
                firebase.firestore().collection('imageBatch').doc(imageBatchObj.id).update({downloadUrl: imageBatchObj.downloadUrl})
                .catch(error => {
                    console.log(error)
                })
            }
        }
    }

    function deleteImageBatchItem(id, fileType, cat) {
        const storage = firebase.storage();
        const storageRef = storage.ref(`user-uploads/${cat}/batch/${id}.${fileType}`);
        if (id && fileType) {
            firebase
                .firestore()
                .collection('imageBatch')
                .doc(id)
                .delete()
                .then(
                    storageRef.delete().then(() => {
                            if (cat === 'properties') {
                                setImages(images.filter(item => item.id !== id))
                            } else if (cat === 'properties/roomTypes') {
                                setRoomTypeImages(images.filter(item => item.id !== id))
                            }
                        }
                    )
                )
                .catch(error => {console.log(error)})
        }
    }

    function getAllBatchImages(parentId, cat) {
        var imagelist =[]
        if (parentId) {
            firebase
            .firestore()
            .collection('imageBatch')
            .where('parentId', '==', parentId )
            .get()
            .then(function(querySnapshot) {
                const images = querySnapshot.docs.map(_doc => {
                    const data = _doc.data();
                    data['id'] = _doc.id;
                    return data;
                })
                images.forEach(item => imagelist.push({id: item.id, imageUrl: item.downloadUrl, fileType: item.fileType, status: 'old', parentId: item.parentId}))

                if(cat === 'properties') {
                    setImages(imagelist)
                } else if (cat === 'properties/roomTypes') {
                    setRoomTypeImages(imagelist)
                }
            })
        }
    }

    function createHolidayType(image, name, desc, slug) {
        console.log(image, name, desc, slug)
        if (name) {
            firebase
            .firestore()
            .collection('holidayTypes')
            .add({
                imageURL: '',
                fileType: '',
                name: name,
                description: desc,
                slug: slug,
                isTrash: false
            })
            .then(item => {
                uploadImage(item.id, image, 'holiday-types')
                toastNotification('Holiday type added successfully.')
            })
            .catch(error => {
                console.log(error)
            })
        }
    }

    function updateHolidayType(holTypeId, image, fileType=null, imageUrl=null, name, desc, slug, isTrash = null) {
        if (holTypeId) {
            if(imageUrl) {
                firebase.firestore().collection('holidayTypes').doc(holTypeId).update({imageURL: imageUrl, fileType: fileType})
                .catch(error => {
                    console.log(error)
                })
            }

            if(name && slug) {
                firebase.firestore().collection('holidayTypes').doc(holTypeId).update({name: name, slug: slug})
                .catch(error => {
                    console.log(error)
                })
            }

            if(desc) {
                firebase.firestore().collection('holidayTypes').doc(holTypeId).update({description: desc})
                .catch(error => {
                    console.log(error)
                })
            }

            if(image) {
                const storage = firebase.storage();
                const storageRef = storage.ref(`user-uploads/holiday-types/${holTypeId}.${fileType}`);
                storageRef.delete().then(function () {
                    uploadImage(holTypeId, image, 'holiday-types')
                })
                .catch(error => {
                    console.log(error.code)

                    // eslint-disable-next-line default-case
                    switch(error.code){
                        case 'storage/object-not-found':
                            uploadImage(holTypeId, image, 'holiday-types')
                            break;
                    }
                })
            }

            if(isTrash !== null) {
                firebase.firestore().collection('holidayTypes').doc(holTypeId).update({isTrash: isTrash})
                .catch(error => {
                    console.log(error)
                })
            }
        }

    }

    function deleteHolidayType(holTypeId, fileType) {
        const storage = firebase.storage();
        const storageRef = storage.ref(`user-uploads/holiday-types/${holTypeId}.${fileType}`);
        if (holTypeId) {
            firebase
                .firestore()
                .collection('holidayTypes')
                .doc(holTypeId)
                .delete()
                .then(
                    storageRef.delete().then(function () {
                        toastNotification('Holiday type deleted successfully.')
                    })
                )
                .catch(error => {console.log(error)})
        }
    }


    //createCountry, allCountries, updateCountry

    function createCountry(name, code, slug) {
        if (name && code) {
            firebase
            .firestore()
            .collection('countries')
            .doc()
            .set({
                name: name,
                code: code,
                slug: slug
            })
            .then(() => {
                toastNotification('Country added successfully.')
            })
            .catch(error => {
                console.log(error)
            })
        }
    }

    function updateCountry(countryId, name, code, slug) {
        if (countryId) {
            if (name) {
                firebase.firestore().collection('countries').doc(countryId).update({name: name, slug: slug})
                .catch(error => {
                    console.log(error)
                })
            }

            if (code) {
                firebase.firestore().collection('countries').doc(countryId).update({code: code})
                .catch(error => {
                    console.log(error)
                })
            }
        }
    }

    function deleteCountry(countryId) {
        if (countryId) {
            firebase
                .firestore()
                .collection('countries')
                .doc(countryId)
                .delete()
                .then(toastNotification('Country deleted successfully.'))
                .catch(error => {console.log(error)})
        }
    }


    //createAtoll, allAtolls, updateAtoll

    function createAtoll(name, country, code, abbrev, type, slug) {
        if (name && country) {
            firebase
            .firestore()
            .collection('atolls')
            .doc()
            .set({
                name: name,
                country: country,
                code: code,
                abbreviation: abbrev,
                type: type,
                slug: slug
            })
            .then(() => {
                toastNotification('Atoll added successfully.')
            })
            .catch(error => {
                console.log(error)
            })
        }

    }

    function updateAtoll(atollId, name, country, code, abbrev, type, slug ) {
        if (atollId) {
            if (name) {
                firebase.firestore().collection('atolls').doc(atollId).update({name: name, slug: slug})
                .catch(error => {
                    console.log(error)
                })
            }

            if (country) {
                firebase.firestore().collection('atolls').doc(atollId).update({country: country})
                .catch(error => {
                    console.log(error)
                })
            }

            if (code) {
                firebase.firestore().collection('atolls').doc(atollId).update({code: code})
                .catch(error => {
                    console.log(error)
                })
            }

            if (name) {
                firebase.firestore().collection('atolls').doc(atollId).update({abbreviation: abbrev})
                .catch(error => {
                    console.log(error)
                })
            }

            if (type ) {
                firebase.firestore().collection('atolls').doc(atollId).update({type: type})
                .catch(error => {
                    console.log(error)
                })
            }
        }
    }


    function deleteAtoll(atollId) {
        if (atollId) {
            firebase
                .firestore()
                .collection('atolls')
                .doc(atollId)
                .delete()
                .then(toastNotification('Atoll deleted successfully.'))
                .catch(error => {console.log(error)})
        }
    }


    //createIsland, allIslands, updateIsland

    function createIsland(name, country, atoll, code, type, lat, long, slug) {
        if (name && country && atoll && long && lat) {
            firebase
            .firestore()
            .collection('islands')
            .doc()
            .set({
                name: name,
                country: country,
                code: code,
                atoll: atoll,
                type: type,
                longitude: long,
                latitude: lat,
                slug: slug
            })
            .then(() => {
                toastNotification('Island added successfully.')
            })
            .catch(error => {
                console.log(error)
            })
        }
    }

    function updateIsland (islandId, name, country, atoll, code, type, lat, long, slug) {
        console.log(slug)
        if (islandId) {
            if (name) {
                firebase.firestore().collection('islands').doc(islandId).update({name: name, slug: slug})
                .catch(error => {
                    console.log(error)
                })
            }

            if (country) {
                firebase.firestore().collection('islands').doc(islandId).update({country: country})
                .catch(error => {
                    console.log(error)
                })
            }

            if (atoll) {
                firebase.firestore().collection('islands').doc(islandId).update({atoll: atoll})
                .catch(error => {
                    console.log(error)
                })
            }

            if (code) {
                firebase.firestore().collection('islands').doc(islandId).update({code: code})
                .catch(error => {
                    console.log(error)
                })
            }

            if (country) {
                firebase.firestore().collection('islands').doc(islandId).update({type: type})
                .catch(error => {
                    console.log(error)
                })
            }

            if (lat) {
                firebase.firestore().collection('islands').doc(islandId).update({latitude: lat})
                .catch(error => {
                    console.log(error)
                })
            }

            if (long) {
                firebase.firestore().collection('islands').doc(islandId).update({longitude: long})
                .catch(error => {
                    console.log(error)
                })
            }
        }

    }

    function deleteIsland(islandId) {

        if (islandId) {
            firebase
                .firestore()
                .collection('islands')
                .doc(islandId)
                .delete()
                .then(toastNotification('Island deleted successfully.'))
                .catch(error => {console.log(error)})
        }
    }
    
    function toastNotification(message) {

        toast(message, {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });


    }

    //createProperty, updateProperty, allProperties

    function createProperty(propertyObj) {
        const {
            featuredImage, 
            propertyName, 
            propertyType, 
            rating, 
            isPublished, 
            description, 
            email, 
            website, 
            bookingEmail, 
            phoneNumbers, 
            atoll, 
            island, 
            address, 
            latitude, 
            longitude, 
            holidayTypes,
            slug,
            isFeatured,
            hotDeal,
            action,
            history,
            amenities,
            featuredAmenities,
            transportTypes,
        } = propertyObj

        firebase
        .firestore()
        .collection('properties')
        .add({
            name: propertyName,
            type: propertyType,
            rating: rating,
            isPublished: isPublished,
            description: description,
            email: email,
            website: website,
            bookingEmail: bookingEmail,
            phoneNumbers: phoneNumbers,
            atoll: atoll,
            island: island,
            address: address,
            latitude: latitude,
            longitude: longitude,
            holidayTypes: holidayTypes,
            downloadUrl: '',
            fileType: '',
            slug: slug,
            isTrash: false,
            isFeatured: isFeatured,
            hotDeal: hotDeal,
            amenities: amenities,
            featuredAmenities:featuredAmenities,
            transportTypes: transportTypes,
            createdOn: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(item => {
            uploadImage(item.id, featuredImage, 'properties')
            if(action === 'draft') {
                toastNotification('Saved successfully')
                history.push(`/travadm/properties/${slug}/edit`, null)
            } else {
                toastNotification('Property published successfully')
            }
        })
        .catch(error => {
            console.log(error)
        })
    }

    function updateProperty(propertyObj) {

        const {
            propId,
            featuredImage,
            downloadUrl,
            fileType, 
            propertyName, 
            propertyType, 
            rating, 
            isPublished, 
            description, 
            email, 
            website, 
            bookingEmail, 
            phoneNumbers, 
            atoll, 
            island, 
            address, 
            latitude, 
            longitude, 
            holidayTypes,
            slug,
            isFeatured,
            hotDeal,
            isTrash,
            amenities,
            featuredAmenities,
            transportTypes,
        } = propertyObj

        console.log('UPDATE',propertyObj)


        if(propId) {
            if(downloadUrl) {
                firebase.firestore().collection('properties').doc(propId).update({downloadUrl: downloadUrl, fileType: fileType})
                .catch(error => {
                    console.log(error)
                })
            }

            if(propertyName) {
                firebase.firestore().collection('properties').doc(propId).update({name: propertyName, slug: slug})
                .catch(error => {
                    console.log(error)
                })
            }

            if(propertyType) {
                firebase.firestore().collection('properties').doc(propId).update({type: propertyType})
                .catch(error => {
                    console.log(error)
                })
            }

            if(rating) {
                firebase.firestore().collection('properties').doc(propId).update({rating: rating})
                .catch(error => {
                    console.log(error)
                })
            }

            if(description) {
                firebase.firestore().collection('properties').doc(propId).update({description: description})
                .catch(error => {
                    console.log(error)
                })
            }

            if(email) {
                firebase.firestore().collection('properties').doc(propId).update({email: email})
                .catch(error => {
                    console.log(error)
                })
            }

            if(website) {
                firebase.firestore().collection('properties').doc(propId).update({website: website})
                .catch(error => {
                    console.log(error)
                })
            }

            if(bookingEmail) {
                firebase.firestore().collection('properties').doc(propId).update({bookingEmail: bookingEmail})
                .catch(error => {
                    console.log(error)
                })
            }

            if(phoneNumbers) {
                firebase.firestore().collection('properties').doc(propId).update({phoneNumbers: phoneNumbers})
                .catch(error => {
                    console.log(error)
                })
            }

            if(atoll) {
                firebase.firestore().collection('properties').doc(propId).update({atoll: atoll})
                .catch(error => {
                    console.log(error)
                })
            }

            if(island) {
                firebase.firestore().collection('properties').doc(propId).update({island: island})
                .catch(error => {
                    console.log(error)
                })
            }

            if(latitude) {
                firebase.firestore().collection('properties').doc(propId).update({latitude: latitude})
                .catch(error => {
                    console.log(error)
                })
            }

            if(longitude) {
                firebase.firestore().collection('properties').doc(propId).update({longitude: longitude})
                .catch(error => {
                    console.log(error)
                })
            }

            if(holidayTypes) {
                firebase.firestore().collection('properties').doc(propId).update({holidayTypes: holidayTypes})
                .catch(error => {
                    console.log(error)
                })
            }

            if(address) {
                firebase.firestore().collection('properties').doc(propId).update({address: address})
                .catch(error => {
                    console.log(error)
                })
            }

            if(amenities) {
                firebase.firestore().collection('properties').doc(propId).update({amenities: amenities})
                .catch(error => {
                    console.log(error)
                })
            }

            if(featuredAmenities) {
                firebase.firestore().collection('properties').doc(propId).update({featuredAmenities: featuredAmenities})
                .catch(error => {
                    console.log(error)
                })
            }

            if(transportTypes) {
                firebase.firestore().collection('properties').doc(propId).update({transportTypes: transportTypes})
                .catch(error => {
                    console.log(error)
                })
            }

            if(isPublished === true) {
                firebase.firestore().collection('properties').doc(propId).update({isPublished: true})
                .then(toastNotification('Property published successfully.'))
                .catch(error => {
                    console.log(error)
                })
            } else if (isPublished === false) {
                firebase.firestore().collection('properties').doc(propId).update({isPublished: false})
                .then(toastNotification('Property unpublished successfully.'))
                .catch(error => {
                    console.log(error)
                })
            } else {
                !isTrash && toastNotification('Property updated.')
            }

            if (isFeatured === true) {
                firebase.firestore().collection('properties').doc(propId).update({isFeatured: true})
                .catch(error => {
                    console.log(error)
                })
            } else if (isFeatured === false) {
                firebase.firestore().collection('properties').doc(propId).update({isFeatured: false})
                .catch(error => {
                    console.log(error)
                })
            }

            if (hotDeal === true) {
                firebase.firestore().collection('properties').doc(propId).update({hotDeal: true})
                .catch(error => {
                    console.log(error)
                })
            } else if (hotDeal === false) {
                firebase.firestore().collection('properties').doc(propId).update({hotDeal: false})
                .catch(error => {
                    console.log(error)
                })
            }

            if(isTrash === true) {
                toastNotification('Property moved to trash.')
                firebase.firestore().collection('properties').doc(propId).update({isTrash: true, isPublished: false, isFeatured: false})
                .catch(error => {
                    console.log(error)
                })
            } else if (isTrash === false) {
                firebase.firestore().collection('properties').doc(propId).update({isTrash: false})
                .catch(error => {
                    console.log(error)
                })
            }

            if(featuredImage) {
                if (downloadUrl) {
                    const storage = firebase.storage();
                    const storageRef = storage.ref(`user-uploads/properties/${propId}.${fileType}`);
                    storageRef.delete().then(function () {
                        uploadImage(propId, featuredImage, 'properties')
                    })
                } else {
                    uploadImage(propId, featuredImage, 'properties')
                }
            }


        }
    }


    function deleteProperty(propId, fileType) {
        const storage = firebase.storage();
        const storageRef = storage.ref(`user-uploads/properties/${propId}.${fileType}`);
        if (propId) {
            firebase
                .firestore()
                .collection('properties')
                .doc(propId)
                .delete()
                .then(() => {

                    if(fileType) {
                        storageRef.delete().then(function () {
                            toastNotification('Porperty deleted successfully.')
                        })
                    } else {
                        toastNotification('Property deleted successfully.')
                    }
                })
                .catch(error => {console.log(error)})
        }

    }
    
    function createAmenity(icon, name, description, category, slug){
        if (name) {
            firebase
            .firestore()
            .collection('amenities')
            .doc()
            .set({
                icon:icon,
                name: name,
                description: description,
                category:category ? category : 'hBsENud8BQTJJcVOSTjC',
                slug: slug
            })
            .then(() => {
                toastNotification('Amenity added successfully.')
            })
            .catch(error => {
                console.log(error)
            })
        }
    }

    function updateAmenity(id, icon, name, description, category, slug){
        if (id){
            if(icon) {
                firebase.firestore().collection('amenities').doc(id).update({icon: icon})
                .catch(error => {
                    console.log(error)
                })
            }
            if(name) {
                firebase.firestore().collection('amenities').doc(id).update({name: name, slug:slug})
                .catch(error => {
                    console.log(error)
                })
            }
            if(description){
                firebase.firestore().collection('amenities').doc(id).update({description: description})
                .catch(error => {
                    console.log(error)
                })
            }
            if(description){
                firebase.firestore().collection('amenities').doc(id).update({category: category})
                .catch(error => {
                    console.log(error)
                })
            }
        }
    }

    

    function deleteAmenity(id){
        if(id){
            firebase
                .firestore()
                .collection('amenities')
                .doc(id)
                .delete()
                .then(toastNotification('Amenity deleted successfully.'))
                .catch(error => {console.log(error)})
        }
    }


    function createAmenityCategories( name, slug){
        if (name) {
            firebase
            .firestore()
            .collection('amenitiescategories')
            .doc()
            .set({
                name: name,
                slug: slug
            })
            .then(() => {
                toastNotification('Amenity Category added successfully.')
            })
            .catch(error => {
                console.log(error)
            })
        }
    }

    function updateAmenityCategories(id, name, slug){
        console.log(id, name, slug)
        if (id){
            if(name) {
                firebase.firestore().collection('amenitiescategories').doc(id).update({name: name, slug:slug})
                .catch(error => {
                    console.log(error)
                })
            }
        }
    }

    

    function deleteAmenityCategories(id){
        if(id){
            firebase
                .firestore()
                .collection('amenitiescategories')
                .doc(id)
                .delete()
                .then(toastNotification('Amenity cateory deleted successfully.'))
                .catch(error => {console.log(error)})
        }
    }


    function createRoomTypes(parentId,roomTypeObj) {
        console.log(parentId, roomTypeObj)
        if(parentId && roomTypeObj) {
            firebase
            .firestore()
            .collection('roomTypes')
            .add({
                parentId:parentId,
                name: roomTypeObj.name,
                description: roomTypeObj.desc,
                code: roomTypeObj.code,
                adultCapacity: roomTypeObj.adultCapacity,
                childCapacity: roomTypeObj.childCapacity,
                size: roomTypeObj.size,
                price:roomTypeObj.price,
                amenities:roomTypeObj.amenities,
                featuredAmenities:roomTypeObj.featuredAmenities,
                downloadUrl: '',
                fileType: ''
            })
            // eslint-disable-next-line no-loop-func
            .then(item => {
                getAllRoomTypes(parentId)
                uploadImage(item.id, roomTypeObj.image, 'properties/roomTypes', parentId)
            })
            .catch(error => {
                console.log(error)
            })
        }
    }

    function updateRoomType(id, url, fileType, parentId, roomTypeObj) {

        const {
            image,
            downloadUrl,
            name,
            code,
            desc,
            adultCapacity,
            childCapacity,
            size,
            price,
            amenities,
            featuredAmenities
        } = roomTypeObj

        console.log('UPDATE FUNCTION',roomTypeObj)

        if (id) {
            if(name) {
                firebase.firestore().collection('roomTypes').doc(id).update({name: name})
                .catch(error => {
                    console.log(error)
                })
            }

            if(code) {
                firebase.firestore().collection('roomTypes').doc(id).update({code: code})
                .catch(error => {
                    console.log(error)
                })
            }

            if(desc) {
                firebase.firestore().collection('roomTypes').doc(id).update({description: desc})
                .catch(error => {
                    console.log(error)
                })
            }

            if(adultCapacity) {
                firebase.firestore().collection('roomTypes').doc(id).update({adultCapacity: adultCapacity})
                .catch(error => {
                    console.log(error)
                })
            }

            if(childCapacity) {
                firebase.firestore().collection('roomTypes').doc(id).update({childCapacity: childCapacity})
                .catch(error => {
                    console.log(error)
                })
            }

            if(size) {
                firebase.firestore().collection('roomTypes').doc(id).update({size: size})
                .catch(error => {
                    console.log(error)
                })
            }

            if(price) {
                firebase.firestore().collection('roomTypes').doc(id).update({price: price})
                .catch(error => {
                    console.log(error)
                })
            }

            if(amenities) {
                firebase.firestore().collection('roomTypes').doc(id).update({amenities: amenities})
                .catch(error => {
                    console.log(error)
                })
            }

            if(featuredAmenities) {
                firebase.firestore().collection('roomTypes').doc(id).update({featuredAmenities: featuredAmenities})
                .then(toastNotification("Featured Amenties Updated"))
                .catch(error => {
                    console.log(error)
                })
            }

            if(image) {
                if (downloadUrl) {
                    const storage = firebase.storage();
                    const storageRef = storage.ref(`user-uploads/properties/roomTypes/${id}.${fileType}`);
                    storageRef.delete().then(function () {
                        uploadImage(id, image, 'properties/roomTypes', parentId)
                    })
                } else {
                    uploadImage(id, image, 'properties/roomTypes', parentId)
                }
            }

            

            if(url) {
                firebase.firestore().collection('roomTypes').doc(id).update({downloadUrl: url, fileType: fileType})
                .then(() => {
                        getAllRoomTypes(parentId)
                    }
                )
                .catch(error => {
                    console.log(error)
                })
            }
        }
    }

    async function deleteRoomTypes(id, fileType) {
        const storage = firebase.storage();
        const storageRef = storage.ref(`user-uploads/properties/roomTypes/${id}.${fileType}`);
        if(id){
            firebase
                .firestore()
                .collection('roomTypes')
                .doc(id)
                .delete()
                .then(() => {
                    if(fileType) {
                        storageRef.delete().then(function () {
                            toastNotification('Room Type deleted.')
                        })
                    } else {
                        toastNotification('Room Type deleted.')
                    }
                    getAllBatchImages(id, 'properties/roomTypes')
                    if(roomTypeImages.length > 0) {
                        roomTypeImages.forEach(async (image)  => {
                            deleteImageBatchItem(image.id, image.fileType, 'properties/roomTypes')
                            await sleep(1000)
                        })
                    }
                })
                .catch(error => {console.log(error)})
        }
    }

    function getAllRoomTypes(parentId) {
        console.log('roomTypes',parentId)
        var roomTypesList = []
        if (parentId) {
            firebase
            .firestore()
            .collection('roomTypes')
            .where('parentId', '==', parentId )
            .orderBy('name', 'asc')
            .get()
            .then(function(querySnapshot) {
                const rooms = querySnapshot.docs.map(_doc => {
                    const data = _doc.data();
                    data['id'] = _doc.id;
                    return data;
                })
                rooms.forEach(item => roomTypesList.push({id:item.id, code:item.code, name: item.name, desc: item.description, adultCapacity: item.adultCapacity, childCapacity:item.childCapacity, size: item.size, downloadUrl: item.downloadUrl, price:item.price, amenities: item.amenities, featuredAmenities:item.featuredAmenities}))
                setAllRoomTypes(roomTypesList)
            })
        }
    }

    function removeHTMLTags (str) {
        return str.replace(/<[^>]*>?/gm, '');
    };

    function sendEmail(data, setFormToggle) {
        const {
            name,
            email,
            contactNo,
            arrival,
            departure,
            budget,
            adultCap,
            childCap,
            infantCap,
            notes,
            nationality,
            propertyObj,
            roomObj
        } = data

        const emailSender = firebase.functions().httpsCallable('emailSender')
        emailSender({
            name: name,
            email: email,
            contactNo: contactNo,
            arrival: arrival,
            departure: departure,
            budget: budget,
            adultCap: adultCap,
            childCap: childCap,
            infantCap: infantCap,
            nationality: nationality,
            notes: notes,
            propertyName: propertyObj.name,
            propertyType: propertyObj.type,
            propertyPhoneNumbers: propertyObj.phoneNumbers,
            propertyEmail: propertyObj.email,
            propertyWebsite: propertyObj.website,
            roomName: roomObj.name,

        }).then(response => {
            console.log(response)
            setFormToggle(false)
            window.alert('Your booking inquiry has been submitted successfully.')
        }).catch(error => {
            console.log('error --- ',error)
        })  

    }

    function createTransportType(transportObj, setAddToggle) {
        const {transportName, slug} = transportObj
        if(transportName) {
            firebase
            .firestore()
            .collection('transport')
            .add({
                name: transportName,
                slug: slug,
                createdOn: firebase.firestore.FieldValue.serverTimestamp(),
            })
            // eslint-disable-next-line no-loop-func
            .then(item => {
                setAddToggle(false)
            })
            .catch(error => {
                console.log(error)
            })
        }

    }


    function deleteTransportType(id){
        if(id){
            firebase
                .firestore()
                .collection('transport')
                .doc(id)
                .delete()
                .then(toastNotification('Transport type deleted successfully.'))
                .catch(error => {console.log(error)})
        }
    }

    function createPackage(packageObj) {

        const {
            name,
            price,
            slug,
            desc,
            startDate,
            endDate,
            property,
            roomList,
            duration,
        } = packageObj

        if(name) {
            firebase
            .firestore()
            .collection('packages')
            .add({
                name: name,
                price: price,
                slug: slug,
                desc: desc,
                startDate: startDate,
                endDate: endDate,
                property: property,
                roomList: roomList,
                duration: duration,
                createdOn: firebase.firestore.FieldValue.serverTimestamp(),
            })
            // eslint-disable-next-line no-loop-func
            .then(item => {
                window.alert('Package created successfully.')
            })
            .catch(error => {
                console.log(error)
            })
        }
    }

    function updatePackage(id, packageObj) {

        const {
            name,
            price,
            slug,
            desc,
            startDate,
            endDate,
            property,
            roomList,
            duration,
        } = packageObj

        if (id) {
            if(name) {
                firebase.firestore().collection('packages').doc(id).update({name: name, slug: slug})
                .catch(error => {
                    console.log(error)
                })
            }

            if(price) {
                firebase.firestore().collection('packages').doc(id).update({price: price})
                .catch(error => {
                    console.log(error)
                })
            }

            if(desc) {
                firebase.firestore().collection('packages').doc(id).update({desc: desc})
                .catch(error => {
                    console.log(error)
                })
            }

            if(startDate) {
                firebase.firestore().collection('packages').doc(id).update({startDate: startDate})
                .catch(error => {
                    console.log(error)
                })
            }

            if(endDate) {
                firebase.firestore().collection('packages').doc(id).update({endDate: endDate})
                .catch(error => {
                    console.log(error)
                })
            }

            if(property) {
                firebase.firestore().collection('packages').doc(id).update({property: property})
                .catch(error => {
                    console.log(error)
                })
            }

            if(duration) {
                firebase.firestore().collection('packages').doc(id).update({duration: duration})
                .catch(error => {
                    console.log(error)
                })
            }

            if(roomList.length > 0) {
                firebase.firestore().collection('packages').doc(id).update({roomList: roomList})
                .catch(error => {
                    console.log(error)
                })
            }
        }

    }

    function deletePackage(id) {
        if(id){
            firebase
                .firestore()
                .collection('packages')
                .doc(id)
                .delete()
                .then(toastNotification('Package deleted successfully.'))
                .catch(error => {console.log(error)})
        }
    }
     
    return(
        <Context.Provider value={{
            handleLogin,
            handleLogout,
            sendEmailVerification,
            resetPassword,
            handleToggle,
            handleRegister,
            handleUserDelete,
            handleUserDisable,
            handleUserEnable,
            getUser,
            createPropertyType,
            deletePropertyType,
            updatePropertyType,
            createHolidayType,
            updateHolidayType,
            deleteHolidayType,
            createCountry,
            updateCountry,
            deleteCountry,
            createAtoll,
            updateAtoll,
            deleteAtoll,
            createIsland,
            updateIsland,
            deleteIsland,
            createProperty,
            updateProperty,
            deleteProperty,
            createAmenity,
            updateAmenity,
            deleteAmenity,
            createAmenityCategories,
            updateAmenityCategories,
            deleteAmenityCategories,
            setImages,
            setRoomTypeImages,
            uploadImageBatch,
            sleep,
            deleteImageBatchItem,
            getAllBatchImages,
            toastNotification,
            createRoomTypes,
            updateRoomType,
            deleteRoomTypes,
            getAllRoomTypes,
            setAllRoomTypes,
            removeHTMLTags,
            sendEmail,
            createTransportType,
            deleteTransportType,
            createPackage,
            updatePackage,
            deletePackage,
            allPackages,
            allTransportTypes,
            allRoomTypesLength,
            allRoomTypes,
            roomTypeImages,
            images,
            allAmenities,
            allAmenitiesCategories,
            allProperties,
            allIslands,
            allAtolls,
            allCountries,
            allHolidayTypes,
            allPropertyTypes,
            userDetailsLoaded,
            userObject,
            userDetails,
            allUsers,
            toggled,
            emailError,
            passwordError,
            isLoggedIn,
            emailVerified,
            user,
            contextLoad
        }}>
            {children}
        </Context.Provider>
    )
}


export {ContextProvider, Context};