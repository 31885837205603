import React from 'react'
import PropTypeList from './PropTypeList'


function PropTypeListContainer() {
    return(
        <div className='propTypeListContainer'>
            <PropTypeList />
        </div>
    )

}

export default PropTypeListContainer;