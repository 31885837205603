import React, { useContext, useState, useEffect } from 'react'
import { Context } from '../../../../context/Context'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import { useHistory, useLocation, useParams } from 'react-router-dom';


function PackagesForm() {

    const [packId, setPackId] = useState('')
    const [name, setName] = useState('')
    const [slug, setSlug] = useState('')
    const [room, setRoom] = useState('')
    const [desc, setDesc] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [price, setPrice] = useState('')
    const [property, setProperty] = useState('')
    const [roomToggle, setRoomToggle] = useState(false)
    const [roomList, setRoomList] = useState([])
    const [duration, setDuration] = useState('')

    const { allProperties, getAllRoomTypes, setAllRoomTypes, allRoomTypes, createPackage, allPackages, updatePackage } = useContext(Context)
    const history = useHistory()
    const slugParam = useParams().slug

    const asterisk = <span style={{ color: 'red' }}> * </span>

    useEffect(() => {
        getAllRoomTypes(property)
        return setAllRoomTypes([])
    }, [property])


    useEffect(() => {
        if (slugParam) {
            const currPackage = allPackages.find(item => item.slug === slugParam)
            setPackId(currPackage.id)
            setName(currPackage.name)
            setSlug(currPackage.slug)
            setPrice(currPackage.price)
            setStartDate(currPackage.startDate)
            setEndDate(currPackage.endDate)
            setProperty(currPackage.property)
            setDesc(currPackage.desc)
            setDuration(currPackage.duration)
            if (allRoomTypes.length > 0) {
                setRoomList(currPackage.roomList)
            }
        }
    }, [allPackages, slugParam])


    const roomItems = allRoomTypes.map(item => {
        return (
            <option key={item.id} value={item.id}>{item.name}</option>
        )
    })

    const propertyItems = allProperties.filter(i => i.isPublished).sort().map(item => {
        return (
            <option key={item.id} value={item.id}>{item.name}</option>
        )
    })

    function createUUID() {
        var dt = new Date().getTime();
        var uuid = 'yxxyxx'.replace(/[xy]/g, function (c) {
            var r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            // eslint-disable-next-line no-mixed-operators
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    }

    function handleNameChange(event) {
        const { value } = event.target
        var slugValue = `${value.replace(/\s+/g, '-').toLowerCase()}-${createUUID()}`
        setSlug(slugValue)
        setName(value)
    }

    function handlePropertyChange(event) {
        const { value } = event.target
        setProperty(value)

        if (roomList.length > 0) {
            setRoomList([])
        }
    }

    function handleDurationChange(event) {
        const { value } = event.target
        setDuration(value)
    }

    function handleRoomChange(event) {
        const { value } = event.target
        setRoom(value)
    }

    function handleAddClick() {
        if (!roomToggle && property !== '') {
            setRoomToggle(true)
        } else if (property === '') {
            window.alert("Please select a property to continue adding a room.")
        }
    }

    function handleCloseClick(event) {
        event.preventDefault()
        setRoomToggle(false)
    }

    function handleSaveClick(event) {
        event.preventDefault()
        if (!roomList.includes(room)) {
            setRoomList([...roomList, room])
            // setRoomToggle(false)
        } else {
            window.alert("This room has already been added to this package")
        }
    }

    function handleDeleteClick(id) {
        setRoomList(roomList.filter(item => item !== id))
    }

    function handleDescChange(value) {
        setDesc(value)
    }

    function handlePriceChange(event) {
        const { value } = event.target
        setPrice(value)
    }

    function handleStartDateChange(event) {
        const {value} = event.target
        setStartDate(value)
    }

    function handleEndDateChange(event) {
        const {value} = event.target
        setEndDate(value)
    }

    const roomListItems =
        roomList.map((item, index) => {
            const roomItem = allRoomTypes.find(i => i.id === item)
            if (roomItem) {
                return (
                    <div className="roomListItem" key={index}>
                        <i className='ri-close-circle-fill' onClick={() => handleDeleteClick(roomItem.id)} />
                        {roomItem.name}
                    </div>
                )
            }
        })

    function handleRoomSaveClick(event) {
        event.preventDefault()
        const packageObj = {
            name: name,
            slug: slug,
            price: price,
            desc: desc,
            startDate: startDate,
            endDate: endDate,
            property: property,
            roomList: roomList,
            duration: duration,
        }

        if (name && property && duration) {
            if (slugParam) {
                updatePackage(packId, packageObj)
                history.push('/travadm/packages', null)
            } else {
                createPackage(packageObj)
                history.push('/travadm/packages', null)
            }
        } else {
            window.alert('Please fill all required fields to continue adding a package.')
        }
    }

    function handleRoomCancelClick() {
        history.push('/travadm/packages', null)
    }

    const addNewRoom =
        <div className='packageRoomSection'>
            <h1>Add Room</h1>
            <div className={roomToggle ? 'addNewRoomExpanded' : 'addNewRoom'} onClick={handleAddClick}>
                <div className='addRoomContent'>
                    {
                        roomToggle ?
                            <i className="ri-close-fill" onClick={handleCloseClick}></i>
                            :
                            <i className="ri-add-fill"></i>

                    }
                    <h1>Add Room</h1>
                </div>
                <div className='roomSelect' hidden={!roomToggle}>
                    <select
                        className='formInput propTypeInput'
                        placeholder='Package Name'
                        type='text'
                        value={room}
                        onChange={handleRoomChange}

                    >
                        <option value='' disabled>Select Room</option>
                        {roomItems}
                    </select>
                    <div className='addRoomButtonRow'>
                        <button className='formButton saveButton' onClick={handleSaveClick}>Add</button>
                        <button className='formButton cancelButton' onClick={handleCloseClick}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>

    return (
        <div className='propTypeFormContainer'>
            <form>
                <div className='propTypeFieldGroup'>
                    <label>
                        Package Name{asterisk}:
                    </label>
                    <input
                        className='formInput propTypeInput'
                        placeholder='Package Name'
                        type='text'
                        value={name}
                        onChange={handleNameChange}
                    />
                </div>
                <div className='propTypeFieldGroup'>
                    <label>
                        Package Price:
                    </label>
                    <input
                        className='formInput propTypeInput'
                        placeholder='Package Price'
                        type='text'
                        value={price}
                        onChange={handlePriceChange}
                    />
                </div>
                <div className='propTypeFieldGroup'>
                    <label>
                        Start Date:
                    </label>
                    <input
                        className='formInput propTypeInput'
                        placeholder='Package Price'
                        type='date'
                        value={startDate}
                        onChange={handleStartDateChange}
                    />
                </div>
                <div className='propTypeFieldGroup'>
                    <label>
                        End Date:
                    </label>
                    <input
                        className='formInput propTypeInput'
                        placeholder='Package Price'
                        min={startDate}
                        type='date'
                        value={endDate}
                        onChange={handleEndDateChange}
                    />
                </div>
                <div className='propTypeFieldGroup'>
                    <label>
                        Property{asterisk}:
                    </label>
                    <select
                        className='formInput propTypeInput'
                        placeholder='Package Name'
                        type='text'
                        value={property}
                        onChange={handlePropertyChange}

                    >
                        <option value='' disabled>Select Property</option>
                        {propertyItems}
                    </select>
                </div>
                <div className='propTypeFieldGroup'>
                    <label>
                        Duration{asterisk}:
                    </label>
                    <select
                        className='formInput propTypeInput'
                        placeholder='Package Name'
                        type='text'
                        value={duration}
                        onChange={handleDurationChange}

                    >
                        <option value='' disabled>Select Duration</option>
                        <option value='1 Day'>1 Day</option>
                        <option value='2 Days'>2 Days</option>
                        <option value='3 Days'>3 Days</option>
                        <option value='4 Days'>4 Days</option>
                        <option value='5 Days'>5 Days</option>
                        <option value='6 Days'>6 Days</option>
                        <option value='7 Days'>7 Days</option>
                    </select>
                </div>
                <div className='packageInputWrapperFull'>
                    <label>
                        Package Description:
                    </label>
                    <ReactQuill
                        row={5}
                        style={{ minHeight: '150px' }}
                        value={desc}
                        onChange={handleDescChange}
                    />
                </div>
                <div>
                    {addNewRoom}
                </div>
                <div className='roomListArea'>
                    {roomListItems}
                </div>
                <div className='userFormButtonRow'>
                    <button className='formButton saveButton' onClick={handleRoomSaveClick}>{slugParam ? 'Update' : 'Add'}</button>
                    <button className='formButton cancelButton' onClick={handleRoomCancelClick}>Cancel</button>
                </div>
            </form>
        </div>
    )

}

export default PackagesForm