import React from 'react'
import TransportInput from './TransportInput'

function TransportInputElement(props) {

    const {
        transportObj, 
        setSelected, 
        selected,
        all,
        setAll,
    } = props

    function handleElementClick() {
        setSelected([...selected, {name:transportObj.name, slug:transportObj.slug, duration:''}])
        setAll(all.filter(item => item.slug !== transportObj.slug))
    }

    return(
        <div className='transportSelectElement' onClick={handleElementClick}>
            <p>{transportObj.name}</p>
        </div>
    )

}

export default TransportInputElement;