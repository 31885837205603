import React, {useState, useContext} from 'react';
import AddUserForm from './AddUserForm'

import {Context} from '../../context/Context'


function AddUserItem() {

    const {handleToggle, toggled} = useContext(Context)

    // const [toggled, setToggled] = useState(true)


    function handleItemClick() {
        if(toggled !== true) {
            handleToggle(true)
        }
    }

    function checkClicked(){
        return(   
            <div className={`${toggled ? 'addUserItemExpanded' : 'addUserItem'}`}  onClick={handleItemClick}>
                <h1>{`${!toggled ? 'Click To' : '' } Add New User`}</h1>
                {toggled && <AddUserForm />}
            </div>
        )
    }


    return (
        <>
            {checkClicked()}
        </>
    )
}

export default AddUserItem;