import React, { useState, useContext, useEffect } from 'react';
import { useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Context } from '../../../../context/Context';
import { IconPicker } from 'react-fa-icon-picker'


function Amenitiesform() {

    const [name, setName] = useState('')
    const [icon, setIcon] = useState('')
    const [amenityID, setAmenityID] = useState('')
    const [description, setDescription] = useState('')
    const [slug, setSlug] = useState('')
    const [category, setCategory] = useState('')
    const {createAmenity, allAmenities, updateAmenity, toastNotification} = useContext(Context)
    const {allAmenitiesCategories} = useContext(Context)
    const history = useHistory()
    const nameRef = useRef(null)
    const path = useLocation().pathname
    const slugParam = useParams().slug
    

    useEffect(()=> {
        if(path.includes('edit')) {
            const amenityItem = allAmenities.find(item => item.slug === slugParam)
            setAmenityID(amenityItem.id)
            setName(amenityItem.name)
            setSlug(amenityItem.slug)
            setIcon(amenityItem.icon)
            setCategory(amenityItem.category)
            setDescription(amenityItem.description)

        }
    }, [allAmenities, path, slugParam])

      

    const asterisk = <span style={{color: 'red'}}> * </span>

    function handleNameChange(event) {
        const {value} = event.target
        var slugValue = value.replace(/\s+/g, '-').toLowerCase()
        setName(value)
        setSlug(slugValue)
    }

    function handleDescChange(event) {
        const {value} = event.target
        setDescription(value)
    }
    function handleCatChange(event) {
        const {value} = event.target
        setCategory(value)
    }

    function preProcess(iconString){
        if (iconString.includes('Fa')){
            return iconString
        }else{
            let iconStringArr = iconString.match(/[a-z]+/g)
            for (let i = 0; i < iconStringArr.length; i++){
                const capitalizedStr = iconStringArr[i].charAt(0).toUpperCase() + iconStringArr[i].slice(1)
                iconStringArr[i] = capitalizedStr
            }
            let completeStr = "Fa" + iconStringArr.join('')
            return completeStr;
        }
        
    }

    function process(iconString){
        let iconStringArr = iconString.match(/[A-Z][a-z]+/g)
        if(iconString.includes('Fa')){
            iconStringArr.splice(0,1)
            if(iconStringArr.length>=2){
                let arrToStrings = iconStringArr.join('-')
                arrToStrings = arrToStrings.toLowerCase()
                return arrToStrings;
            }else{
                let arrToStrings = iconStringArr.join('')
                arrToStrings = arrToStrings.toLowerCase()
                return arrToStrings;
            }
        }else{
            return iconString;
        }
    }

    

    function handleSaveClick(event) {
        event.preventDefault()

        if(allAmenities.find(item=>item.slug===slug)) {
            if (path.includes('edit')) {
                if(window.confirm('Are you sure you want to save changes to the Amenity?')) {
                    updateAmenity(amenityID, process(icon), name, description, category, slug)
                    toastNotification('Changes saved successfully.')
                    history.push('/travadm/amenities', null)
                }
            } else {
                window.alert('There is already an Amenity of this name, please create a new  Amenity')
            }
        } else {
            if (name) {
                nameRef.current.classList.remove('invalidInput')       
                if (window.confirm('Are you sure you want to add the new Amenity?')) {
                    createAmenity(process(icon), name, description, category, slug)
                    history.push('/travadm/amenities', null)
                }
            } else {
                if (!name){
                    nameRef.current.classList.add('invalidInput')
                    window.alert('Please ensure all fields highlighted in red contain a value.')
                }
                else{
                     window.alert('There is already an Amenity of this name, please create a new  Amenity')

                }
            }
        }
    }


    function handleCancelClick(event) {
        event.preventDefault()
        if (name || description) {
            if (window.confirm('Are you sure you want to cancel adding this Amenity? All changes will be lost.')) {
                history.push('/travadm/amenities', null)
            }
        } else {
            history.push('/travadm/amenities', null)
        }
    }

    const amenitiesCategories = allAmenitiesCategories.map(item => {
        return(
            <option key={item.id} value={item.id}>{item.name}</option>
        )
    })

    

    return (
        <div className='propTypeFormContainer'>
            <p>All fields marked with [{asterisk}] are mandatory</p>
            <form> 
            <div>
                
            <IconPicker 
                placeholder = {icon?preProcess(icon):''}
                value = {icon?preProcess(icon):''}
                onChange= {(v) => setIcon(v)}
                />

            </div>
            <div className='propTypeFieldGroup'>
                    <label>
                        Amenity name{asterisk}:
                    </label>
                    <input 
                        className='formInput propTypeInput' 
                        placeholder='Enter Amenity (ex: Wi-Fi)'
                        type='text'
                        onChange={handleNameChange}
                        value={name}
                        ref={nameRef}
                    />
                </div>

                <div className='propTypeFieldGroup'>
                    <label>
                        Amenity description:
                    </label>
                    <textarea 
                        className='formInput propTypeTextArea' 
                        placeholder='Type a short description of the amenity here...'
                        type='text'
                        onChange={handleDescChange}
                        value={description}
                    />
                </div>
                <div className='propTypeFieldGroup'>
                    <label>
                        Amenity Category:
                    </label>
                    <select className='formInput propTypeInput' value={category} onChange={handleCatChange}>
                        <option value="" disabled selected>Select your option</option>
                        {amenitiesCategories}
                    </select>
                </div>
                <div className='userFormButtonRow'>
                    <button className='formButton saveButton' onClick={handleSaveClick} >{path.includes('edit') ? 'Update' : 'Add'}</button>
                    <button className='formButton cancelButton' onClick={handleCancelClick}>Cancel</button>
                </div>
            </form>
        </div>
    )
}

export default Amenitiesform;