import React, { useContext } from 'react'
import { Context } from '../../../context/Context';

function ImageElement(props) {
    const {deleteImageBatchItem} = useContext(Context)
    const {imageUrl, fileType, id, cat} = props;


    function handleDeleteClick() {
        if(cat === 'properties') {
            deleteImageBatchItem(id, fileType, 'properties')
        } else if (cat === 'properties/roomTypes') {
            deleteImageBatchItem(id, fileType, 'properties/roomTypes')
        }
    }

    return (
        <div className='imageElement'>
                <div className='imageOverlay' >
                    <div className='imageIcon' onClick={handleDeleteClick}>
                        <i className="ri-close-circle-fill"></i>  
                    </div>
                </div>
            <div className='imageContainer'>
                <img src={imageUrl} alt='' />
            </div>

        </div>
    )
}



export default ImageElement;