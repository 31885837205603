import React, {useContext} from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router';
import './App.css';

import Admin from './components/admin/base/AdminContainer';
import LoginForm from './components/authentication/LoginForm';
import VerifyEmail from './components/authentication/VerifyEmail.js';
import ResetPass from "./components/authentication/ResetPass";
import { Context } from './components/context/Context'
import LandingPage from './components/home/frontpage/LandingPage';
import PropertiesPage from './components/home/propertiespage/PropertiesPage';
import RoomDetails from './components/home/propertiespage/RoomDetails';
import StayPage from './components/home/staypage/StayPage';
import Maldives from './components/home/maldives/Maldives';
import Aboutus from './components/home/maldives/Aboutus';
import TravellersEssentials from './components/home/maldives/TravellersEssentials'
import PackagesPage from './components/home/packages/PackagesPage';

function App() {
  const path = useLocation().path
  const {isLoggedIn, emailVerified} = useContext(Context)

  let routes;

    if (isLoggedIn && !emailVerified) {
      routes = (
        <Switch>
          <Route exact path='/' component={LandingPage} />
          <Route exact path='/properties' component={PropertiesPage} />
          <Route exact path='/properties/:slug' component={PropertiesPage} />
          <Route exact path='/packages/:slug' component={PackagesPage} />
          <Route exact path='/properties/:slug/:code' component={RoomDetails} />
          <Route exact path='/stay/:slug' component={StayPage} />
          <Route exact path='/verify-email' component={VerifyEmail} />
          <Route exact path='/resetpass' component={ResetPass} />
          <Route exact path='/maldives' component={Maldives} />
          <Route exact path='/aboutus' component={Aboutus} />
          <Route exact path='/travellersessentials' component={TravellersEssentials} />
          <Redirect to='/verify-email' />
        </Switch>

      )
    } else if (isLoggedIn && emailVerified ) {
      routes = (
        <Switch>
          <Route exact path='/' component={LandingPage} />
          <Route exact path='/properties/:slug' component={PropertiesPage} />
          <Route exact path='/packages/:slug' component={PackagesPage} />
          <Route exact path='/properties/:slug/:code' component={RoomDetails} />
          <Route exact path='/stay/:slug' component={StayPage} />
          <Route path = '/travadm' component={Admin} />
          <Route exact path='/resetpass' component={ResetPass}/>
          <Route exact path='/maldives' component={Maldives} />
          <Route exact path='/aboutus' component={Aboutus} />
          <Route exact path='/travellersessentials' component={TravellersEssentials} />
          <Redirect to='/travadm' />
        </Switch>
      )
    } else {
      routes = (
        <Switch>
          <Route exact path='/' component={LandingPage} />
          <Route exact path='/properties' component={PropertiesPage} />
          <Route exact path='/packages/:slug' component={PackagesPage} />
          <Route exact path='/properties/:slug' component={PropertiesPage} />
          <Route exact path='/properties/:slug/:code' component={RoomDetails} />
          <Route exact path='/stay/:slug' component={StayPage} />
          <Route exact path='/travadm-login' component={LoginForm}/>
          <Route exact path='/resetpass' component={ResetPass}/>
          <Route exact path='/maldives' component={Maldives} />
          <Route exact path='/aboutus' component={Aboutus} />
          <Route exact path='/travellersessentials' component={TravellersEssentials} />
          <Redirect to='/travadm-login' />
        </Switch>
      )
    }

  return (
    <>
      {routes}
    </>
  );
}

export default App;
