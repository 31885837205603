import React, { useContext, useState, useEffect } from 'react'
import { Context } from '../../../../context/Context'
import PropTypeListItem from './PropTypeListItem'
import {useLocation} from 'react-router-dom'
import ReactTooltip from 'react-tooltip'



function PropTypeList() {
    const location = useLocation()
    const [activePropertyTypes, setActivePropertyTypes] = useState([])
    const [trashPropertyTypes, setTrashPropertyTypes] = useState([])

    const {allPropertyTypes} = useContext(Context)

    useEffect(() => {
        var activePropTypes = []
        var inactivePropTypes = []

        activePropTypes = allPropertyTypes.filter(item => !item.isTrash)
        inactivePropTypes = allPropertyTypes.filter(item => item.isTrash)

        setActivePropertyTypes(activePropTypes.sort())
        setTrashPropertyTypes(inactivePropTypes.sort())
    }, [allPropertyTypes]) 


    const propertyTypes = activePropertyTypes.map(item  => {
            return(
                <PropTypeListItem key={item.id} id={item.id} name={item.name} desc={item.description} slug={item.slug}/>
            )
        }
    )


    const trashedPropertyTypes = trashPropertyTypes.map(item  => {
        return(
            <PropTypeListItem key={item.id} id={item.id} name={item.name} desc={item.description} slug={item.slug}/>
        )
    }
)


    return (
        <div className='propTypeList'>
            <table>
                <thead>
                    <tr>
                        <th colSpan='2'>Name</th>
                        <th>Slug</th>
                    </tr>
                </thead>
                <tbody>
                    {!location.pathname.includes('trash') ? propertyTypes : trashedPropertyTypes}
                </tbody>
            </table>
            <ReactTooltip />
        </div>
    )
}

export default PropTypeList;