import React from 'react'
import ListItemActions from '../../../../base/ListItemActions'


function IslandsListItem(props) {

    const {id, name, country, atoll, code, type, slug} = props

    return (
        <>
            <tr className='propertyItem'>
                <td colSpan='2'>{name}</td>
                <td>{code}</td>
                <td>{country}</td>
                <td>{atoll}</td>
                <td>{type}</td>
                <td><ListItemActions actionType='islands' slug={slug} itemId={id}/></td>
            </tr>
        </>

    )
}

export default IslandsListItem;