import React, { useEffect, useState } from 'react';
import {useLocation, Link} from 'react-router-dom'


function AdminActions() {
    const location = useLocation();
    const path = location.pathname
    var allItemsPath = path.includes('edit') ? path.split('/').slice(0,-2).join('/') : path.split('/').slice(0,-1).join('/')
    const actionSuffix = path.split('/')[path.split('/').length -1]

    function ActionButtonGroup() {
        const addAction =
        <Link to={`${path}/add`}>
            <div className='adminAction'>
                <i className="ri-add-fill"></i>
                <p>Add New</p>
            </div>
        </Link>
    
        const trashAction =
        <Link to={`${path}/trash`}>
            <div className='adminAction'>
                <i className="ri-restart-fill"></i>
                <p>Trash</p>
            </div>
        </Link>
    
        const viewAllAction =
        <Link to={path.includes('add') || path.includes('trash') || path.includes('edit')  ? allItemsPath : path}>
            <div className='adminAction'>
                <i className="ri-list-unordered"></i>
                <p>View All Items</p>
            </div>
        </Link>
  
        if (path.includes("add") || path.includes("edit") || path.includes("trash")) {
            return (
                <>
                    {viewAllAction}
                </>
            )
        } else if (path.includes('locations')) {
            return (
                <>
                    {addAction}
                </>
            )
        }else if (path.includes('amenities')) {
            return (
                <>
                    {addAction}
                </>
            )
        } else if (path.includes('packages')) {
            return (
                <>
                    {addAction}
                </>
            )
        } else {
            return (
                <>
                    {addAction}
                    {trashAction}
                </>
            )
        }
    }

    function actionTitle() {

        if(path.includes('property-types')) {
            if(actionSuffix === 'add') {
                return(
                    <>
                        <h1>Property Types</h1>
                        <h2>Add Property Type</h2>
                    </>
                )
            } else if (actionSuffix === 'edit') {
                return(
                    <>
                        <h1>Property Types</h1>
                        <h2>Edit Property Type</h2>
                    </>
                )
            } else if(actionSuffix === 'trash') {
                return(
                    <>
                        <h1>Property Types</h1>
                        <h2>Trash</h2>
                    </>
                )
            }
            return(
                <h1>Property Types</h1>
            )
        } else if (path.includes('holiday-types')) {
            if(actionSuffix === 'add') {
                return(
                    <>
                        <h1>Holiday Types</h1>
                        <h2>Add Holiday Type</h2>
                    </>
                )
            } else if (actionSuffix === 'edit') {
                return(
                    <>
                        <h1>Holiday Types</h1>
                        <h2>Edit Holiday Type</h2>
                    </>
                )
            } else if(actionSuffix === 'trash') {
                return(
                    <>
                        <h1>Holiday Types</h1>
                        <h2>Trash</h2>
                    </>
                )
            }
            return(
                <h1>Holiday Types</h1>
            )
        } else if (path.includes('locations')) {
            if(path.includes('countries')) {
                if(actionSuffix === 'add') {
                    return(
                        <>
                            <h1>Locations</h1>
                            <h2>Add Country</h2>
                        </>
                    )
                } else if (actionSuffix === 'edit') {
                    return(
                        <>
                            <h1>Locations</h1>
                            <h2>Edit Country</h2>
                        </>
                    )
                }
                return(
                    <>
                        <h1>Locations</h1>
                        <h2>Countries</h2>
                    </>
                )
            } else if (path.includes('atolls')) {
                if(actionSuffix === 'add') {
                    return(
                        <>
                            <h1>Locations</h1>
                            <h2>Add Atoll</h2>
                        </>
                    )
                } else if (actionSuffix === 'edit') {
                    return(
                        <>
                            <h1>Locations</h1>
                            <h2>Edit Atoll</h2>
                        </>
                    )
                }
                return(
                    <>
                        <h1>Locations</h1>
                        <h2>Atolls</h2>
                    </>
                )

            } else if (path.includes('islands')) {
                if(actionSuffix === 'add') {
                    return(
                        <>
                            <h1>Locations</h1>
                            <h2>Add Island</h2>
                        </>
                    )
                } else if (actionSuffix === 'edit') {
                    return(
                        <>
                            <h1>Locations</h1>
                            <h2>Edit Island</h2>
                        </>
                    )
                }
                return(
                    <>
                        <h1>Locations</h1>
                        <h2>Islands</h2>
                    </>
                )
            }
        } else if (path.includes('properties')) {
            if (actionSuffix  === 'add') {
                return(
                    <>
                        <h1>Properties</h1>
                        <h2>Add Property</h2>
                    </>
                )

            } else if (actionSuffix === 'edit') {
                return(
                    <>
                        <h1>Properties</h1>
                        <h2>Edit Property</h2>
                    </>
                )

            } else if (actionSuffix  === 'trash') {
                return(
                    <>
                        <h1>Properties</h1>
                        <h2>Trash</h2>
                    </>
                )

            } else {
                return(
                    <>
                        <h1>Properties</h1>
                    </>
                )
            }
        } else if (path.includes('packages')) {
            if (actionSuffix  === 'add') {
                return(
                    <>
                        <h1>Packages</h1>
                        <h2>Add Packages</h2>
                    </>
                )

            } else if (actionSuffix === 'edit') {
                return(
                    <>
                        <h1>Packages</h1>
                        <h2>Edit Packages</h2>
                    </>
                )

            } else {
                return(
                    <>
                        <h1>Packages</h1>
                    </>
                )
            }
        }
    }
   

    return(
        <div className='adminActionBar'>
            <div className='actionTitle'>
                {actionTitle()}
            </div>
            <div className='actionEnd'>
                {ActionButtonGroup()}
            </div>
        </div>
    )
}

export default AdminActions;