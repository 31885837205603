import React from 'react'


function PropertiesFilter() {
    return (
        <div className='propertiesFilter'>
            filter
        </div>
    )
}

export default PropertiesFilter;