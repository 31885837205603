import React from 'react';

import { Route, Switch } from 'react-router'

import AdminActions from '../../base/AdminActions'
import PropTypeListContainer from './proptypelist/PropTypeListContainer'
import PropTypeFormContainer from './proptypeform/PropTypeFormContainer'



function PropTypeContainer() {
    return (
        <div className='propTypeContainer'>
            <AdminActions />
            <Switch>
                <Route exact path='/travadm/property-types' component={PropTypeListContainer} />
                <Route exact path='/travadm/property-types/add' component={PropTypeFormContainer} />
                <Route exact path='/travadm/property-types/trash' component={PropTypeListContainer} />
                <Route exact path='/travadm/property-types/:slug/edit' component={PropTypeFormContainer} />
            </Switch> 
        </div>    
    )
}


export default PropTypeContainer;