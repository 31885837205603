import React from 'react'
import { useRef } from 'react';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import GoogleMapsContainer from '../../admin/base/GoogleMapsContainer';
import { Context } from '../../context/Context';
import MainFooter from '../shared/MainFooter';
import MainHeader from '../shared/MainHeader';
import RoomItem from './RoomItem';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import Gallery from 'react-grid-gallery';
import FbImageLibrary from 'react-fb-image-grid'
import './style.css'






function PropertiesPage() {
    const [downloadUrl, setDownloadUrl] = useState('')
    const [name, setName] = useState('')
    const [desc, setDesc] = useState('')
    const [rating, setRating] = useState('')
    const [long, setLong] = useState('')
    const [lat, setLat] = useState('')
    const [type, setType] = useState('')
    const [address, setAddress] = useState('')
    const [propId, setPropId] = useState('')
    const [amenities, setAmenities] = useState('')
    const [transport, setTransport] = useState([])
    const [maxAmenities, setMaxAmenities] = useState(0)

    const [fixedMenu, setFixedMenu] = useState(false)
    const [ovActive, setOvActive] = useState(false)
    const [rActive, setRActive] = useState(false)
    const [lActive, setLActive] = useState(false)
    const [aActive, setAActive] = useState(false)
    const [imageList, setImageList] = useState([])

    const ovRef = useRef()
    const rmRef = useRef()
    const lRef = useRef()
    const aRef = useRef()
    const propMenuRef = useRef()

    const ovSecRef = useRef()
    const rSecRef = useRef()
    const lSecRef = useRef()
    const aSecRef = useRef()
    const pageWrapperRef = useRef()

    let hash = useLocation().hash

    // document.oncontextmenu = (e) => {
    //     if (e.button == 2) {
    //         e.preventDefault();
    //         return false;
    //     }
    // }

    const slugParam = useParams().slug
    const { getAllBatchImages, setImages, images, allProperties, getAllRoomTypes, allRoomTypes, setAllRoomTypes, allAmenities, allAmenitiesCategories } = useContext(Context)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    console.log(imageList)
    useEffect(() => {
        if (hash === '#overview') {
            setOvActive(true)
            setRActive(false)
            setLActive(false)
            setAActive(false)
        } else if (hash === '#rooms') {
            setOvActive(false)
            setRActive(true)
            setLActive(false)
            setAActive(false)
        } else if (hash === '#location') {
            setOvActive(false)
            setRActive(false)
            setLActive(true)
            setAActive(false)
        }
        else if (hash === '#amenities') {
            setOvActive(false)
            setRActive(false)
            setLActive(false)
            setAActive(true)
        }
    }, [hash])

    useEffect(() => {
        getAllRoomTypes(propId)
        getAllBatchImages(propId, 'properties')
        return setAllRoomTypes([]), setImages([])
    }, [propId])

    useEffect(() => {
        const currImages = []
        currImages.push(downloadUrl)
        images.map(item => {
            currImages.push(item.imageUrl)
            setImageList(currImages)
        })
    }, [images])

    const fixMenu = () => {
        if (window.scrollY >= 140) {
            setFixedMenu(true)
        } else {
            setFixedMenu(false)
        }
    }

    window.addEventListener('scroll', fixMenu)

    useEffect(() => {
        const prop = allProperties.find(item => item.slug === slugParam)

        if (prop) {
            setDownloadUrl(prop.downloadUrl)
            setName(prop.name)
            setRating(prop.rating)
            setDesc(prop.description)
            setLat(prop.latitude)
            setLong(prop.longitude)
            setType(prop.type)
            setAddress(prop.address)
            setPropId(prop.id)
            images.map(item => {
                console.log('images', item)
            })

            if (prop.transportTypes) {
                setTransport(prop.transportTypes)
            }
            if (prop.amenities) {
                var currAmenities = getAmenities(prop.amenities)
                setAmenities(currAmenities)
            }
        }
    }, [allProperties, allAmenities])

    function getAmenities(propAmenities) {
        var filteredAmenities = []
        if (propAmenities) {
            propAmenities.map(item => filteredAmenities.push(allAmenities.find(amenity => amenity.id === item)))
            return (filteredAmenities.filter(item => typeof (item) !== 'undefined'))
        }
    }

    function starRatingDisplay() {
        const stars = []
        for (var i = 0; i < parseInt(rating); i++) {
            stars.push(<i className="ri-star-fill" key={i}></i>)
        }
        return (stars)
    }

    const roomItemElements = allRoomTypes.map(item => {
        return (
            <RoomItem
                key={item.id}
                id={item.id}
                name={item.name}
                size={item.size}
                downloadUrl={item.downloadUrl}
                adultCap={item.adultCapacity}
                childCap={item.childCapacity}
                code={item.code}
                propId={propId}
                propName={name}
                propSlug={slugParam}
                allAmenities={allAmenities}
                amenities={item.amenities}
                featuredAmenities={item.featuredAmenities}
            />
        )
    })


    const amenityElements = allAmenitiesCategories.map(item => {
        if (amenities.length !== 0) {
            const currAmen = amenities.filter(a => a.category === item.id)
            if (currAmen.length !== 0) {
                if (currAmen.length > maxAmenities) {
                    setMaxAmenities(currAmen.length)
                }
                return (
                    <div key={item.id} className='amenityCatItem'>
                        <h3>{item.name}</h3>
                        {currAmen.map(i => {
                            return (
                                <div key={i.id} className={name ? 'amenityItem' : 'detailLoading'}>
                                    <FontAwesomeIcon
                                        icon={i.icon}
                                    />
                                    <p>{i.name}</p>
                                </div>
                            )
                        })}

                    </div>
                )
            }
        }
    })

    function handleOverviewClick() {
        // var offset = rSecRef.current.getBoundingClientRect().height
        window.scrollTo(0, 150)
    }

    function handleRoomClick() {
        var offset = ovSecRef.current.getBoundingClientRect().height
        window.scrollTo(0, offset)
    }

    function handleLocationClick() {
        var offset = rSecRef.current.getBoundingClientRect().height + ovSecRef.current.getBoundingClientRect().height
        window.scrollTo(0, offset)
    }

    function handleAmenitiesClick() {
        var offset = rSecRef.current.getBoundingClientRect().height + ovSecRef.current.getBoundingClientRect().height + lSecRef.current.getBoundingClientRect().height

        window.scrollTo(0, offset + 20)
    }

    const transportTypes = transport.map(item => {
        return (
            <div className='transportItem' key={item.id}>
                <div className='transportImageContainer'>
                    <img src={`${process.env.PUBLIC_URL}/resources/${item.name === 'Seaplane' ? 'seaplane' : item.name === 'Speedboat' ? 'boat' : item.name === 'Domestic Flight and Speedboat' ? 'plane' : item.name === 'Taxi' ? 'taxi' : ''}.svg`} alt='' />
                </div>
                <h3>{item.name}</h3>
                <p>{item.duration}</p>
            </div>
        )
    })

    return (
        <>
            <MainHeader />
            <Helmet>
                <title>{`${name}`}</title>
            </Helmet>
            <div className='propertiesPageContainer'>
                <div className='propertiesPageWrapper' ref={pageWrapperRef}>
                    <div className='overviewSection' id='overview' ref={ovSecRef}>
                        <div className='propertyBanner'>
                            <div className='propertyTitleWrapper'>
                                <div className='propertyTitle'>
                                    <h1>{name}<span className='propTypeTag'>{type}</span></h1>
                                    <div className='propPageRating'>
                                        {starRatingDisplay()}
                                    </div>
                                </div>
                                <div ref={propMenuRef} className={fixedMenu ? 'propertyMenuContainer propertyMenuContainerFixed' : 'propertyMenuContainer'}>
                                    <div className='propertyMenu'>
                                        <ul>
                                            <a onClick={handleOverviewClick}><li className={ovActive ? 'propertyMenuActive' : ''} ref={ovRef}>Overview</li></a>
                                            {allRoomTypes.length !== 0 && <a onClick={handleRoomClick}><li className={rActive ? 'propertyMenuActive' : ''} ref={rmRef}>Rooms</li></a>}
                                            {long &&<a onClick={handleLocationClick}><li className={lActive ? 'propertyMenuActive' : ''} ref={lRef}>Location</li></a>}
                                            {amenities.length !== 0 && <a onClick={handleAmenitiesClick}><li className={aActive ? 'propertyMenuActive' : ''} ref={aRef}>Amenities</li></a>}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='propertyFeature'>
                                <div className='imageGrid'>
                                    <FbImageLibrary images={imageList} renderOverlay={() => 'View Image'} />
                                </div>
                                <div className='propertyOverview'>
                                    <div className='summaryMap'>
                                        {
                                            long ?
                                                <GoogleMapsContainer long={long} lat={lat} style={{ height: '50%', borderRadius: '20px' }} />
                                                :
                                                ''
                                        }
                                    </div>
                                    {
                                        address &&
                                        <div className='propertyAddress'>
                                            <div className='addressIcon'>
                                                <i className="ri-map-pin-fill"></i>
                                            </div>
                                            <div className='addressContent'>
                                                <p>{address}</p>
                                            </div>
                                        </div>
                                    }
                                    {
                                        desc &&
                                        <div className='propertyInfo'>
                                            <p className='propertyDesc' dangerouslySetInnerHTML={{ __html: desc }}></p>
                                        </div>
                                    }
                                    {
                                        transport.length !== 0 ?
                                            <div className='propertyTransport'>
                                                <h2>Transport Options from Male' International Airport</h2>
                                                <div className='transportWrapper'>
                                                    <div className='transportItems'>
                                                        <div className='transportElements'>
                                                            {transportTypes}
                                                        </div>
                                                    </div>
                                                    {/* <img src={`${process.env.PUBLIC_URL}/resources/island.svg`} alt=''/> */}
                                                </div>
                                            </div>
                                            :
                                            ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {allRoomTypes.length !== 0 &&
                        <div className='roomTypeItemsContainer' id='rooms' ref={rSecRef}>
                            <h1 className='propSectionHeading'>Choose Your Room</h1>
                            <div className='roomTypeWrapper'>
                                <div className='roomItems'>
                                    {roomItemElements}
                                </div>
                            </div>
                        </div>
                    }

                    <div className='propertyMap' id='location' ref={lSecRef}>

                        <h1 className='propSectionHeading'>Location</h1>
                        {
                            long ?
                                <GoogleMapsContainer long={long} lat={lat} defaultOptions={{ mapTypeId: 'satellite' }} />
                                :
                                ''
                        }
                    </div>
                    {amenities.length !== 0 &&
                        <div className='propertyAmenitiesContainer' id='amenities'>
                            <h1 className='propSectionHeading'>Facilities and Amenities</h1>
                            <div className='propertyAmenitiesWrapper'>
                                <div className='propertyAmenities'>
                                    {amenityElements}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <MainFooter />
        </>
    )
}

export default PropertiesPage;