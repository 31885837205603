import React from 'react'
import ListItemActions from '../../../base/ListItemActions'



function HolTypeListItem(props) {

    const {id, imageUrl, name, desc, slug, fileType} = props

    const nameDataItem = 
    <div className='listItemImageContainer'>
        <div className='listItemImageWrapper'><img src={imageUrl} alt=''/></div>
        <div className='listItemTextWrapper'><p data-tip={desc} data-place='bottom'>{name}</p></div>
    </div>

    const slugDataItem =
    <div className='listItemImageContainer'>
        <p>{slug}</p>
    </div>


    return (
        <>
            <tr className='holTypeItem'>
                <td colSpan='2'>{nameDataItem}</td>
                <td>{slugDataItem}</td>
                <td><ListItemActions actionType='holidayTypes' itemId={id} slug={slug} fileType={fileType}/></td>
            </tr>
        </>

    )

}

export default HolTypeListItem;