import React, {useContext} from 'react';
import { Context } from '../../context/Context';
import SidebarItem from './SidebarItem';

function Sidebar() {

    const {userDetails, userDetailsLoaded} = useContext(Context)

    return (
        <div className="sideBar">
            <div className='sidebarContent'>
                <SidebarItem icon="ri-dashboard-fill" title="Dashboard" path=""/>
                <SidebarItem icon="ri-archive-fill" title="Packages" path="packages"/>
                <SidebarItem icon="ri-building-fill" title="Properties" path="properties"/>
                <SidebarItem icon="ri-price-tag-2-fill" title="Property Types" path="property-types"/>
                <SidebarItem icon="ri-sun-fill" title="Holiday Types" path="holiday-types"/>
                <SidebarItem icon="ri-map-pin-fill" title="Locations" path="locations/countries"/>
                <SidebarItem icon="ri-list-check-2" title="Amenities" path="amenities"/>
                <SidebarItem icon="ri-sailboat-fill" title="Transport" path="transport"/>
                {userDetailsLoaded && userDetails.userLevel <= 2 && <SidebarItem icon="ri-folder-user-fill" title="Users" path="users"/>}
            </div>
        </div>
    );
}

export default Sidebar;