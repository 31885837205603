import React, {useContext} from "react"
import { Context } from "../../../../context/Context";
import AmenitiesCatItems from "../amenitiesCategories/AmenitiesCatItems";
import AmenitiesCatForm from "./AmenitiesCatForm";

function AmenitiesCategoriesList(){

    const {allAmenitiesCategories} = useContext(Context)
    console.log("This is what the context is providing",allAmenitiesCategories)
    const amenitiesCategories = allAmenitiesCategories.map(item => {
        return(
            <AmenitiesCatItems key={item.id} id={item.id} name={item.name} slug={item.slug} />
        )
    })


    return (
        <div>
            <h2 className='amenitiesh1'>Amenities Categories</h2>
            <div className='propTypeList'>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Slug</th>
                        </tr>
                    </thead>
                    <tbody>
                        {amenitiesCategories}
                    </tbody>
                </table>
            </div>
            <AmenitiesCatForm/>
        </div>

    )
}
export default AmenitiesCategoriesList;