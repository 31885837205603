import React, { useContext } from 'react'
import { Context } from '../../../../context/Context'
import PackageListItem from './PackageListItem'


function PackagesList() {

    const {allPackages} = useContext(Context)

    const packageItems = allPackages.map(item => {
        return (
            <PackageListItem key={item.id} id={item.id} name={item.name} slug={item.slug} />
        )
    })


    return (
        <div className='propTypeList'>
            <table>
                <thead>
                    <tr>
                        <th colSpan='2'>Package Name</th>
                    </tr>
                </thead>
                <tbody>
                    {packageItems}
                </tbody>
            </table>
        </div>
    )

}

export default PackagesList