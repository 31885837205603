import React, { useContext, useEffect } from 'react';
import UserItem from './UserItem';
import AddUserItem from './AddUserItem'
import { Context } from '../../context/Context';


function UserContainer() {

    const {allUsers, userDetails, userDetailsLoaded} = useContext(Context)


    const userItems = allUsers.map(item => {
        return(
            <UserItem key={item.id} uid={item.id} name={item.name} email={item.email} level={item.userLevel} disabled={item.disabled} currentUserLevel={userDetailsLoaded && userDetails.userLevel}/>
        )
    })

    return (
        <div className='userContainer'>
            <AddUserItem />
            {userItems}
        </div>
    )
}

export default UserContainer;