import React from 'react'
import ListItemActions from '../../../base/ListItemActions'



function AmenitiesCatItems(props) {

    const {id, name, slug} = props

    const nameDataItem = 
    <div className='listItemTextContainer'>
        <div className='listItemTextWrapper'><p data-place='bottom'>{name}</p></div>
    </div>

    const slugDataItem =
    <div className='listItemTextWrapper'>
        <p>{slug}</p>
    </div>


    return (
        <>
            <tr className='holTypeItem'>
                <td>{nameDataItem}</td>
                <td>{slugDataItem}</td>
                <td><ListItemActions actionType='amenitiesCategory' itemId={id} slug={slug}/></td>
            </tr>
        </>

    )

}

export default AmenitiesCatItems;