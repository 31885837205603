import React, { useEffect, useState } from 'react'
import ListItemActions from '../../../base/ListItemActions'
import { useLocation } from 'react-router';




function SelectedTransportItem(props) {

    const {transportObj, all, setAll, selected, setSelected, save, setSave, index} = props
    const [duration, setDuration] = useState('')
    const [saved, setSaved] = useState(false)

    const path = useLocation().pathname

    useEffect(() => {
        if(transportObj.duration) {
            setSaved(true)
            setDuration(transportObj.duration)
        }
    }, [path,selected])



    function handleCancelClick() {
        if(path.includes('edit')) {
            setSelected(selected.filter(item => item.slug !== transportObj.slug))
            setSave(selected.filter(item => item.slug !== transportObj.slug))
            setAll([...all, transportObj])
        } else {
            setSelected(selected.filter(item => item.slug !== transportObj.slug))
            setAll([...all, transportObj])
        }
    }

    function handleSaveClick() {
        if(duration) {
            setSaved(true)
            setSave([...save, {name:transportObj.name, slug:transportObj.slug, duration:duration}])
            // setSelected([...selected, {name:transportObj.name, slug:transportObj.slug, duration:duration}])
        } else {
            window.alert('Please enter duration to continue adding transport type.')
        }
    }

    const cancelAction = 
    <div data-tip='Cancel' data-place='bottom' onClick={handleCancelClick}>
        <i className="ri-close-circle-line"></i>
    </div> 

    const saveAction = 
    <div data-tip='Add' data-place='bottom' onClick={handleSaveClick}>
        <i className="ri-checkbox-circle-line"></i>
    </div>


    return(
        <>
        {
            !saved ?
            <>
                <tr>
                    <td colSpan='2'>{transportObj.name}</td>
                    <td className='durationColumn'>
                    <input 
                        className='formInput durationInput' 
                        placeholder='Enter duration (mins)...'
                        type='text'
                        value={duration}
                        onChange={(event) => {
                            const {value} = event.target
                            setDuration(value)
                        }}
                        />
                    </td>
                    <td className='transportActions'>
                        <div>
                            {saveAction}
                            {cancelAction}
                        </div>
                    </td>

                </tr>
            </>
            :
            <>
                <tr>
                    <td colSpan='2'>{transportObj.name}</td>
                    <td className='durationColumn'>
                        {duration}
                    </td>
                    <td className='transportActions'>
                        <div>
                        {cancelAction}
                        </div>
                    </td>

                </tr>
            </>
        }
        </>
        
    )
}

export default SelectedTransportItem