import React, { useState, useContext, useEffect } from 'react';
import { Link, useHistory} from 'react-router-dom';

import {Context} from '../context/Context'

function LoginForm() {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [hiddenMessage, setHiddenMessage] = useState(true)

    const { handleLogin, emailError, passwordError, user, isLoggedIn, emailVerified} = useContext(Context)

    useEffect(() => {
        if (isLoggedIn && emailVerified) {
            setEmail('')
            setPassword('')
        }
    }, [emailVerified, isLoggedIn, user])

    useEffect(() => {
        if(emailError || passwordError) {
            setHiddenMessage(true)
        } else {
            setHiddenMessage(false)
        }


    }, [emailError, passwordError])


    function handleEmailChange(event) {
        const { value } = event.target;
        setEmail(value)
    }

    function handlePasswordChange(event) {
        const { value } = event.target;
        setPassword(value)
    }

    function handleClick(event) {
        event.preventDefault()
        handleLogin(email, password)
    }

    const errorMsg = 
    <div className='errorCard'>
        <p>The email and/or password you entered is incorrect. Please try again.</p>
    </div>

    return(
        <div className='loginContainer'>
            <div className='loginWrapper'>
                <div className='loginImage'>
                    <img className='adminLogo' src={process.env.PUBLIC_URL + '/resources/TravLogoMonoGrad.png'} alt='logo'/>
                </div>
                {hiddenMessage ? errorMsg : ''}
                <div className='loginCard'>
                    <form className="loginForm">
                        <div className='loginInputFieldGroup'>
                            <label>
                                Email Address
                            </label>
                            <input 
                            className='formInput loginInput' 
                            placeholder='Enter Email'
                            onChange={handleEmailChange}
                            value={email}
                            type='email'
                            />

                            <label>
                                Password
                            </label>
                            <input 
                            className='formInput loginInput' 
                            placeholder='Enter Password'
                            onChange={handlePasswordChange}
                            type='password'
                            />
                        </div>

                        <div className='loginButtonRow'>
                            <button 
                            className="formButton loginButton" 
                            onClick={handleClick}>
                                Sign In
                            </button>
                            <Link to="/resetpass"><p>Forgot Password?</p></Link>
                        </div>
                    </form>
                </div>
                <Link to="/"><p className='loginHomeLink'>Go back to TravGo home page</p></Link>
            </div>
        </div>
    )
}

export default LoginForm;
