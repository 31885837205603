import React from 'react'
import ListItemActions from '../../../../base/ListItemActions'


function CountriesListItem(props) {
    const { id, name, code, slug } = props
    return (
        <>
            <tr className='propertyItem'>
                <td colSpan='3'>{name}</td>
                <td colSpan='2'>{code}</td>
                <td colSpan='1'><ListItemActions actionType='countries' itemId={id} slug={slug}/></td>
            </tr>
        </>

    )
}

export default CountriesListItem;