import React from 'react';

import { Route, Switch } from 'react-router'

import AdminActions from '../../base/AdminActions'
import HolTypeFormContainer from './holtypeform/HolTypeFormContainer';
import HolTypeListContainer from './holtypelist/HolTypeListContainer';




function HolTypeContainer() {
    return (
        <div className='propTypeContainer'>
            <AdminActions />
            <Switch>
            <Route exact path='/travadm/holiday-types' component={HolTypeListContainer} />
                <Route exact path='/travadm/holiday-types/add' component={HolTypeFormContainer} />
                <Route exact path='/travadm/holiday-types/trash' component={HolTypeListContainer} />
                <Route exact path='/travadm/holiday-types/:slug/edit' component={HolTypeFormContainer} />
            </Switch> 
        </div>    
    )
}


export default HolTypeContainer;