import React from 'react'
import AdminContent from './AdminContent'
import Sidebar from './Sidebar'

function AdminContentWrapper() {
    return (
        <div className='adminContentWrapper'>
            <Sidebar />
            <AdminContent />
        </div>
    )

}

export default AdminContentWrapper