import React, { useContext, useEffect, useState } from 'react'
import FbImageLibrary from 'react-fb-image-grid'
import { Context } from '../../context/Context'
import '../propertiespage/style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function PackageRoomItem(props) {

    const [featuredAmenities, setFeatutredAmenities] = useState([])
    const [roomAmenities, setRoomAmenities] = useState([])
    const [roomToggle, setRoomToggle] = useState(false)

    console.log(roomAmenities)

    const {
        getAllBatchImages,
        roomTypeImages,
        setRoomTypeImages,
        allAmenities,
        allAmenitiesCategories

    } = useContext(Context)

    const [roomImages, setRoomImages] = useState([])

    const {
        roomItem,
        roomImageList
    } = props

    useEffect(() => {

        setRoomTypeImages([])
        getAllBatchImages(roomItem.id, "properties/roomTypes")

        if (roomItem.featuredAmenities) {
            const featAmenities = []
            roomItem.featuredAmenities.map(item => {
                featAmenities.push(allAmenities.find(i => i.id === item))
            })
            setFeatutredAmenities(featAmenities)
        }

        if (roomItem.amenities) {
            const amenities = []
            roomItem.amenities.map(item => {
                amenities.push(allAmenities.find(i => i.id === item))
            })
            setRoomAmenities(amenities)

        }

        return setRoomTypeImages([])

    }, [roomItem])


    useEffect(() => {
        setRoomImages([])
        const images = []
        images.push(roomItem.downloadUrl)

        if (roomImageList.length > 0) {
            const currImages = roomImageList.filter(item => item.parentId === roomItem.id)
            currImages.map(item => images.push(item.imageUrl))
        }


        setRoomImages(images)

    }, [roomImageList])

    const featuredAmenityElements = featuredAmenities.map(item => {
        return (
            <div key={item.id} className={'featuredItem'}>
                <FontAwesomeIcon
                    icon={item.icon}
                />
                <p>{item.name}</p>
            </div>
        )
    })

    const amenityElements = allAmenitiesCategories.map((item) => {
        try {
            console.log(roomAmenities)
            let currAmen =[]
            //i.category === item.id
            currAmen = roomAmenities.filter(i => i && i.category === item.id)
            if (currAmen.length > 0) {
                return (
                    <div key={item.id} className='amenityCatItem'>
                        <h3>{item.name}</h3>
                        {
                            currAmen.map(i => {
                                return (
                                    <div key={i.id} className={roomItem ? 'amenityItem' : 'detailLoading'}>
                                        <FontAwesomeIcon
                                            icon={i.icon}
                                        />
                                        <p>{i.name}</p>
                                    </div>
                                )
                            })
                        }

                    </div>
                )
            }
        } catch (e) {
            console.log('F', e)
        }
    })

    

    function handleToggleClick() {
        setRoomToggle(!roomToggle)
    }

    return (
        <div className={`${roomToggle ? 'packageRoomItemExp' : 'packageRoomItem'}`} onClick={() => {!roomToggle && handleToggleClick()}}>
            <div className='roomTitle'>
                <h1>{roomItem && roomItem.name}</h1>
                {
                roomToggle ?
                <i className="ri-arrow-up-s-line" onClick={handleToggleClick}></i>
                :
                <i className="ri-arrow-down-s-line"onClick={handleToggleClick}></i>
                }
            </div>
            {
                roomToggle ?
            <div className='roomContent'>
                <div className='imageGrid'>
                    <FbImageLibrary images={roomImages} renderOverlay={() => 'View Image'} />
                </div>
                <div className='roomSummary'>
                    {
                        <div className='roomInfoItems'>
                            <div className={roomItem ? 'highlightItem' : 'detailLoading'}>
                                <i className="ri-shape-fill" />
                                <p>{`${roomItem.size} sq ft`}</p>
                            </div>
                            <div className={roomItem ? 'highlightItem' : 'detailLoading'}>
                                <i className="ri-user-fill"></i>
                                <p>{roomItem.adultCapacity && `Sleeps ${roomItem.adultCapacity} ${roomItem.adultCapacity > 1 ? 'Adults' : 'Adult'}`}</p>
                            </div>
                            <div className={roomItem ? 'highlightItem' : 'detailLoading'}>
                                <i className="ri-parent-fill"></i>
                                <p>{roomItem.adultCapacity && `Sleeps ${roomItem.childCapacity} ${roomItem.childCapacity > 1 ? 'Children' : 'Child'}`}</p>
                            </div>
                        </div>
                    }

                    <div className='roomFeaturedAmenities'>
                        {featuredAmenityElements}
                    </div>

                    <p>{roomItem.desc}</p>

                    <div className={roomItem ? 'roomAmenitiesList' : 'detailLoading'}>
                        <div className='amenityHeading'>
                            {roomItem && <h2>Room Amenities</h2>}
                        </div>
                        <div className='amenityElements packageAmenities'>
                            {amenityElements}
                        </div>
                    </div>
                </div>
            </div>
            :
            ''
            }
        </div>
    )
}

export default PackageRoomItem