import React, {useContext} from "react"
import { Context } from "../../../../context/Context";
import AmenitiesListItems from "./AmenitiesListItems";
import AmenitiesCategoriesList from "../amenitiesCategories/AmenitiesCategoriesList";

function AmenitiesList(){

    const {allAmenities} = useContext(Context)

    const amenities = allAmenities.map(item => {
        return(
            <AmenitiesListItems key={item.id} id={item.id} name={item.name} icon={item.icon} description={item.description} slug= {item.slug} />
        )
    })


    return (
        <div>
            <h2 className='amenitiesh1'>Amenities</h2>
            <div className='propTypeList'>
                <table>
                    <thead>
                        <tr>
                            <th  colSpan='2'>Icon</th>
                            <th>Name</th>
                            <th>Slug</th>
                        </tr>
                    </thead>
                    <tbody>
                        {amenities}
                    </tbody>
                </table>
            </div>
            <AmenitiesCategoriesList/>      
        </div>
    )
}
export default AmenitiesList;