import React, { useState } from 'react'
import { useContext } from 'react'
import ReactTooltip from 'react-tooltip'
import { Context } from '../../../../../../context/Context'
import Modal from 'react-modal';
import BatchImageUpload from '../../../../../base/batchimageupload/BatchImageUpload';
import RoomTypeFormContainer from '../roomtypeform/RoomTypeFormContainer';



function RoomTypeListItem(props) {
    const {deleteRoomTypes, getAllRoomTypes, roomTypeImages, deleteImageBatchItem, sleep} = useContext(Context)
    const {roomType, propId} = props
    const {id, name, adultCapacity:adult, childCapacity:child, size, downloadUrl, fileType, code} = roomType
    const [toggle, setToggle] = useState(false)
    const [toggleEdit, setToggleEdit] = useState(false)

    async function handleDeleteClick() {
        if (id) {
            if(roomTypeImages.length > 0) {
                roomTypeImages.forEach(async (image)  => {
                    deleteImageBatchItem(image.id, image.fileType, 'properties/roomTypes')
                    await sleep(1000)
                })
            }
            deleteRoomTypes(id, fileType)
            getAllRoomTypes(propId)
            
        } 
    }

    function handleUploadClick() {
        setToggle(true)
    }

    const nameDataItem = 
    <div className='listItemImageContainer'>
        <div className='listItemImageWrapper'><img src={downloadUrl} alt=''/></div>
        <div className='listItemTextWrapper'>{name}</div>
    </div>


    const deleteAction = 
    <div data-tip='Delete' data-place='bottom'  className='deleteAction' onClick={handleDeleteClick}>
        <i className="ri-close-circle-fill"></i>
        <ReactTooltip />
    </div> 

    const uploadAction = 
    <div data-tip='Upload Images' data-place='bottom'  className='restoreAction' onClick={handleUploadClick}>
        <i className="ri-file-upload-fill"></i>
        <ReactTooltip />
    </div> 

    const editAction =
    <div data-tip='Edit' data-place='bottom' className='editAction' onClick={handleEditClick}>
        <i className="ri-pencil-fill"></i>
        <ReactTooltip />
    </div>

    function handleEditClick() {
        setToggleEdit(true)
    }


    return (
        <>
            <Modal 
            isOpen={toggle}
            contentLabel="Minimal Modal Example"
            ariaHideApp={false}
            >
            <div className='propertiesBatchImageRowGroup'>
                <div className='propertiesFormSection'>
                <h1>Upload Aditional Images for Room Type</h1>
                    <BatchImageUpload parentId={id} cat='properties/roomTypes' setToggle={setToggle}/>
                </div>
            </div>
            </Modal>

            <Modal 
            isOpen={toggleEdit}
            contentLabel="Minimal Modal Example"
            ariaHideApp={false}
            >
                <RoomTypeFormContainer 
                setToggleModal={setToggleEdit} 
                parentId={propId}
                roomType={roomType}
                action={'edit'}
                />
            </Modal>
            <tr className='roomTypeItem'>
                <td colSpan='2'>{nameDataItem}</td>
                <td>{adult}</td>
                <td>{child}</td>
                <td>{size}</td>
                <td>
                    <div className='listItemActionsBar'>
                        {uploadAction}
                        {editAction}
                        {deleteAction}
                    </div>
                </td>
            </tr>
        </>
    )

}

export default RoomTypeListItem;