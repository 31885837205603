import React, { useState, useContext, useEffect } from 'react';
import { useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Context } from '../../../../../context/Context'

import Select from 'react-select'

function AtollsFormContainer() {

    const [name, setName] = useState('')
    const [country, setCountry] = useState('')
    const [code, setCode] = useState('')
    const [abbrev, setAbbrev] = useState('')
    const [type, setType] = useState('')
    const [atollId, setCountryId] = useState('')
    const [slug, setSlug] = useState('')

    const [countryOptions, setCountryOptions] = useState([])

    const {createAtoll, allCountries, allAtolls, updateAtoll, toastNotification} = useContext(Context)
    const history = useHistory()
    const nameRef = useRef(null)
    const countryRef = useRef(null)
    const path = useLocation().pathname
    const slugParam = useParams().slug
    const typeOptions = [
        {value: 'State', label: 'State'},
        {value: 'Atoll', label: 'Atoll'},
        {value: 'City', label: 'City'},
    ]

    useEffect(() => {
        if(path.includes('edit')) {
            var atoll = allAtolls.find(item=>item.slug === slugParam)
            setName(atoll.name)
            setCode(atoll.code)
            setCountry(atoll.country)
            setAbbrev(atoll.abbreviation)
            setType(atoll.type )
            setCountryId(atoll.id)
        }
        setOptionsList()
    }, [])

    function setOptionsList() {
        const countryList = []
        allCountries.forEach(country => countryList.push({value: country.name, label: country.name}))
        setCountryOptions(countryList)
    }


    const asterisk = <span style={{color: 'red'}}> * </span>

    function handleNameChange(event) {
        const {value} = event.target
        var slugValue = value.replace(/\s+/g, '-').toLowerCase()
        setName(value)
        setSlug(slugValue)
    }

    function handleCodeChange(event) {
        const {value} = event.target
        setCode(value)
    }

    function handleCountryChange(selectedCountry) {
        const {value} = selectedCountry
        setCountry(value)
    }


    function handleTypeChange(selectedType) {
        const {value} = selectedType
        setType(value)
    }

    function handleAbbrevChange(event) {
        const {value} = event.target
        setAbbrev(value)
    }


    function handleSaveClick(event) {
        event.preventDefault()
        if (name) {
            nameRef.current.classList.remove('invalidInput') 
        } else {
            nameRef.current.classList.add('invalidInput')
        }

        if(country){
            countryRef.current.classList.remove('invalidSelectInput')            
            countryRef.current.classList.add('selectCustomBorder')        
        } else {
            countryRef.current.classList.add('invalidSelectInput')
            countryRef.current.classList.remove('selectCustomBorder')        
        }

        if (!allAtolls.find(item=>item.slug === slug)) {
            if (path.includes('edit')) {
                if(window.confirm('Are you sure you want to save changes to this country?')) {
                    updateAtoll(atollId, name, country, code, abbrev, type, slug)
                    toastNotification('Changes saved successfully.')
                    history.push('/travadm/locations/atolls', null)
                }
            } else {
                if (name && country) {

                    if (window.confirm('Are you sure you want to add this country?')) {
                        createAtoll(name, country, code, abbrev, type, slug)
                        history.push('/travadm/locations/atolls', null)
                    }
                } else {
                    window.alert('Please ensure all fields highlighted in red contain a value.')
                }
            }
        } else {
            window.alert('This country already exists. Please add a unique country.')

        } 
        
    }


    function handleCancelClick(event) {
        event.preventDefault()
        if (name || country) {
            if (window.confirm('Are you sure you want to cancel adding this property type? All changes will be lost.')) {
                history.push('/travadm/locations/atolls', null)
            }
        } else {
            history.push('/travadm/locations/atolls', null)
        }
    }


    return (
        <div className='countriesFormContainer'>
            <form>
                <div className='atollsFieldGroupFull'>
                    <div className='atollsInputWrapperFull'>
                        <label>
                            Atoll Name{asterisk}:
                        </label>
                        <input 
                            className='formInput' 
                            placeholder='Enter atoll name (ex: Kaafu)'
                            type='text'
                            onChange={handleNameChange}
                            value={name}
                            ref={nameRef}
                        />
                    </div>
                </div>
                <div className='atollsFieldGroupFull'>
                    <div className='atollsInputWrapperFull'>
                        <label>
                            Country {asterisk}:
                        </label>
                        <div className='atollCountrySelectWrapper selectCustomBorder' ref={countryRef}>
                            <Select options={countryOptions} onChange={handleCountryChange} placeholder='Select Country' value={countryOptions.find(option => option.value === country)}/>
                        </div>
                    </div>
                </div>
                <div className='atollsFieldGroupFull'>
                    <div className='atollsInputWrapperTri'>
                        <label>
                            Code:
                        </label>
                        <input 
                            className='formInput' 
                            placeholder='Enter atoll code (ex: H)'
                            type='text'
                            onChange={handleCodeChange}
                            value={code}
                        />
                    </div>
                    <div className='atollsInputWrapperTri'>
                        <label>
                            Abbreviation:
                        </label>
                        <input 
                            className='formInput' 
                            placeholder='Enter atoll abbreviation (ex: K)'
                            type='text'
                            onChange={handleAbbrevChange}
                            value={abbrev}
                        />
                    </div>
                    <div className='atollsInputWrapperTri'>
                        <label>
                            Type:
                        </label>
                        <div className='atollCountrySelectWrapper selectCustomBorder'>
                            <Select options={typeOptions} onChange={handleTypeChange} placeholder='Select Type' value={typeOptions.find(option => option.value === type)}/>
                        </div>
                    </div>
                </div>
                <div className='userFormButtonRow'>
                    <button className='formButton saveButton' onClick={handleSaveClick} >{path.includes('edit') ? 'Update' : 'Add'}</button>
                    <button className='formButton cancelButton' onClick={handleCancelClick}>Cancel</button>
                </div>
            </form>
            <p>All fields marked with [{asterisk}] are mandatory</p>
        </div>
    )
}

export default AtollsFormContainer;