import React, { useState, useContext, useEffect } from 'react';
import { useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Context } from '../../../../../context/Context'

import Select from 'react-select'
import GoogleMapsContainer from '../../../../base/GoogleMapsContainer';

function IslandsFormContainer() {

    const [name, setName] = useState('')
    const [country, setCountry] = useState('')
    const [code, setCode] = useState('')
    const [atoll, setAtoll] = useState('')
    const [type, setType] = useState('')
    const [long, setLong] = useState('')
    const [lat, setLat] = useState('')
    const [islandId, setCountryId] = useState('')
    const [slug, setSlug] = useState('')

    const [countryOptions, setCountryOptions] = useState([])
    const [atollOptions, setAtollOptions] = useState([])

    const {createIsland, allCountries, allAtolls, allIslands, updateIsland, toastNotification} = useContext(Context)
    const history = useHistory()
    const nameRef = useRef(null)
    const countryRef = useRef(null)
    const atollRef = useRef(null)
    const latRef = useRef(null)
    const longRef = useRef(null)
    const path = useLocation().pathname
    const slugParam = useParams().slug
    const typeOptions = [
        {value: 'City', label: 'City'},
        {value: 'Island', label: 'Island'},
        {value: 'Ward', label: 'Ward'},
    ]

    useEffect(() => {
        if(path.includes('edit')) {
            var island = allIslands.find(item=>item.slug === slugParam)
            setName(island.name)
            setCode(island.code)
            setCountry(island.country)
            setAtoll(island.atoll)
            setType(island.type )
            setLat(island.latitude)
            setLong(island.longitude)
            setCountryId(island.id)
            setSlug(island.slug)
        }
        setOptionsList()
    }, [])

    function setOptionsList() {
        const countryList = []
        const atollList = []

        allCountries.forEach(country => countryList.push({value: country.name, label: country.name}))
        allAtolls.forEach(atoll => atollList.push({value: atoll.name, label: atoll.name}))

        setCountryOptions(countryList)
        setAtollOptions(atollList)

        
    }


    const asterisk = <span style={{color: 'red'}}> * </span>

    function handleNameChange(event) {
        const {value} = event.target
        var slugValue = value.replace(/\s+/g, '-').toLowerCase()
        setName(value)
        setSlug(slugValue)
    }

    function handleCodeChange(event) {
        const {value} = event.target
        setCode(value)
    }

    function handleCountryChange(selectedCountry) {
        const {value} = selectedCountry
        setCountry(value)
    }

    function handleAtollChange(selectedAtoll) {
        const {value} = selectedAtoll
        setAtoll(value)
    }


    function handleTypeChange(selectedType) {
        const {value} = selectedType
        setType(value)
    }

    function handleLatChange(event) {
        const {value} = event.target
        setLat(value)

    }

    function handleLongChange(event) {
        const {value} = event.target
        setLong(value)
    }




    function handleSaveClick(event) {
        event.preventDefault()
        if (name) {
            nameRef.current.classList.remove('invalidInput') 
        } else {
            nameRef.current.classList.add('invalidInput')
        }

        if(country){
            countryRef.current.classList.remove('invalidSelectInput')            
            countryRef.current.classList.add('selectCustomBorder')        
        } else {
            countryRef.current.classList.add('invalidSelectInput')
            countryRef.current.classList.remove('selectCustomBorder')        
        }

        if(atoll){
            atollRef.current.classList.remove('invalidSelectInput')            
            atollRef.current.classList.add('selectCustomBorder')

        } else {
            atollRef.current.classList.add('invalidSelectInput')
            atollRef.current.classList.remove('selectCustomBorder')

        }

        if(long){
            longRef.current.classList.remove('invalidInput')
        } else {
            longRef.current.classList.add('invalidInput')
        }

        if(lat){
            latRef.current.classList.remove('invalidInput')
        } else {
            latRef.current.classList.add('invalidInput')
        }



        if (!allIslands.find(item=>item.slug === slug)) {
            if (path.includes('edit')) {
                if(window.confirm('Are you sure you want to save changes to this Island?')) {
                    updateIsland(islandId, name, country, atoll, code, type, lat, long, slug)
                    toastNotification('Changes saved successfully')
                    history.push('/travadm/locations/islands', null)
                }
            } else {
                if (name && country) {

                    if (window.confirm('Are you sure you want to add this Island?')) {
                        createIsland(name, country, atoll, code, type, lat, long, slug)
                        history.push('/travadm/locations/islands', null)
                    }
                } else {
                    window.alert('Please ensure all fields highlighted in red contain a value.')
                }
            }
        } else {
            window.alert('This island already exists. Please add a unique island.')

        } 
        
    }


    function handleCancelClick(event) {
        event.preventDefault()
        if (name || code || country || atoll || long || lat) {
            if (window.confirm('Are you sure you want to cancel adding this property type? All changes will be lost.')) {
                history.push('/travadm/locations/islands', null)
            }
        } else {
            history.push('/travadm/locations/islands', null)
        }
    }


    return (
        <div className='countriesFormContainer'>
            <form>
                <div className='atollsFieldGroupFull'>
                    <div className='atollsInputWrapperFull'>
                        <label>
                            Atoll Name{asterisk}:
                        </label>
                        <input 
                            className='formInput' 
                            placeholder='Enter island name (ex: Thuraakunu)'
                            type='text'
                            onChange={handleNameChange}
                            value={name}
                            ref={nameRef}
                        />
                    </div>
                </div>
                <div className='atollsFieldGroupFull'>
                    <div className='islandInputWrapperBi'>
                        <label>
                            Country {asterisk}:
                        </label>
                        <div className='atollCountrySelectWrapper selectCustomBorder' ref={countryRef}>
                            <Select options={countryOptions} onChange={handleCountryChange} placeholder='Select Country' value={countryOptions.find(option => option.value === country)}/>
                        </div>
                    </div>
                    <div className='islandInputWrapperBi'>
                        <label>
                            Atoll/State {asterisk}:
                        </label>
                        <div className='atollCountrySelectWrapper selectCustomBorder' ref={atollRef}>
                            <Select options={atollOptions} onChange={handleAtollChange} placeholder='Select Atoll/State' value={atollOptions.find(option => option.value === atoll)}/>
                        </div>                    
                    </div>
                </div>

                <div className='atollsFieldGroupFull'>
                    <div className='islandInputWrapperBi'>
                        <label>
                            Code:
                        </label>
                        <input 
                            className='formInput' 
                            placeholder='Enter island code (ex: A01)'
                            type='text'
                            onChange={handleCodeChange}
                            value={code}
                        />
                    </div>
                    <div className='islandInputWrapperBi'>
                        <label>
                            Type:
                        </label>
                        <div className='atollCountrySelectWrapper selectCustomBorder'>
                            <Select options={typeOptions} onChange={handleTypeChange} placeholder='Select Type' value={typeOptions.find(option => option.value === type)}/>
                        </div>
                    </div>
                </div>

                <div className='atollsFieldGroupFull'>
                    <div className='islandInputWrapperBi'>
                        <label>
                            Latitude{asterisk}:
                        </label>
                        <input 
                            className='formInput cordNumber' 
                            placeholder='Enter latitude (ex: 7.103806)'
                            type='number'
                            onChange={handleLatChange}
                            value={lat}
                            ref={latRef}
                        />
                    </div>

                    <div className='islandInputWrapperBi'>
                        <label>
                            Logitude{asterisk}:
                        </label>
                        <input 
                            className='formInput cordNumber' 
                            placeholder='Enter longitude (ex: 72.898979)'
                            type='number'
                            onChange={handleLongChange}
                            value={long}
                            ref={longRef}
                        />                   
                    </div>
                </div>
                { path.includes('edit') ?
                <div className='atollsFielGroupFull'>
                    <div className='atollsInputWrapperFull'>
                            <GoogleMapsContainer long={long} lat={lat}/>
                    </div>
                </div>
                :
                ''
                }
                <div className='userFormButtonRow'>
                    <button className='formButton saveButton' onClick={handleSaveClick} >{path.includes('edit') ? 'Update' : 'Add'}</button>
                    <button className='formButton cancelButton' onClick={handleCancelClick}>Cancel</button>
                </div>
            </form>
            <p>All fields marked with [{asterisk}] are mandatory</p>
        </div>
    )
}

export default IslandsFormContainer;