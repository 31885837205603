import React, { useContext } from 'react'
import { Context } from '../../../../../context/Context';
import CountriesListItem from './CountriesListItem'


function CountriesList() {

    const { allCountries  } = useContext(Context)


    const countryItems = allCountries.map(item => {
        return (
            <CountriesListItem key={item.id} id={item.id} name={item.name} code={item.code} slug={item.slug} />
        )
    })


    return (
        <div className='propTypeList'>
            <table>
                <thead>
                    <tr>
                        <th colSpan='3'>Name</th>
                        <th colSpan='2'>Code</th>
                        <th colSpan='1'></th>
                    </tr>
                </thead>
                <tbody>
                    {countryItems}
                </tbody>
            </table>
        </div>
    )
}

export default CountriesList;