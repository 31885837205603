import React, {useContext} from 'react'
import { Context } from '../../../../context/Context';
import AmenitiesPickerItem from '../amenitiesPicker/AmenitiesPickerItem';

function AmenitiesPickerContainer(props){
    const {amenitiesState, setAmenitiesState} = props
    const {featuredAmenities, setFeaturedAmenities} = props
    const {allAmenities, allAmenitiesCategories} = useContext(Context)

    const amenityItems = allAmenitiesCategories.map((item, index) => {
        const filteredAmenities = allAmenities.filter(a => a.category === item.id)
        if(filteredAmenities.length !== 0) {
            return(
                <div key={item.id}>
                    <h3 className='amenityPickerCategory'>{item.name}</h3>
                    {filteredAmenities.map(i => {
                        return(
                            <AmenitiesPickerItem id={i.id} index={index} name={i.name} icon={i.icon} amenitiesState = {amenitiesState} setAmenitiesState={setAmenitiesState} featuredAmenities={featuredAmenities} setFeaturedAmenities={setFeaturedAmenities}/>
                        )
                    })}
                </div>
            )
        }
    })

      

//    const amenities = allAmenities.map(items => {
//        return(
//            <div key={items.id}>
//                <AmenitiesPickerItem id={items.id} name={items.name} icon={items.icon} amenitiesState = {amenitiesState} setAmenitiesState={setAmenitiesState}/>
//            </div>
        
//     )})
       

    return (
        <div>
            {amenityItems}
        </div>
    )

}

export default AmenitiesPickerContainer;