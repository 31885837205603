import React from 'react'
import MainFooter from '../shared/MainFooter';
import MainHeader from '../shared/MainHeader';


function Aboutus(){
    /* document.oncontextmenu = (e) => {
        if (e.button === 2) {
            e.preventDefault();
            return false;
        }
   } */

    return(
        <>
            <MainHeader />
            <div className="aboutus-wrapper">
                <h1 className="aboutus-header">TravGo Madives</h1>
                <div className="aboutus-ontop-text">
                    <h1>Who are we?</h1>
                    <p>
                        TravGo Maldives is registered and licensed travel agency by the Ministry of Economic Development and Tourism Ministry of the Maldives located in Male’. TravGo is established to help all the people around the world to visit and explorer the beauty of Maldives and make their dreams come true with convenience and affordability.
                    </p>
                    <h3>
                        A paradise on earth…The Maldives.
                    </h3>
                    <p>
                        TravGo Maldives offers creative and innovative ideas to allow the maximum enjoyment of the richness of the Maldives, providing an agile, personalized and efficient service, with the backing of professionals of vast experience in the field of tourism. Among the services offered by the compny stands out the coordination of itineraries for individuals or groups, including hotel reservations, transport, tours, local flights, and any other special requirements necessary.
                    </p>
                </div>
            </div>
            <div className="aboutus-goals-wrapper">
                <div className="main-div-goals">
                    <div className="goals-icon">
                        <div className="icon-controller">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                            </svg>
                        </div>
                        <div className="goals-ontop-text">
                            <h2>Our Mission</h2>
                            <p>We tend to focus on the customers' requirement to come up with effective strategies for meeting their demands in an effiecient manner. Our mission is to offer corporate travel solutions and becom the biggest travel agency in the Maldives.</p>
                        </div>
                    </div>
                    <div className="goals-icon">
                    <div className="icon-controller">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                        </svg>
                        </div>
                        <div className="goals-ontop-text">
                            <h2>Our Vision</h2>
                            <p>To be the ultimate travel service provider.</p>
                        </div>
                    </div>
                    <div className="goals-icon">
                        <div className="icon-controller">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </div>
                        <div className="goals-ontop-text">
                            <h2>Our Values</h2>
                            <p>Our core values are customer service, communication, loyalty, integrity, innovation and quality. We comply with strict principles of professional conduct and ethics.</p>
                        </div>
                    </div>
                </div>
            </div>
            <MainFooter/>
        </>
    )
}
export default Aboutus;