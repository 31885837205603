import React from 'react'
import { Route, Switch } from 'react-router'

import PropertiesListContainer from './propertieslist/PropertiesListContainer'
import AdminActions from '../../base/AdminActions';
import PropertiesFormContainer from './propertiesform/PropertiesFormContainer';
import { ToastContainer} from 'react-toastify';


function PropertiesContainer() {

    return(
        <div className='propertiesContainer'>
            <AdminActions />
            <Switch>
                <Route exact path='/travadm/properties' component={PropertiesListContainer} />
                <Route exact path='/travadm/properties/add' component={PropertiesFormContainer} />
                <Route exact path='/travadm/properties/:slug/edit' component={PropertiesFormContainer} />
                <Route exact path='/travadm/properties/trash' component={PropertiesListContainer} />
            </Switch> 
        </div>
    )
}


export default PropertiesContainer;