import React, { useContext, useEffect }  from 'react';

import { Context } from '../context/Context'

function VerifyEmail() {
    const { handleLogout, sendEmailVerification} = useContext(Context)

    useEffect(() => {
        sendEmailVerification()
    }, [])



    return (
        <div className='loginContainer'>
            <div className= 'loginWrapper'>
                <div className='loginImage'>
                    <img className='adminLogo' src={process.env.PUBLIC_URL + '/resources/TravLogoMonoGrad.png'} alt='logo'/>
                </div>
                <div className='verifyCard'>
                    <h1>Verify your email address</h1>
                    <p>Please check your email inbox for the verification email.</p>
                    <button 
                        className="formButton logoutButton" 
                        onClick={handleLogout}>
                    Sign Out
                    </button>
                </div>

            </div>
        </div>
    )
}

export default VerifyEmail;