import React from 'react'
import MainFooter from '../shared/MainFooter';
import MainHeader from '../shared/MainHeader';


function Maldives(){
    document.oncontextmenu = (e) => {
        if (e.button === 2) {
            e.preventDefault();
            return false;
        }
   }

    return(
        <>
            <MainHeader />
            <div className="geography-image">
                <h1 className="maldives-header">THE MALDIVES</h1>
                <div className="ontop-text">
                    <h1>GEOGRAPHY</h1>
                    <p>
                        The Maldives is a nation of islands in the Indian Ocean, that spans across the equator. The country is comprised of 1192 islands that stretch along a length of 871 kilometers. While the country covers an area of approximately 90,000 square kilometers, only 298 square kilometers of that is dry land. The islands are grouped into a double chain of 26 atolls. </p>
                    <p>
                        The country’s unique geography mesmerizes the visitor. Reefs that offer bands of color, tiny jewel-like islands rimmed with the whitest of soft sand surrounded by the clearest shallow waters that one can imagine. Only 200 of the islands are inhabited, and a select few on each of the atolls are resorts and some of the islands are used for industry and agriculture. </p>
                    <p>
                        The beauty of the Maldives is not only above the water. The Maldives is home to about five percent of the planet’s reefs that comes with an explosion of color contributed by soft and hard corals that form them. The reefs are home to a thousand species of fish. Lured by the rich nutrients that flow in with the currents, large pelagic fishes such as manta rays and whale sharks also make the Maldives their home.
                    </p>
                </div>
            </div>
            <div className="culture-image">
                <div className="ontop-text">
                    <h1>CULTURE</h1>
                    <p>
                    Although home to just over half a million people the Maldives has its own unique culture and traditions. While heavily influenced by various cultures around the rim of the Indian Ocean, the Maldivian culture, craft and traditions have been shaped by the island environment and the seas that surround us. </p>
                    <p>
                    Dhivehi is the language of the Maldivian people. Our current script, Thaana is unique and was developed from Arabic numerals around the 16th century. The traditional cuisine is heavily based on fish and coconut, with several dishes that have no parallels anywhere in the region.
                    </p>
                </div>
            </div>
            <div className="environment-image">
                <div className="ontop-text">
                    <h1>ENVIRONMENT</h1>
                    <p>
                    The Maldives has one of the most delicate environments anywhere on the planet. Coral reefs are the foundation of the islands. They offer protection to the tiny islands as its natural defense system, and the country’s economy depends heavily on the health of its reefs and ecosystems. Several conservation efforts are underway to protect the valuable marine environment of Maldives. While several marine species and birds are protected by law, protected areas have been designated to ensure the conservation of specific ecosystems and the rich biodiversity of the country</p>
                    <p>This includes designated nature reserves in islands of various atolls to protect wetlands and mangroves and the protection of marine areas and the designation of biosphere reserves that covers coral reefs, islands, sea grass beds and mangroves. 
                    </p>
                </div>
            </div>
            <MainFooter/>
        </>
    )
}
export default Maldives;