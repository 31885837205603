import React from 'react';
import { Link, useLocation } from 'react-router-dom';



function LocationsMenu() {
    const path = useLocation().pathname;
    return (
        <div className='locationsMenuContainer'>
            <div className='locationsMenu'>
                <Link to='/travadm/locations/countries'><h3 className={path.includes('countries') ? 'locationsMenuActive' : ''}>Countries</h3></Link>
                <Link to='/travadm/locations/atolls'><h3 className={path.includes('atolls') ? 'locationsMenuActive' : ''}>Atolls</h3></Link>
                <Link to='/travadm/locations/islands'><h3 className={path.includes('islands') ? 'locationsMenuActive' : ''}>Islands</h3></Link>
            </div>
        </div >
    )

}

export default LocationsMenu;