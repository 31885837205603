import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import { useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';

import Select from 'react-select';
import Modal from 'react-modal';
import makeAnimated from 'react-select/animated';
import { Context } from '../../../../context/Context';
import BatchImageUpload from '../../../base/batchimageupload/BatchImageUpload';
import RoomTypeList from './roomtypes/roomtypelist/RoomTypeList';
import RoomTypeFormContainer from './roomtypes/roomtypeform/RoomTypeFormContainer';
import GoogleMapsContainer from '../../../base/GoogleMapsContainer';
import AmenitiesPickerContainer from '../../amenities/amenitiesPicker/AmenitiesPickerContainer';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import TransportInput from '../../transport/transportinput/TransportInput';


function PropertiesFormContainer() {
    const [propId, setPropId] = useState('')
    const [featuredImage, setFeaturedImage] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [name, setName] = useState('');
    const [prevName, setPrevName] = useState('');
    const [desc, setDesc] = useState('');
    const [propType, setPropType] = useState('')
    const [rating, setRating] = useState('');
    const [email, setEmail] = useState('');
    const [website, setWebsite] = useState('');
    const [bookingEmail, setBookingEmail] = useState('');
    const [phoneNumbers, setPhoneNumbers] = useState('');
    const [atoll, setAtoll] = useState('');
    const [island, setIsland] = useState('');
    const [address, setAddress] = useState('');
    const [holTypes, setHoltypes] = useState([])
    const [slug, setSlug] = useState('');
    const [lat, setLat] = useState('');
    const [long, setLong] = useState('');
    const [isFeatured, setIsFeatured] = useState(false);
    const [hotDeal, setHotDeal] = useState(false);
    const [status, setStatus] = useState('new');
    const [roomTypes, setRoomTypes] = useState([]);
    const [amenities, setAmenities] = useState([]);
    const [featuredAmenities, setFeaturedAmenities] = useState([]);
    const [transportTypes, setTransportTypes] = useState([]);

    const {images, 
        deleteImageBatchItem, 
        allPropertyTypes, 
        allAtolls, 
        allIslands, 
        allHolidayTypes, 
        createProperty, 
        updateProperty, 
        allProperties, 
        getAllRoomTypes,
    } = useContext(Context)

    const [propTypeOptions, setPropTypeOptions] = useState([]);
    const [atollOptions, setAtollOptions] = useState([]);
    const [islandOptions, setIslandOptions] = useState([]);
    const [holTypeOptions, setHoltypeOptions] = useState([]);
    const [emailError, setEmailError] = useState(false)
    const [bookingEmailError, setBookingEmailError] = useState(false)
    const [ratingError, setRatingError] = useState(false)
    const [islandDisabled, setIslandDisabled] = useState(true)
    const [toggleModal, setToggleModal] = useState(false)
    const featuredImageRef = useRef(null)
    const emailErrorRef = useRef(null)
    const ratingErrorRef = useRef(null)
    const bookingEmailErrorRef = useRef(null)
    const publishRef = useRef(null)
    const saveRef = useRef(null)
    const unpublishRef = useRef(null)

    const host = window.location.host
    const path = useLocation().pathname
    const history = useHistory()
    const slugParam = useParams().slug
    const urlPath  = path.split('/').slice(2,-1).join('/')
    const propertyUrl = `https://${host}/${urlPath}/${slug}`

    useEffect(() => {
        if(path.includes('edit')) {
            setIslandDisabled(false)
            const property = allProperties.find(item => item.slug === slugParam)
            console.log(property)
            setPropId(property.id)
            setName(property.name)
            setPrevName(property.name)
            setImageUrl(property.downloadUrl)
            setDesc(property.description)
            setAddress(property.address)
            setAtoll(property.atoll)
            setIslandOptionsList(property.atoll)
            setIsland(property.island)
            setLat(property.latitude)
            setLong(property.longitude)
            setHoltypes(property.holidayTypes)
            setPropType(property.type)
            setRating(property.rating)
            setEmail(property.email)
            setWebsite(property.website)
            setSlug(property.slug)
            setBookingEmail(property.bookingEmail)
            setPhoneNumbers(property.phoneNumbers)
            setIsFeatured(property.isFeatured)
            setHotDeal(property.hotDeal)
            setAmenities(property.amenities)
            setFeaturedAmenities(property.featuredAmenities)
            setTransportTypes(property.transportTypes)
            if(property.isPublished) {
                setStatus('published')
                unpublishRef.current.hidden = false
                saveRef.current.hidden = true
            } else {
                setStatus('draft')
                saveRef.current.hidden = false
            }
        }
        setOptionsList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slugParam])

    function setOptionsList() {
        const propTypeFiltered = allPropertyTypes.filter(prop => prop.isTrash === false)
        const holTypeFiltered = allHolidayTypes.filter(hol => hol.isTrash === false)
        
        const propTypesList = []
        const atollList = []
        const holtypeList = []

        propTypeFiltered.forEach(prop => propTypesList.push({value: prop.name, label: prop.name}))
        allAtolls.forEach(atoll => atollList.push({value: atoll.name, label: `${atoll.name} (${atoll.abbreviation})`}))
        holTypeFiltered.forEach(hol => holtypeList.push({value: hol.id, label: hol.name}))

        setPropTypeOptions(propTypesList)
        setAtollOptions(atollList)
        setHoltypeOptions(holtypeList)
    } 

    function setIslandOptionsList(atollValue) {
        if(atollValue) {
            var filteredIslands = []
            const islandList = []

            if(atollValue) {
                filteredIslands = allIslands.filter(island => island.atoll === atollValue)
            }

            filteredIslands.forEach(island => islandList.push({value: island.name, label: island.name}))
            setIslandOptions(islandList)
        }
    }


    function handleImageChange(event) {
        var file = event.target.files[0]
        setFeaturedImage(file)
        if (event.target.files && event.target.files[0]) {
            var reader = new FileReader();

            reader.onload = e => {
                featuredImageRef.current.src = e.target.result
            }
            reader.readAsDataURL(event.target.files[0])
        }
    }

    function handleNameChange(event) {
        const {value} = event.target
        var slugValue = value.replace(/\s+/g, '-').toLowerCase()
        setName(value)
        setSlug(slugValue)
    }

    function handleDescChange(value) {
        setDesc(value)
    }

    function handlePropTypeChange(selectedPropType) {
        const {value} = selectedPropType
        setPropType(value)
    }

    function handleRatingChange(event) {
        const {value} = event.target
        setRating(value) 
    }

    function handleEmailChange(event) {
        const {value} = event.target
        setEmail(value)
    }

    function handleWebsiteChange(event) {
        const {value} = event.target
        setWebsite(value)
    }

    function handleBookingEmailChange(event) {
        const {value} = event.target
        setBookingEmail(value)
    }

    function handlePhoneNumbersChange(event) {
        const {value} = event.target
        setPhoneNumbers(value)
    }

    function handleAtollChange(selectedAtoll) {
        const {value} = selectedAtoll
        setAtoll(value)
        if (value) {
            setIslandOptionsList(value)
            setIslandDisabled(false)
        }
    }

    function handleIslandChange(selectedIsland) {
        const {value} = selectedIsland
        console.log(value)
        var islandObj = allIslands.find(item => item.name === value)
        setIsland(value)
        setLat(islandObj.latitude)
        setLong(islandObj.longitude)
    }


    function handleAdressChange(event) {
        const {value} = event.target
        setAddress(value)
    }

    function handleLatChange(event) {
        const {value} = event.target
        setLat(value)
    }

    function handleLongChange(event) {
        const {value} = event.target
        setLong(value)
    }

    function handleHolTypeChange(selectedHols) {
        const holTypeIds = []

        selectedHols.forEach(hols => holTypeIds.push(hols.value))
        setHoltypes(holTypeIds)
    }

    function handleFeaturedChange(event) {
        const checked = event.target.checked
        setIsFeatured(checked)
    }

    function handleHotDealChange(event) {
        const checked = event.target.checked
        setHotDeal(checked)
    }

    function validateEmail(event) {
        const {name, value} = event.target
        // eslint-disable-next-line no-useless-escape
        const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (value) {
            if (name === 'email') {
                if(emailPattern.test(value)) {
                    emailErrorRef.current.hidden = true
                } else {
                    emailErrorRef.current.hidden = false
                    setEmailError(true)
                }
            } else if (name === 'bookingEmail') {
                if(emailPattern.test(value)) {
                    bookingEmailErrorRef.current.hidden = true
                } else {
                    bookingEmailErrorRef.current.hidden = false
                    setBookingEmailError(true)
                }
            }
        } else {
            emailErrorRef.current.hidden = true
            bookingEmailErrorRef.current.hidden = true
        }
    }

    function validateRating(event) {
        const {value} = event.target
        if(value) {
            if (value > 7 || value < 1) {
                ratingErrorRef.current.classList.add('errorInstruction')
                setRatingError(true)
            } else {
                ratingErrorRef.current.classList.remove('errorInstruction')
                setRatingError(false)
            }
        } else {
            ratingErrorRef.current.classList.remove('errorInstruction')
        }

    }

    function statusTag() {
        if (status === 'published') {
            return(
                <div className='propertyStatusTag publishedTag'>
                    <p>Published</p>
                </div>
            )
        } else if (status === 'draft'){
            return(
                <div className='propertyStatusTag draftTag'>
                    <p>Draft</p>
                </div>
            )
        } else if(status === 'new') {
            return(
                <div className='propertyStatusTag newTag'>
                    <p>New</p>
                </div>
            )
        }

    }


    function setPublishButton() {
        if (path.includes('edit') && status === 'published') {
            return (
                <button className='formButton publishButton' onClick={handlePublishClick} ref={publishRef}>Update</button>
            )
        } else if (status === 'draft' || status === 'new') {
            return (
                <button className='formButton publishButton' onClick={handlePublishClick} ref={publishRef}>Publish</button>
            )
        }
    }


    function handlePublishClick(event) {
        event.preventDefault() 
        console.log(emailError)
        console.log(ratingError)
        const propObj = {
            propId: propId,
            featuredImage: featuredImage,
            propertyName: name,
            propertyType: propType,
            rating: rating,
            isPublished: true,
            description: desc,
            email: email,
            website: website,
            bookingEmail: bookingEmail,
            phoneNumbers: phoneNumbers,
            atoll: atoll,
            island: island,
            address: address,
            latitude: lat,
            longitude: long,
            holidayTypes: holTypes,
            slug: slug,
            isTrash: false,
            isFeatured: isFeatured,
            hotDeal: hotDeal,
            amenities: amenities,
            featuredAmenities: featuredAmenities,
            transportTypes: transportTypes,

        }

        if (propId) {
            if(name) {
                if (!emailError && !ratingError) {
                    if (window.confirm(`Are you sure you want to ${status === 'draft' ? 'publish' : 'update'} this property?`)) {
                        updateProperty(propObj)
                        history.push('/travadm/properties', null)
                    }
                    
                } else {
                    window.alert(`Please correct the following fields:\n ${ratingError ? '- Star Rating\n' : ''} ${emailError ? '- Email\n' : ''} ${bookingEmailError ? '- Booking Email\n' : ''}`)
                }
            } else {
                window.alert('Please enter a property name to continue publishing this property.')
            }
        } else {
            if(!allProperties.find(item=>item.slug === slug)) {
                if (name) { 
                    if (!emailError && !ratingError) {
                        if (window.confirm('Are you sure you want to publish this property?')) {
                            createProperty(propObj)
                            history.push('/travadm/properties', null)
                        }
                    } else {
                        window.alert(`Please correct the following fields:\n ${ratingError ? '- Star Rating\n' : ''} ${emailError ? '- Email\n' : ''} ${bookingEmailError ? '- Booking Email\n' : ''}`)
                    }
                } else {
                    window.alert('Please enter a property name to continue publishing this property.')
                }
            } else {
                window.alert('A property with this name already exists. Please add a property with a unique name.')
            }
        }
    }

    function handleUnpublishClick(event) {
        event.preventDefault()
        if(window.confirm('Are you sure you want to unpublish this property?')) {
            updateProperty({propId: propId, isPublished: false, isFeatured: false})
            history.push('/travadm/properties', null)

        }
    }

    function handleSaveClick(event) {
        event.preventDefault()
        const propObj = {
            propId: propId,
            featuredImage: featuredImage,
            propertyName: name,
            propertyType: propType,
            rating: rating,
            isPublished: '',
            description: desc,
            email: email,
            website: website,
            bookingEmail: bookingEmail,
            phoneNumbers: phoneNumbers,
            atoll: atoll,
            island: island,
            address: address,
            latitude: lat,
            longitude: long,
            holidayTypes: holTypes,
            slug: slug,
            isTrash: false,
            isFeatured: isFeatured,
            hotDeal: hotDeal,
            fileType: featuredImage ? featuredImage.type.split('/').pop() : '',
            action: 'draft',
            history: history,
            amenities: amenities,
            featuredAmenities: featuredAmenities,
            transportTypes: transportTypes,
        }

        if (propId) {
            if(event.target.name === 'rtButton') {
                setToggleModal(true)
            }
            
            if (!allProperties.find(item=>item.slug === slug)) {
                if(name) {
                    updateProperty(propObj)
                    setRoomTypes([])
                    getAllRoomTypes(propId)
                } else {
                    window.alert('Please enter a property name to continue saving this property.')
                }
            } else {
                if(name === prevName) {
                    updateProperty(propObj)
                    setRoomTypes([])
                    getAllRoomTypes(propId)
                } else {
                    window.alert('A property with this name already exists. Please change the property name to continue.')
                }
            }
        } else {
            if(!allProperties.find(item=>item.slug === slug)) {
                if(name) {
                    createProperty(propObj)
                    setRoomTypes([])
                    getAllRoomTypes(propId)
                } else {
                    window.alert('Please enter a property name to continue saving this property.')
                }
            } else {

                window.alert('A property with this name already exists. Please change the property name to continue.')
            }
        }
    }

    function handleCancelClick(event) {
        event.preventDefault()
        if(name) {
            if(window.confirm('Are you sure you want to cancel. All your changes will be lost')) {
                history.push('/travadm/properties', null)
                if (images.length > 0) {
                    const imageList = images.filter(image => image.status === 'new')
                    imageList.forEach(item => deleteImageBatchItem(item.id, item.fileType, 'properties'))
                }
            } 
        } else {
            history.push('/travadm/properties', null)
        }
    }

    function handleToggleModal(event) {
        event.preventDefault()
        if(propId){
            setToggleModal(true)
        } else if(window.confirm('Property must be saved before adding room types, would you like to save this property as draft?')) {
            handleSaveClick(event)
        }
    }

    function handleBatchUploadClick(event) {
        if(!propId) {
            if(window.confirm('Property must be saved before adding additional images, would you like to save this property as draft?')) {
                handleSaveClick(event)
            }
        }
    }

    return(
        <>
            <Modal 
            isOpen={toggleModal}
            contentLabel="Add room type"
            ariaHideApp={false}
            >
                <RoomTypeFormContainer 
                setToggleModal={setToggleModal} 
                parentSlug={slug} 
                parentId={propId}
                action={'add'}
                />
            </Modal>
            <form>
                <div className='propertiesFormRowGroup'>
                    <div className='propertiesFormColumnPimary'>
                    <div className='propertiesFormSection'>
                        <div className='atollsInputWrapperFull'>
                        <div className='featuredRow'>
                            <h1>Property Header</h1>
                            <div className = 'toggleElement'>
                                <label>Featured:</label>
                                <label className="switch">
                                    <input type="checkbox" onChange={handleFeaturedChange} checked={isFeatured}/>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <div className = 'toggleElement'>
                                <label>Hot Deal:</label>
                                <label className="switch">
                                    <input type="checkbox" onChange={handleHotDealChange} checked={hotDeal}/>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>

                            <label>
                                Featured Image:
                            </label>
                            <div className='formInput holTypeImageContainer'>
                                <img
                                className="holTypeImage"
                                src={imageUrl ? imageUrl : process.env.PUBLIC_URL + '/resources/Placeholder.png'}
                                alt=""
                                ref={featuredImageRef}
                                ></img>
                            </div>

                            <div className="holTypeImageWrapper">
                                    <p className="subInstruction">Upload image:</p>
                                    <div className="uploadRow">
                                        <label htmlFor="file" className="substituteLabel">
                                        {`${featuredImage || path.includes('edit') ? 'Change' : 'Upload'} Image`}
                                        </label>
                                        <p className="fileLabel">{featuredImage ? featuredImage.name : ''}</p>
                                    </div>
                                    <input
                                        type="file"
                                        id="file"
                                        accept="image/*"
                                        className="substituteImage"
                                        onChange={handleImageChange}
                                    ></input>
                                </div>
                            </div>
                            <div className='atollsFieldGroupFull'>
                                <div className='atollsInputWrapperFull'>
                                    <label>
                                        Property Name:
                                    </label>
                                    <input 
                                        className='formInput propertiesTitleInput' 
                                        placeholder='Enter property name (ex: Bandos Maldives)'
                                        type='text'
                                        onChange={handleNameChange}
                                        value={name}
                                    />
                                <label>{propertyUrl}</label>
                            </div>
                        </div>
                    </div>
                        <div className='propertiesFormSection'>
                            <div className='atollsInputWrapperFull'>
                            <h1>Description</h1>
                                <label>
                                    Property Description:
                                </label>
                                <ReactQuill value={desc}
                                onChange={handleDescChange} style={{minHeight: '150px'}}/>
                            </div>
                        </div>

                        <div className='propertiesFormSection'>
                            <h1>Location</h1>
                            <div className='propFieldGroupBi'>
                                <div className='propInputWrapperBi'>
                                    <label>
                                        Atoll/City:
                                    </label>
                                    <div className='atollCountrySelectWrapper selectCustomBorder'>
                                        <Select options={atollOptions} onChange={handleAtollChange} value={atollOptions.find(option => option.value === atoll)}/>
                                    </div>
                                </div>

                                <div className='propInputWrapperBi'>
                                    <label>
                                        Island/Ward:
                                    </label>
                                    <div className='atollCountrySelectWrapper selectCustomBorder'>
                                        <Select options={islandOptions} isDisabled={islandDisabled} onChange={handleIslandChange} value={islandOptions.find(option => option.value === island)}/>
                                    </div>
                                </div>
                            </div>
                            <div className='atollsInputWrapperFull'>
                                <label>
                                    Address:
                                </label>
                                <textarea 
                                    className='formInput propAddressArea' 
                                    placeholder='Type the property address...'
                                    type='text'
                                    onChange={handleAdressChange}
                                    value={address}
                                />
                            </div>
                            <div className='atollsFieldGroupFull'>
                                <div className='islandInputWrapperBi'>
                                    <label>
                                        Latitude:
                                    </label>
                                    <input 
                                        className='formInput cordNumber' 
                                        placeholder='Enter latitude (ex: 7.103806)'
                                        type='number'
                                        onChange={handleLatChange}
                                        value={lat}
                                    />
                                </div>

                                <div className='islandInputWrapperBi'>
                                    <label>
                                        Logitude:
                                    </label>
                                    <input 
                                        className='formInput cordNumber' 
                                        placeholder='Enter longitude (ex: 72.898979)'
                                        type='number'
                                        onChange={handleLongChange}
                                        value={long}
                                    />                   
                                </div>
                            </div>
                            {path.includes('edit') ?
                            <div className='atollsFielGroupFull'>
                                <div className='atollsInputWrapperFull'>
                                    <GoogleMapsContainer long={long} lat={lat}/>
                                </div>
                            </div>
                            :
                            ''
                            }
                        </div>

                        <div className='propertiesFormSection'>
                            <div className='atollsInputWrapperFull'>
                            <h1>Holiday/Transport Types</h1>
                                <label>
                                    Holiday Types:
                                </label>
                                <div className='atollCountrySelectWrapper selectCustomBorder'>
                                    <Select options={holTypeOptions}  closeMenuOnSelect={false} isMulti onChange={handleHolTypeChange} components={makeAnimated()} value={holTypeOptions.filter(item => holTypes.includes(item.value))}/>
                                </div>
                            </div>
                            <label>
                                    Transport Types:
                            </label>
                            <div className='atollsInputWrapperFull'>
                                <TransportInput propId={propId} setTransportTypes={setTransportTypes} transportTypes={transportTypes}/>
                            </div>
                        </div>
                    </div>
                    
                    <div className='propertiesFormColumnSecondary'>
                        <div className='propertiesFormSection'>
                            <h1>Publish</h1>
                                <div className='atollsInputWrapperFull'>
                                <label>
                                    Property Type:
                                </label>
                                <div className='atollCountrySelectWrapper selectCustomBorder'>
                                    <Select options={propTypeOptions} onChange={handlePropTypeChange} value={propTypeOptions.find(option => option.value === propType)}/>
                                </div>

                                <label>
                                    Star Rating:
                                </label>
                                <input 
                                    className='formInput propNumber' 
                                    placeholder='Enter star rating'
                                    type='number'
                                    max='7'
                                    min='1'
                                    onBlur={validateRating}
                                    onChange={handleRatingChange}
                                    value={rating}
                                />
                                <label className='formInstruction' ref={ratingErrorRef}>Star Rating must be a number between 1 and 7.</label>

                                <label>
                                    Status:
                                </label>
                                {statusTag()}
                                <div className='propFormButtonRow'>
                                    {setPublishButton()}
                                    <button className='formButton saveDraftButton' onClick={handleSaveClick} ref={saveRef}>Save Draft</button>
                                    <button className='formButton unpublishButton' ref={unpublishRef} onClick={handleUnpublishClick} hidden>Unpublish</button>
                                    <button className='formButton cancelButton' onClick={handleCancelClick}>Cancel</button>
                                </div>
                            </div>
                        </div>
                        <div className='propertiesFormSection'>
                            <h1>Contact Info</h1>
                            <div className='atollsInputWrapperFull'>
                                <label>
                                    Email
                                </label>
                                <input 
                                    className='formInput' 
                                    placeholder='Enter email'
                                    type='email'
                                    onBlur={validateEmail}
                                    onChange={handleEmailChange}
                                    value={email}
                                    name='email'
                                />
                                <label className='errorInstruction' ref={emailErrorRef} hidden>Email must be in the format "property@example.com". </label>

                                <label>
                                    Website:
                                </label>
                                <input 
                                    className='formInput' 
                                    placeholder='Enter website'
                                    type='text'
                                    onChange={handleWebsiteChange}
                                    value={website}
                                />

                                <label>
                                    Booking Email:
                                </label>
                                <input 
                                    className='formInput' 
                                    placeholder='Enter booking email'
                                    type='email'
                                    onBlur={validateEmail}
                                    onChange={handleBookingEmailChange}
                                    value={bookingEmail}
                                    name='bookingEmail'
                                />                               
                                 <label className='errorInstruction' ref={bookingEmailErrorRef} hidden>Booking Email must be in the format "property@example.com". </label>


                                <label>
                                    Phone Numbers:
                                </label>
                                <input 
                                    className='formInput cordNumber' 
                                    placeholder='Enter phone number'
                                    type='text'
                                    onChange={handlePhoneNumbersChange}
                                    value={phoneNumbers}
                                />
                                <label className='formInstruction'>Please seperate multiple phone numbers with commas.</label>
                            </div>
                        </div>
                        <div className='propertiesFormSection'>
                            <h1>Amenities</h1>
                            <div>
                                <div className="AmenitiesIconCheckbox">
                                    <AmenitiesPickerContainer setAmenitiesState={setAmenities} amenitiesState={amenities} featuredAmenities={featuredAmenities} setFeaturedAmenities={setFeaturedAmenities}/>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='propertiesBatchImageRowGroup'>
                            <div className='propertiesFormSection'>
                            <h1>Aditional Images</h1>
                                <BatchImageUpload parentId={propId} cat='properties' handleBatchUploadClick={handleBatchUploadClick}/>
                            </div>
                        </div>
                    <div className='roomTypeSection'>
                            <h1>Room Types</h1>
                                <button className='formButton addRoomButton' onClick={handleToggleModal} name='rtButton'>Add Room Type</button>
                            <div className='roomTypeListContainer'>
                                <RoomTypeList 
                                roomTypes={roomTypes} 
                                propId={propId} 
                                />
                            </div>
                        </div>
                </div>
            </form>

        </>
    )
}

export default PropertiesFormContainer;