import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Context } from '../../context/Context';
import MobileSubmenu from './MobileSubmenu';
import SubMenu from './SubMenu';

import WhatsAppWidget from 'react-whatsapp-widget'
import 'react-whatsapp-widget/dist/index.css'

function MainHeader() {
    const [navbar, setNavbar] = useState(false)
    const [hambToggle, setHambToggle] = useState(false)
    const path = useLocation().pathname
    const hamburgerRef = useRef()
    const { allPropertyTypes } = useContext(Context)
    const [hoverState, setHoverState] = useState(false)
    const [maldivesHover, setMaldivesHover] = useState(false)
    const [mobStayToggle, setMobStayToggle] = useState(false)
    const [mobMaldivesToggle, setMobMaldivesToggle] = useState(false)


    const changeBackground = () => {
        if (!path.includes('properties')) {
            if (window.scrollY >= 300) {
                setNavbar(true)
            } else {
                setNavbar(false)
            }
        }
    }

    const caret =
        <span className='caret'>
            <i className="ri-arrow-drop-down-line"></i>
        </span>

    useEffect(() => {
        if (hambToggle) {
            hamburgerRef.current.classList.add('open')
        } else {
            hamburgerRef.current.classList.remove('open')
        }
    }, [hambToggle])

    function handleHamburgerClick() {
        setHambToggle(!hambToggle)
        setMobStayToggle(false)
    }

    function getPropTypeItems() {
        const propTypeItems = []

        allPropertyTypes.forEach(item => propTypeItems.push({
            name: item.name,
            link: `/stay/${item.slug}`,
            order:
                item.name === 'Resort' ? 1
                    :
                    item.name === 'Hotel' ? 2
                        :
                        item.name === 'Guest House' ? 3
                            :
                            item.name === 'Live Aboards' ? 4
                                : ''
        }))
        return (propTypeItems)
    }

    const stayItems =
        getPropTypeItems().map((item, index) => {
            return (
                <Link className='navItem' key={index} to={item.link} onClick={handleHamburgerClick}>{item.name}</Link>
            )
        })

    function getMaldivesLinks() {
        const maldivesLinks = []

        maldivesLinks.push(
            {
                name: 'Maldives',
                link: '/maldives',
                order: 1
            },
            {
                name: 'Travellers Essentials',
                link: '/travellersessentials',
                order: 2
            }
        )
        return (maldivesLinks)
    }

    const hamburger =
        <div id="nav-icon2" ref={hamburgerRef} onClick={handleHamburgerClick}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>

    window.addEventListener('scroll', changeBackground)

    return (

        <div className={navbar ? 'header headerActive active' : 'header'}>
            {
                !hambToggle &&
                <WhatsAppWidget phoneNumber='9607971789' message={'Hello!\nHow may we assist you?'} />
            }
            <div className='headerLogo'>
                <Link to='/'><img src={`${process.env.PUBLIC_URL}/resources/TravLogo.png`} alt='' /></Link>
            </div>

            <div className='mobileMenu' style={hambToggle ? { width: '100%' } : { width: '0' }} hidden={!hambToggle}>
                <div className='mobileAction' onClick={handleHamburgerClick}></div>
                <div className='mobileNavigation'>
                    <div className='mobileNavigationItems'>
                        <div className='mobileNavigationItemWrapper'>
                            <Link to='/' className='navHeader' onClick={handleHamburgerClick}>Home</Link>
                            <p className='navHeader'>Stay</p>
                            {stayItems}
                            <p className='navHeader'>Maldives</p>
                            <Link className='navItem' to='/maldives' onClick={handleHamburgerClick}>Maldives</Link>
                            <Link className='navItem' to='/travellersessentials' onClick={handleHamburgerClick}>Travellers Essentials</Link>
                            <Link to='/aboutus' className='navHeader' onClick={handleHamburgerClick}>About Us</Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className='headerNav'>
                <ul>
                    <div className="vl" />
                    <li><Link to='/'>Home</Link></li>
                    <div className="vl" />
                    <li
                        onMouseEnter={() => { setHoverState(true) }}
                        onMouseLeave={() => { setHoverState(false) }}>
                        Stay {caret}
                        <SubMenu
                            items={getPropTypeItems().sort((a, b) => (a.order > b.order) ? 1 : -1)}
                            hoverState={hoverState}
                            setHoverState={setHoverState}
                            active={navbar}
                        />
                    </li>
                    <div className="vl" />
                    <li
                        onMouseEnter={() => { setMaldivesHover(true) }}
                        onMouseLeave={() => { setMaldivesHover(false) }}>
                        Maldives {caret}
                        <SubMenu
                            items={getMaldivesLinks().sort((a, b) => (a.order > b.order) ? 1 : -1)}
                            hoverState={maldivesHover}
                            setHoverState={setMaldivesHover}
                            active={navbar}
                        /></li>
                    <div className="vl" />
                    <li><Link to='/aboutus'>About Us</Link></li>
                    {/* <li>Hot Deals</li> */}
                    {/* <li>Quick Inquiry</li> */}
                    <div className="vl" />
                </ul>
            </div>
            <div className='hamburgerNav'>
                {hamburger}
            </div>
        </div>
    )
}

export default MainHeader;
