import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'


function AmenitiesPickerItem(props){
    const {
        name,
        id,
        index,
        icon,
        amenitiesState,
        setAmenitiesState,
        featuredAmenities,
        setFeaturedAmenities
    } = props
    const [featuredCheck, setFeaturedCheck] = useState(false)
    const [checkedState, setCheckedState] = useState(false)
    
    useEffect(()=>{
        if(amenitiesState !== undefined){
            if (amenitiesState.includes(id)){
                setCheckedState(true)
            }
        }
        if(featuredAmenities !== undefined){
            if(featuredAmenities.includes(id)){
                setFeaturedCheck(true)
            }
        }
        
    }, [amenitiesState, featuredAmenities, id])



    function handleCheckboxClick(event){

        // event.preventdefault()
        setCheckedState(!checkedState)
        if(event.target.checked) {
            setAmenitiesState([...amenitiesState, id])
        } else {
            const removeItem = amenitiesState.filter(item => item !== id)
            setAmenitiesState(removeItem)
        }
    }

    function addtofeatured(event){

        // event.preventdefault()
        setFeaturedCheck(!featuredCheck)
        if(event.target.checked) {
            if (featuredAmenities === undefined){
                setFeaturedAmenities([id])
            }else{
                setFeaturedAmenities([...featuredAmenities, id])
            }
        } else {
            const removeItem = featuredAmenities.filter(item => item !== id)
            setFeaturedAmenities(removeItem)
        }
    }

    var i = 0;

    return(
        <ul>
        <div className="amenitiesPickerInput">
            <span>
                <input type='checkbox' key={i++} id={index} onChange={addtofeatured} checked={featuredCheck}/>
                <input type='checkbox' key={id} id={id} onChange={handleCheckboxClick} checked={checkedState}/>
                <FontAwesomeIcon 
                icon={icon} 
                size="2x"
                /><label>{name}</label>
            </span>
        </div>
        </ul>
        
        
    )
}
export default AmenitiesPickerItem;