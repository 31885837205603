/* eslint-disable eqeqeq */
import React, { useContext } from 'react';
import { Context } from '../../context/Context';


function UserItem(props) {

    const {uid, name, email, level, disabled, currentUserLevel} = props

    const {handleUserDelete, handleUserDisable, handleUserEnable} = useContext(Context)



    const verifiedIcon = false ? 
        <span className='verifiedIcon'><i className="ri-checkbox-circle-fill"></i></span>
        :
        ''

    function setUserLevelTag() {

        if(!disabled) {
            if(level === "1") {
                return(
                    <div className='userLevelTag levelOne'>
                        <p>Support</p>
                    </div>
                )    
            } else if(level === "2") {
                return(
                    <div className='userLevelTag levelTwo'>
                        <p>Admin</p>
                    </div>
                ) 
            } else if(level === "3") {
                return(
                    <div className='userLevelTag levelThree'>
                        <p>Maintainer</p>
                    </div>
                ) 
            } 
        }

        
        if(disabled){
            return(
                <div className='userLevelTag disabledTag'>
                    <p>Disabled</p>
                </div>
            ) 
        }
    }

    function handleDeleteClick() {

        if(window.confirm('Are you sure you want to delete this user?')){
            handleUserDelete(uid)
        }
        
    }

    function handleDisableClick() {

        if(window.confirm('Are you sure you want to disable this user?')){
            handleUserDisable(uid)
        }
        
    }

    function handleEnableClick() {
        if(window.confirm('Are you sure you want to enable this user?')){
            handleUserEnable(uid)
        }
    }
    console.log(level, currentUserLevel)

    function userControlButtons() {

        const buttonRowEnabled =                         
        <div className='userItemButtonRow'>
        <button className={`formButton ${!disabled ? 'disableButton' : 'enableButton'}`} onClick={!disabled ? handleDisableClick : handleEnableClick}>{!disabled ? 'Disable' : 'Enable'}</button>
        <button className='formButton deleteButton' onClick={handleDeleteClick}>Delete</button> 
        </div>

        const buttonRowDisabled =                         
        <div className='userItemButtonRow'>
        <button className='formButton' disabled>{!disabled ? 'Disable' : 'Enable'}</button>
        <button className='formButton' disabled>Delete</button> 
        </div>

        if (currentUserLevel == 1) {
            return(buttonRowEnabled)
        } else if (currentUserLevel == 2) {
            if (level >= 2) {
                return buttonRowEnabled
            } else {
                return buttonRowDisabled
            }
        }
    }

    return (
        <div className={!disabled ? 'userItem' : 'disabledUserItem'}>
            <div className='userNameGroup'>
                <p className='userLabel'>Name</p>
                <p className='userName'>{name}</p>
            </div>
            <div className='userEmailGroup'>
                <p className='userLabel'>Email</p>
                 <p className='userEmail'>{email}{verifiedIcon}</p> 
            </div>
            {setUserLevelTag()}
            {userControlButtons()}
        </div>
    )
}

export default UserItem;