import React from 'react'
import { useContext } from 'react';
import { useRef } from 'react';
import { useState, useEffect } from 'react'
import { Context } from '../../../../../../context/Context';
import AmenitiesPickerContainer from '../../../../amenities/amenitiesPicker/AmenitiesPickerContainer';



function RoomTypeFormContainer(props) {
    const {setToggleModal, parentId, roomType, action} = props
    
    const [featuredImage, setFeaturedImage] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [id, setId] = useState('')
    const [name, setName] = useState('')
    const [code, setCode] = useState('')
    const [desc, setDesc] = useState('')
    const [uuid, setUuid] = useState('')
    const [adultCapacity, setAdultCapacity] = useState('')
    const [childCapacity, setChildCapacity] = useState('')
    const [size, setSize] = useState('')
    const [price, setPrice] = useState('')
    const {createRoomTypes, updateRoomType, getAllRoomTypes} = useContext(Context)
    const roomTypeImageRef = useRef(null)
    const nameRef = useRef(null)
    const adultRef = useRef(null)
    const [amenities, setAmenities] = useState([]);
    const [featuredAmenities, setFeaturedAmenities] = useState([]);


    useEffect(() => {
        setUuid(createUUID())
        console.log(roomType)

        if(roomType) {
            setId(roomType.id)
            setImageUrl(roomType.downloadUrl)
            setName(roomType.name)
            setCode(roomType.code)
            setDesc(roomType.desc)
            setAdultCapacity(roomType.adultCapacity)
            setChildCapacity(roomType.childCapacity)
            setSize(roomType.size)
            setPrice(roomType.price)
            setAmenities(roomType.amenities)
            setFeaturedAmenities(roomType.featuredAmenities)
        }
    }, [])
    console.log(price)
    function handleImageChange(event) {
        var file = event.target.files[0]
        setFeaturedImage(file)
        if (event.target.files && event.target.files[0]) {
            var reader = new FileReader();

            reader.onload = e => {
                roomTypeImageRef.current.src = e.target.result
            }
            reader.readAsDataURL(event.target.files[0])
        }
    }

    function handleNameChange(event) {
        const {value} = event.target
        var codeValue = `${value.replace(/\s+/g, '-').toLowerCase()}-${uuid}`
        setName(value)
        setCode(codeValue)
    } 

    function handleDescChange(event) {
        const {value} = event.target
        setDesc(value)
    }

    function handleAdultChange(event) {
        const {value} = event.target
        setAdultCapacity(value)
    }

    function handleChildChange(event) {
        const {value} = event.target
        setChildCapacity(value)
    }

    function handleSizeChange(event) {
        const {value} = event.target
        setSize(value)
    }

    function handlePriceChange(event) {
        const {value} = event.target
        setPrice(value)
    }

    function handleCancelClick(e) {
        e.preventDefault()
        setToggleModal(false)
    }

    function handleSaveClick(event) {
        event.preventDefault()
        
        const roomTypeObj = {
            image: featuredImage,
            downloadUrl: imageUrl,
            name: name,
            code: code,
            desc: desc,
            adultCapacity: adultCapacity,
            childCapacity: childCapacity,
            size: size,
            price: price,
            amenities:amenities,
            featuredAmenities:featuredAmenities
        }

        if(roomType) {
            if(name && adultCapacity) {
                nameRef.current.classList.remove('invalidInput')
                adultRef.current.classList.remove('invalidInput')
                updateRoomType(id, '',featuredImage ? featuredImage.type.split('/').pop() : '', parentId, roomTypeObj)
                getAllRoomTypes(parentId)
                setToggleModal(false)
            }
        } else {
            if(name && adultCapacity) {
                nameRef.current.classList.remove('invalidInput')
                adultRef.current.classList.remove('invalidInput')
                createRoomTypes(parentId, roomTypeObj)
                setToggleModal(false)
    
            } 
        }
        
        if (!name && !adultCapacity){ 
            window.alert('Please correct all fields highlighted in red.')
            if(!name){
                nameRef.current.classList.add('invalidInput')
            }

            if(!adultCapacity) {
                adultRef.current.classList.add('invalidInput')
            }
        }
    }

    const asterisk = <span style={{color: 'red'}}> * </span>

    function createUUID(){
        var dt = new Date().getTime();
        var uuid = 'yxxyxx'.replace(/[xy]/g, function(c) {
            var r = (dt + Math.random()*16)%16 | 0;
            dt = Math.floor(dt/16);
            // eslint-disable-next-line no-mixed-operators
            return (c==='x' ? r :(r&0x3|0x8)).toString(16);
        });
        return uuid;
    }

    return (
        <div className='roomTypeFormContainer'>
            <div className='closeBar'>
                <i className="ri-close-circle-line" onClick={() => {setToggleModal(false)}}></i>
            </div>
            <form>
            <div className='propertiesFormRowGroup'>
                <div className='roomtypeFormSection'>
                    <h1>Add Room Type</h1>
                    <p>All fields marked with [{asterisk}] are mandatory</p>
                    <label>
                        Featured Image:
                    </label>
                    <div className='formInput holTypeImageContainer'>
                        <img
                        className="holTypeImage"
                        src={imageUrl? imageUrl : process.env.PUBLIC_URL + '/resources/Placeholder.png'}
                        alt=""
                        ref={roomTypeImageRef}
                        ></img>
                    </div>

                    <div className="holTypeImageWrapper">
                            <p className="subInstruction">Upload image:</p>
                            <div className="uploadRow">
                                <label htmlFor="rtfile" className="substituteLabel">
                                    Upload Image
                                </label>
                                <p className="fileLabel">{featuredImage ? featuredImage.name : ''}</p>
                            </div>
                            <input
                                type="file"
                                id="rtfile"
                                accept="image/*"
                                className="substituteImage"
                                onChange={handleImageChange}
                            ></input>
                    </div>
                    <div className='propFieldGroupBi'>
                        <div className='propInputWrapperBi'>
                            <label>
                                Name{asterisk}:
                            </label>
                            <input 
                                className='formInput roomTypeInput' 
                                value={name}
                                placeholder='Enter room type'
                                type='text'
                                onChange={handleNameChange}
                                ref={nameRef}
                            />

                        </div>

                        <div className='propInputWrapperBi'>
                            <label>
                                Room Type Code:
                            </label>
                            <input 
                                className='formInput roomTypeInput' 
                                value={code}
                                placeholder='Enter room type code'
                                type='text'
                                readOnly
                            />

                        </div>
                    </div>
                    <div className='atollsInputWrapperFull'>
                                <label>
                                    Room Type Description:
                                </label>
                                <textarea 
                                    className='formInput propTextArea' 
                                    value={desc}
                                    placeholder='Type the description for the room type...'
                                    type='text'
                                    onChange={handleDescChange}
                                />
                            </div>

                        <div className='atollsFieldGroupFull'>
                        <div className='atollsInputWrapperTri'>
                            <label>
                                Adult Capacity{asterisk}:
                            </label>
                            <input 
                                className='formInput cordNumber' 
                                value={adultCapacity}
                                placeholder='Enter adult capacity'
                                type='text'
                                onChange={handleAdultChange}
                                ref={adultRef}
                            />
                        </div>
                        <div className='atollsInputWrapperTri'>
                            <label>
                                Child Capacity:
                            </label>
                            <input 
                                className='formInput cordNumber' 
                                value={childCapacity}
                                placeholder='Enter child capacity'
                                type='text'
                                onChange={handleChildChange}
                            />
                        </div>
                        <div className='atollsInputWrapperTri'>
                            <label>
                                Room Size:
                            </label>
                            <input 
                                className='formInput' 
                                value={size}
                                placeholder='Enter room size (in sqft)'
                                type='text'
                                onChange={handleSizeChange}
                            />
                        </div>
                        <div className='atollsInputWrapperTri'>
                            <label>
                                Room Price:
                            </label>
                            <input 
                                className='formInput' 
                                value={price}
                                placeholder='Enter room price (ex: 2000)'
                                type='text'
                                onChange={handlePriceChange}
                            />
                        </div>
                    </div>

                </div>

                <div className='propertiesFormSection'>
                    <h1>Amenities</h1>
                    <div className="AmenitiesIconCheckbox">
                        <AmenitiesPickerContainer setAmenitiesState={setAmenities} amenitiesState={amenities} featuredAmenities={featuredAmenities} setFeaturedAmenities={setFeaturedAmenities}/>
                    </div>
                </div>

                <div className='roomTypeFormButtonRow'>
                    <button className='formButton saveButton' onClick={handleSaveClick}>{roomType ? 'Update' : 'Add'}</button>
                    <button className='formButton cancelButton' onClick={handleCancelClick}>Cancel</button>
                </div>
            </div>
            </form>
        </div>

    )
}

export default RoomTypeFormContainer