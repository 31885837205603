import React, { useEffect, useContext, useState} from 'react'
import ReactTooltip from 'react-tooltip';
import { Context } from '../../../../../../context/Context';
import RoomTypeListItem from './RoomTypeListItem';


function RoomTypeList(props) {
    const {removeRoomType, propId, toggleModal, setToggleModal} = props
    const {allRoomTypes, getAllRoomTypes, setAllRoomTypes} = useContext(Context)
    const [roomTypesList, setRoomTypesList] = useState([])

    useEffect(() => {
        if(propId) {
            getAllRoomTypes(propId)
        } else {
            setAllRoomTypes([])
        }
    }, [propId])

    useEffect(() => {
        setRoomTypesList(allRoomTypes)
    }, [allRoomTypes])

    console.log(propId,allRoomTypes)

    const loadedRoomTypeItems =roomTypesList.map(item => {

        return (
            <RoomTypeListItem 
            key={item.code} 
            roomType={item} 
            removeRoomType={removeRoomType} 
            propId={propId}
            />
        )
    })


    return (
        <div className='roomTypeList'>
            <table>
                <thead>
                    <tr>
                        <th colSpan='2'>Name</th>
                        <th>Adult Capacity</th>
                        <th>Child Capacity</th>
                        <th>Room Size</th>
                    </tr>
                </thead>
                <tbody>
                    {loadedRoomTypeItems}
                </tbody>
            </table>

        </div>
    )
}

export default RoomTypeList;