import React, { useContext, useEffect, useState } from 'react'
import MainHeader from '../shared/MainHeader'
import MainFooter from '../shared/MainFooter'
import { Context } from '../../context/Context'
import { useParams } from 'react-router'
import FbImageLibrary from 'react-fb-image-grid'
import '../propertiespage/style.css'
import PackageRoomItem from './PackageRoomItem'
import Modal from 'react-modal';
import PackageBookingForm from './PackageBookingForm'

function PackagesPage() {

    const {
        allPackages,
        allProperties,
        getAllRoomTypes,
        allRoomTypes,
        setAllRoomTypes,
        getAllBatchImages,
        images,
        roomTypeImages,
        setRoomTypeImages
    } = useContext(Context)

    const slugParam = useParams().slug
    const [name, setName] = useState('')
    const [price, setPrice] = useState('')
    const [desc, setDesc] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [propId, setPropId] = useState('')
    const [propName, setPropName] = useState('')
    const [propImage, setPropImage] = useState('')
    const [propType, setPropType] = useState('')
    const [imageList, setImageList] = useState([])
    const [passThresh, setPassThresh] = useState(false)
    const [roomList, setRoomList] = useState([])
    const [roomImageList, setRoomImageList] = useState([])
    const [formToggle, setFormToggle] = useState(false)

    useEffect(() => {
        if (slugParam) {
            const currPackage = allPackages.find(item => item.slug === slugParam)

            if (currPackage) {
                const rooms = []

                setName(currPackage.name)
                setPrice(currPackage.price)
                setDesc(currPackage.desc)
                setStartDate(currPackage.startDate)
                setEndDate(currPackage.endDate)
                setRoomList(currPackage.roomList)
                const currProp = allProperties.find(item => item.id === currPackage.property)
                setPropId(currProp.id)
                setPropName(currProp.name)
                setPropType(currProp.type)
                setPropImage(currProp.downloadUrl)
                getAllBatchImages(currProp.id, "properties")
                getAllRoomTypes(currProp.id)
            }
        }
    }, [slugParam, allPackages])

    useEffect(() => {

        roomList.map(item => {
            getAllBatchImages(item.id, 'properties/roomTypes')
        })

        return setRoomTypeImages([])

    }, [roomList.id])

    useEffect(() => {
        if(roomTypeImages.length > 0) {
            roomTypeImages.map(item => {
                setRoomImageList(prevList => [...prevList, {imageUrl: item.imageUrl, parentId: item.parentId}])
            })
        }
    }, [roomTypeImages])

    useEffect(() => {

        if (roomList.length > 0) {
            const rooms = []
            roomList.map(item => {
                rooms.push(allRoomTypes.find(room => room.id === item))
            })
            setRoomList(rooms)
        }
    }, [allRoomTypes])


    const fixMenu = () => {
        if (window.scrollY >= 50) {
            setPassThresh(true)
        } else {
            setPassThresh(false)
        }
    }

    window.addEventListener('scroll', fixMenu)

    useEffect(() => {
        const currImages = []
        currImages.push(propImage)
        images.map(item => currImages.push(item.imageUrl))
        setImageList(currImages)
    }, [images, propId, propImage])

    function handleSubmitClick() {
        setFormToggle(true)
    }

    const roomElements = roomList.map((item, index) => {
        return (
            <PackageRoomItem key={index} roomItem={item} roomImageList={roomImageList}/>
        )
    })

    function convertTimestamp(timestamp) {

        let months = ['January', 'February', 'March', 'April' , 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

        const day = timestamp.split('-')[2]
        const month = timestamp.split('-')[1]
        const year = timestamp.split('-')[0]

        let date = `${day} ${months[parseInt(month)-1]} ${year}`

        return date
    }

    return (
        <>
            <MainHeader />
            <Modal
            isOpen={formToggle}
            contentLabel="Booking Form"
            ariaHideApp={false}
            className='bookingFormModal'
            overlayClassName="bookingFormOverlay">
                <PackageBookingForm 
                    name={name}
                    price={price}
                    desc={desc}
                    startDate={convertTimestamp(startDate)}
                    endDate={convertTimestamp(endDate)}
                    propName={propName}
                    setFormToggle={setFormToggle}
                />
            </Modal>
            <div className='packageContainer'>
                <div className='packageContent'>
                    <div className='packageHero'>
                        <div className={passThresh ? 'packageTitle packageTitleFixed' : 'packageTitle'}>
                            <h1>{name}</h1>
                            <div className='packageActionRow'>
                                <div className='packageOverview'>
                                    {/* <h2>{startDate && convertTimestamp(startDate)} - {endDate && convertTimestamp(endDate)}</h2> */}
                                    <h2 className='packageDetPrice'>{price}</h2>
                                </div>
                                <button className='formButton bookingButton' onClick={handleSubmitClick}>Book Package</button>
                            </div>
                        </div>
                        <div className='packageDetails' style={passThresh ? {marginTop: '150px'} : {marginTop: '0'}}>
                            <div className='imageGrid'>
                                <FbImageLibrary images={imageList} renderOverlay={() => 'View Image'} />
                            </div>
                            <div className='packageInfo'>
                                <div className='packPropTitle'>
                                    <h2>{propName}</h2>
                                    <p>{propType}</p>
                                </div>
                                <p className='packageDesc' dangerouslySetInnerHTML={{ __html: desc }} />
                            </div>
                        </div>
                    </div>
                    <div className='packageRooms'>
                        <h1>Room Details</h1>
                        {roomElements}
                    </div>
                </div>
            </div>
            <MainFooter />
        </>
    )

}

export default PackagesPage