import React from 'react'
import ListItemActions from '../../../base/ListItemActions'


function PackageListItem(props) {

    const {
        id,
        name,
        slug
    } = props

    return (
        <tr className='propertyItem'>
            <td colSpan='2'><p>{name}</p></td>
            <td><ListItemActions actionType='packages' itemId={id} slug={slug}/></td>
        </tr>
    )


}

export default PackageListItem