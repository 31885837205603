import React from 'react';
import { Switch, Route} from 'react-router-dom';

import UserContainer from '../users/UsersContainer'
import PropertiesContainer from '../propertiesGroup/properties/PropertiesContainer';
import PropTypeContainer from '../propertiesGroup/propertytypes/PropTypeContainer';
import HolTypeContainer from '../propertiesGroup/holidaytypes/HolTypeContainer';
import LocationsContainer from '../propertiesGroup/locations/LocationsContainer';
import AmenitiesContainer from '../propertiesGroup/amenities/AmenitiesContainer';
import { ToastContainer} from 'react-toastify';
import TransportContainer from '../propertiesGroup/transport/TransportContainer';
import PackagesContainer from '../propertiesGroup/packages/PackagesContainer';



function AdminContent() {
    return(
        <div className='adminContent'>
            <Switch>
                <Route exact path='/travadm/users' component={UserContainer} />     
                <Route path='/travadm/packages' component={PackagesContainer} />
                <Route path='/travadm/properties' component={PropertiesContainer} />
                <Route path='/travadm/property-types' component={PropTypeContainer} /> 
                <Route path='/travadm/holiday-types' component={HolTypeContainer} /> 
                <Route path='/travadm/locations' component={LocationsContainer} /> 
                <Route path='/travadm/amenities' component={AmenitiesContainer} /> 
                <Route path='/travadm/transport' component={TransportContainer} /> 

            </Switch>
            <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />

        </div>
    );
}

export default AdminContent;