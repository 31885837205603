import React, {useContext} from 'react'
import IslandsListItem from './IslandsListItem'
import { Context } from '../../../../../context/Context';


function IslandsList() {

    const { allIslands  } = useContext(Context)

    const islandItems = allIslands.map(item => {
        return (
            <IslandsListItem
             key={item.id} 
             id={item.id} 
             name={item.name} 
             country={item.country} 
             atoll = {item.atoll}
             code={item.code} 
             type={item.type}
             lat={item.latitude}
             long ={item.longitude}
             slug={item.slug} />
        )
    })


    return (
        <div className='propTypeList'>
            <table>
                <thead>
                    <tr>
                        <th colSpan='2'>Name</th>
                        <th>Code</th>
                        <th>Country</th>
                        <th>Atoll/State</th>
                        <th>Type</th>
                    </tr>
                </thead>
                <tbody>
                    {islandItems}
                </tbody>
            </table>
        </div>
    )
}

export default IslandsList;