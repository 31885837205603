import React, { useState, useContext } from 'react';
import {Context} from '../context/Context.js'
import {Link} from "react-router-dom";

function ResetPass() {
    const [email, setEmail] = useState("")
    const {resetPassword} = useContext(Context)
    function onEmailChange(event){
        const {value} = event.target;
        setEmail(value)
    }
    function handleClick(event){
        event.preventDefault()
        resetPassword(email)
    }

    return (
        <div className='loginContainer'>
            <div className='loginWrapper'>
                <div className='loginImage'>
                    <img className='adminLogo' src={process.env.PUBLIC_URL + '/resources/TravLogoMonoGrad.png'} alt='logo'/>
                </div>
                <div className='loginCard'>
                    <h1 className="resetPasswordHeading">Reset Password</h1>
                    <form className="loginInputFieldGroup">
                        <input
                            className='formInput'
                            placeholder='Enter Email'
                            type='email'
                            onChange = {onEmailChange}
                            value = {email}
                        />
                        <div className='resetButtonRow'>
                            <button
                                className="formButton loginButton"
                                onClick={handleClick}
                                >
                                Reset Password
                            </button>
                        </div>
                    </form>
                </div>
                <Link to="/travadm-login"><p className='loginHomeLink'>Go back to TravGo-Admin Sign-in</p></Link>
            </div>
        </div>
    )
}

export default ResetPass;