import React, { useContext, useRef, useState } from 'react'
import { Context } from '../../context/Context';
import nationalities from './Nationalities'
import emailjs from 'emailjs-com';


function BookingForm(props) {

    const {formToggle, setFormToggle, room, property} = props
    const { sendEmail } = useContext(Context)
    const asterisk = <span style={{color: 'red', fontSize: '13px'}}> * </span>
    const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var today = new Date()
    var minDate = `${today.getFullYear()}-${(today.getMonth()+1) < 10 ? ('0'+(today.getMonth() + 1)) : (today.getMonth()+1)}-${today.getDate() < 10 ? '0'+today.getDate() : today.getDate()}`
    var maxDate = `${today.getFullYear()}-${(today.getMonth()+1) < 10 ? ('0'+(today.getMonth() + 4)) : (today.getMonth()+4)}-${today.getDate() < 10 ? '0'+today.getDate() : today.getDate()}`

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [contactNo, setContactNo] = useState('')
    const [arrival, setArrival] = useState('')
    const [departure, setDeparture] = useState('')
    const [budget, setBudget] = useState('')
    const [nationality, setNationality] = useState('')
    const [adults, setAdults] = useState('')
    const [children, setChildren] = useState('')
    const [infants, setInfants] = useState('')
    const [notes, setNotes] = useState('')


    const nameRef = useRef()
    const emailRef = useRef()
    const contactRef = useRef()
    const arrivalRef = useRef()
    const departureRef = useRef()
    const budgetRef = useRef()
    const adultRef = useRef()


    function handleNameChange(e) {
        const val = e.target.value
        setName(val)
    }

    function handleEmailChange(e) {
        const val = e.target.value
        setEmail(val)
    }

    function handleContactChange(e) {
        const val = e.target.value
        setContactNo(val)
    }

    function handleBudgetChange(e) {
        const val = e.target.value
        setBudget(val)
    }

    function handleAdultChange(e) {
        const val = e.target.value
        setAdults(val)
    }

    function handleChildrenChange(e) {
        const val = e.target.value
        setChildren(val)
    }

    function handleInfantChange(e) {
        const val = e.target.value
        setInfants(val)
    }

    function handleArrivalChange(e) {
        const val = e.target.value
        setArrival(val)
    }

    function handleDepartureChange(e) {
        const val = e.target.value
        setDeparture(val)
    }

    function handleNoteChange(e) {
        const val = e.target.value
        setNotes(val)
    }
    console.log(notes)

    function handleSubmitClick(e) {
        e.preventDefault()

        if(!name) {
            nameRef.current.classList.add('invalidInput')
        } else {
            nameRef.current.classList.remove('invalidInput')
        }

        if(!email) {
            emailRef.current.classList.add('invalidInput')

        } else {
            emailRef.current.classList.remove('invalidInput')
        }

        if(!contactNo) {
            contactRef.current.classList.add('invalidInput')
        } else {
            contactRef.current.classList.remove('invalidInput')
        }

        if(!arrival) {
            arrivalRef.current.classList.add('invalidInput')
        } else {
            arrivalRef.current.classList.remove('invalidInput')
        }

        if(!departure) {
            departureRef.current.classList.add('invalidInput')
        } else {
            departureRef.current.classList.remove('invalidInput')
        }

        if(!budget) {
            budgetRef.current.classList.add('invalidInput')
        } else {
            budgetRef.current.classList.remove('invalidInput')
        }

        if(!adults) {
            adultRef.current.classList.add('invalidInput')
        } else {
            adultRef.current.classList.remove('invalidInput')
        }

        if (!name || !email || !contactNo || !arrival || !departure || !adults || nationality || !emailPattern.test(email)) {
            window.alert(`Please correct the following fields to continue:
            ${(!name || !email || !contactNo || !arrival || !departure || !budget || !adults) ? '\n- Please fill all required fields.' : ''}
            ${!emailPattern.test(email) ? '\n- Please ensure email is in the format "email@example.com"':''} `)
        } else {
            const data = 
            {
                name: name,
                email: email,
                contactNo: contactNo,
                arrival: arrival,
                departure: departure,
                budget: budget,
                adults: adults,
                children: children,
                infants: infants,
                notes: notes,
                propertyObj: property,
                roomObj: room,
                nationality: nationality,
            }

            emailjs.send('default_service', 'template_ej205i6', data, 'user_IHT7ECH7AG4jARD9J1jpt')
            .then(
                (response) => {
                    console.log(response.status, response.text)
                    window.alert('Your booking inquiry has been submitted successfuly!')
                    setFormToggle(false)
                },
                (err) => {
                    console.log(err)
                    window.alert('An error occured, please reload the page and try again.')
                    setFormToggle(false)
                }
            );

        }
        
    }


    function handleNationalityChange(event) {
        const {value} = event.target
        setNationality(value)
    }

    const nationalityItems = nationalities.map((item, index) => {
        return(
            <option key={index} value={item}>{item}</option>
        )
    })


    

    return(
        <div className='bookingFormContainer'>
            <div className='closeBar'>
                <i className="ri-close-circle-line" onClick={() => {setFormToggle(false)}}></i>
            </div>
            <h2>Booking Inquiry for <br/><span>{room.name}<br/>{(property.name).toUpperCase()}</span></h2>
            <form className='bookingForm'>
                <div className='bookingFormFull'>
                    <label>Full Name{asterisk}</label>
                    <input type='text' className='formInput' onChange={handleNameChange} value={name} ref={nameRef}/>
                </div>
                <div className='bookingFormRow'>
                    <div className='bookingFormHalf'>
                        <label>Email Address{asterisk}</label>
                        <input type='email' className='formInput' onChange={handleEmailChange} value={email} ref={emailRef}/>
                    </div>
                    <div className='bookingFormHalf'>
                        <label>Contact Number{asterisk}</label>
                        <input type='tel' className='formInput' onChange={handleContactChange} value={contactNo} ref={contactRef}/>
                    </div>
                </div>
                <div className='bookingFormRow'>
                    <div className='bookingFormHalf'>
                        <label>Arrival{asterisk}</label>
                        <input type='date' className='formInput' min={minDate} onChange={handleArrivalChange} value={arrival} ref={arrivalRef}/>
                    </div>
                    <div className='bookingFormHalf'>
                        <label>Departure{asterisk}</label>
                        <input type='date' className='formInput' min={minDate} onChange={handleDepartureChange} value={departure} ref={departureRef}/>
                    </div>
                </div>                
                <div className='bookingFormRow'>
                    <div className='bookingFormHalf'>
                        <label>Nationality{asterisk}</label>
                        <select defaultValue='default' className='formInput' onChange={handleNationalityChange}>
                            <option value='default'>Select Nationality...</option>
                            {nationalityItems}
                        </select>
                    </div>
                    <div className='bookingFormHalf'>
                        <label>Budget</label>
                        <input type='number' className='formInput budgetInput cordNumber' onChange={handleBudgetChange} value={budget} ref={budgetRef}/>
                    </div>
                </div>
                <div className='bookingFormRow'>
                    <div className='bookingFormTri'>
                        <label>Adults{asterisk}</label>
                        <input type='number' className='formInput cordNumber' onChange={handleAdultChange} value={adults} ref={adultRef}/>
                        <label className='formInstruction'> 12 years and above.</label>

                    </div>
                    <div className='bookingFormTri'>
                        <label>Children</label>
                        <input type='number' className='formInput cordNumber' onChange={handleChildrenChange} value={children}/>
                        <label className='formInstruction'> 2 years to 11 years.</label>
                    </div>
                    <div className='bookingFormTri'>
                        <label>Infants</label>
                        <input type='number' className='formInput cordNumber' onChange={handleInfantChange} value={infants}/>
                        <label className='formInstruction'> Below 2 years of age.</label>
                    </div>
                </div>
                <div className='bookingFormRow'>
                    <div className='bookingFormFull' style={{marginTop: '5px'}}>
                        <label>Notes</label>
                        <textarea type='text' className='formInput' rows='5' onChange={handleNoteChange} value={notes}/>
                    </div>
                </div>

                <div className='bookingFormButtonRow'>
                    <button className='formButton bookingButton' onClick={handleSubmitClick}>Submit</button>
                    <button className='formButton cancelBooking' onClick = {(e) => {
                        e.preventDefault() 
                        setFormToggle(false)
                    }}>Cancel</button>
                </div>
            </form>
        </div>
    )
}

export default BookingForm