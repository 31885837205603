import React from 'react';

import { Route, Switch } from 'react-router'

import AdminActions from '../../base/AdminActions'
import AtollsFormContainer from './LocationsForm/atolls/AtollsFormContainer';
import CountriesFormContainer from './LocationsForm/countries/CountriesFormContainer';
import IslandsFormContainer from './LocationsForm/islands/IslandsFormContainer';
import LocationsListContainer from './LocationsList/LocationsListContainer'




function LocationsContainer() {
    return (
        <div className='propTypeContainer'>
            <AdminActions />
            <Switch>
                <Route exact path='/travadm/locations' component={LocationsListContainer}/>
                <Route exact path='/travadm/locations/countries' component={LocationsListContainer}/>
                <Route exact path='/travadm/locations/countries/add' component={CountriesFormContainer}/>
                <Route exact path='/travadm/locations/countries/:slug/edit' component={CountriesFormContainer} />
                <Route exact path='/travadm/locations/atolls' component={LocationsListContainer}/>
                <Route exact path='/travadm/locations/atolls/add' component={AtollsFormContainer}/>
                <Route exact path='/travadm/locations/atolls/:slug/edit' component={AtollsFormContainer} />
                <Route exact path='/travadm/locations/islands' component={LocationsListContainer}/>
                <Route exact path='/travadm/locations/islands/add' component={IslandsFormContainer}/>
                <Route exact path='/travadm/locations/islands/:slug/edit' component={IslandsFormContainer} />


            </Switch> 
        </div>    
    )
}


export default LocationsContainer;