import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { useLocation } from 'react-router'
import { Context } from '../../../context/Context'
import BatchImageDisplay from './BatchImageDisplay'




function BatchImageUpload(props) {
    const {images, roomTypeImages, setRoomTypeImages, setImages, uploadImageBatch, getAllBatchImages, sleep} = useContext(Context)
    const {parentId, cat, handleBatchUploadClick, setToggle} = props

    var imageDisplay= ''

    useEffect(() => {
        if(cat === 'properties') {
            getAllBatchImages(parentId, 'properties')
            return setImages([]) 
        } else if (cat === 'properties/roomTypes') {
            getAllBatchImages(parentId, 'properties/roomTypes')
            return setRoomTypeImages([]) 
        }
    }, [parentId])

    imageDisplay = 
    <BatchImageDisplay images={cat === 'properties' ? images : roomTypeImages} parentId={parentId} cat={cat}/>

    async function handleImageChange(event) {
        var files = event.target.files
        for (var i = 0; i < files.length; i++) {
            await sleep(1000)
            var image = files[i]
            uploadImageBatch(image, cat, parentId)
        }
    }

    function handleBatchCancelClick() {
        setToggle(false)
    }



    return (
        <div className='atollsInputWrapperFull'>
            <div className="holTypeImageWrapper">
                {
                !parentId ?
                <div className="uploadRow" onClick={handleBatchUploadClick}>
                    <label className="substituteLabel">
                    Upload Image
                    </label>
                </div>
                :
                <div className="uploadRow">
                    <label htmlFor={`${cat}file`} className="substituteLabel">
                    Upload Image
                    </label>     
                </div>
                }
                {
                    cat === "properties/roomTypes" &&
                    <div className='uploadRow' onClick={handleBatchCancelClick}>
                    <label className="formButton cancelButton">
                    Close
                    </label>
                    </div>
                }  
                <input
                    type='file'
                    id={`${cat}file`}
                    accept="image/*"
                    className="substituteImage"
                    onChange={handleImageChange}
                    multiple
                ></input>
            </div>
            {imageDisplay}
        </div>
    )
}

export default BatchImageUpload;