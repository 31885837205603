import React from 'react'
import ListItemActions from '../../../base/ListItemActions'
import ReactTooltip from 'react-tooltip'



function PropTypeListItem(props) {

    const {id, name, desc, slug} = props

    return (
        <>
            <tr className='propertyItem'>
                <td colSpan='2'><p data-tip={desc} data-place='bottom'>{name}</p></td>
                <td>{slug}</td>
                <td><ListItemActions actionType='propertyTypes' itemId={id} slug={slug}/></td>
            </tr>
        </>

    )

}

export default PropTypeListItem;