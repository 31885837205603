import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router';
import { Context } from '../../../../context/Context';
import PropertiesListItem from './PropertiesListItem'


function PropertiesList() {

    const {allProperties, setAllRoomTypes, getAllRoomTypes} = useContext(Context)

    const location = useLocation()
    const [activeProperties, setActiveProperties] = useState([])
    const [trashedProperties, setTrashedProperties] = useState([])

    useEffect(() => {
        const properties = allProperties.filter(property => !property.isTrash)
        const trashedProperties = allProperties.filter(property => property.isTrash)
        setActiveProperties(properties)
        setTrashedProperties(trashedProperties)
    }, [allProperties])

    const propertyItems = activeProperties.map(property => {
        return (
            <PropertiesListItem 
            key={property.id} 
            id={property.id} 
            fileType={property.fileType} 
            name={property.name} 
            type={property.type} 
            city={property.island} 
            isPublished={property.isPublished} 
            slug={property.slug} />
        )
    })

    const trashedPropertyItems = trashedProperties.map(property => {
        return (
            <PropertiesListItem 
            key={property.id} 
            id={property.id} 
            fileType={property.fileType} 
            name={property.name} 
            type={property.type} 
            city={property.island} 
            isPublished={property.isPublished} 
            slug={property.slug} /> 
        )
    })
    
    return (
        <div className='propTypeList'>
            <table>
                <thead>
                    <tr>
                        <th colSpan='2'>Name</th>
                        <th>Listing Type</th>
                        <th>City</th>
                        <th>Room Types</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                {!location.pathname.includes('trash') ? propertyItems : trashedPropertyItems}
                </tbody>
            </table>
        </div>
    )
}

export default PropertiesList;