import React, { useContext, useEffect, useState } from 'react'
import MainHeader from '../shared/MainHeader';

import { Fade } from 'react-slideshow-image';
import TextLoop from "react-text-loop";
import 'react-slideshow-image/dist/styles.css'
import CarouselContainer from './dealcarousel/CarouselContainer';
import { Context } from '../../context/Context';
import MainFooter from '../shared/MainFooter';
import { Helmet } from 'react-helmet';


function LandingPage() {
    const { allProperties, allPackages } = useContext(Context)
    const [durationList, setDurationList] = useState([])
    const images = ['slide4.jpg', 'slide5.jpg', 'slide6.jpg', 'slide7.jpg', 'slide8.jpg', 'slide8.jpg']
    const buzzWords = ['serenity.', 'tranquility.', 'bliss.', 'delight.', 'calm.', 'peace.', 'repose.']

    function getUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    useEffect(() => {
        const durations = []
        allPackages.map(item => durations.push(item.duration))
        setDurationList(durations.filter(getUnique))
    }, [allPackages])

    console.log(durationList)
    console.log(durationList.includes("4 Days"), durationList.includes("7 Days"))

    const slides = images.map(image => {
        return (
            <div className='each-fade' key={image}>
                <div className='image-container'>
                    <img src={`${process.env.PUBLIC_URL}/resources/${image}`} alt='' />
                </div>
            </div>
        )
    })

    return (
        <>
            <MainHeader />
            <Helmet>
                <title>TravGo - Home</title>
            </Helmet>
            <div className='sectionWrapper'>
                <div className='introTextContainer'>
                    <div className='introTextWrapper'>
                        <div className='introTextBorder'>
                            <h1>Follow us<br />to <div><TextLoop children={buzzWords} mask={true} fade={true} interval={5000} /></div></h1>
                            <a href='#featured'><button className='formButton introAction'>Explore Featured Destinations</button></a>
                        </div>
                    </div>
                </div>
                <Fade arrows={false} infinite={true} duration={4000}>
                    {slides}
                </Fade>
            </div>


            <div className='sectionWrapper carouselSection' id='featured'>
                <h1>Featured Properties</h1>
                <CarouselContainer properties={allProperties.filter(item => item.isFeatured)} />
            </div>
            {/* <div className='sectionWrapper aboutSection altSection'> 
                <div className='aboutTextContainer'>
                    <div className='aboutTextWrapper'>
                        <h1>{aboutTitle}</h1>
                        <p>{aboutText}</p>
                        <a href='#hot'><button className='formButton aboutAction'>Explore Hot Deals</button></a>
                    </div>
                    
                </div>
                <img src={`${process.env.PUBLIC_URL}/resources/birdseyeview.png`} alt=''/>
            </div>  */}
            <div className='Cards_Section'>
                <div className="aboutTextWrapper">
                    <h1>At TravGo Maldives...</h1>
                    <p>We offer creative and innovative ideas to allow the maximum enjoyment of the richness of the Maldives, providing an agile, personalized and efficient service, with the backing of professionals of vast experience in the field of tourism. Services we offer include coordination of itineraries for individuals or groups, including hotel reservations, transport, tours, local flights, and any other special requirements necessary.</p>
                    <a href="#hot">
                        <button className="formButton aboutCardAction">Explore Hot Deals</button>
                    </a>
                </div>
                <div className="cards">
                    <div className="card">
                        <img src="resources/business-travel.jpg" className="card__image" alt="" />
                        <div className="card__overlay">
                            <div className="card__header">
                                <div className="card__header-text">
                                    <h3 className="card__title">Business Travel</h3>
                                </div>
                            </div>
                            <p className="card__description">We provide the means for cost effective travel with a corporate prospective in mind. And that means getting the job done right the way you want it. </p>
                        </div>
                    </div>
                    <div className="card">
                        <img src="resources/leisure-travel.jpg" className="card__image" alt="" />
                        <div className="card__overlay">
                            <div className="card__header">
                                <div className="card__header-text">
                                    <h3 className="card__title">Leisure Travel</h3>
                                </div>
                            </div>
                            <p className="card__description">Take a vacation from your everyday life. Stay in a nice hotel or resort, relxing on the beach or in a room. Go on a guided tour and experience our local tourist attractions. Together we plan your vacation itineraries from start to finish to make up your perfect holiday.</p>
                        </div>
                    </div>
                    <div className="card">
                        <img src="resources/tours.jpg" className="card__image" alt="" />
                        <div className="card__overlay">
                            <div className="card__header">
                                <div className="card__header-text">
                                    <h3 className="card__title">Tours and Excursions</h3>
                                </div>
                            </div>
                            <p className="card__description">Apart from lying on the beaches, there are more to explore. Diving in Maldives is out of imagination. Surfers enjoys heavy takeoff waves. Fishing lovers has endless oppotunities as well. There are many more water sports and excursions that will give our guests the chance to truly experience the country and have an unforgettable expeirence. </p>
                        </div>
                    </div>
                    <div className="card">
                        <img src="resources/group-excursions.jpg" className="card__image" alt="" />
                        <div className="card__overlay">
                            <div className="card__header">
                                <div className="card__header-text">
                                    <h3 className="card__title">Group Tours</h3>
                                </div>
                            </div>
                            <p className="card__description">Whether you are looking for discounted group tours, a professional gathering, a family adventure, or just a perfect holiday adventure in Maldives. Travgo Maldives offers the best options for you. </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='sectionWrapper carouselSection' id='hot'>
                <h1>Hot Deals</h1>
                {
                    durationList.includes("7 Days") &&
                    <div className="carouselFilter">
                        <h3>7 Day Packages</h3>
                        <CarouselContainer properties={allPackages.filter(item => item.duration === "7 Days")} type="packages" duration="7 Days" />
                    </div>
                }
                {
                    durationList.includes("6 Days") &&
                    <div className="carouselFilter">
                        <h3>6 Day Packages</h3>
                        <CarouselContainer properties={allPackages.filter(item => item.duration === "6 Days")} type="packages" duration="6 Days" />
                    </div>
                }
                {
                    durationList.includes("5 Days") &&
                    <div className="carouselFilter">
                        <h3>5 Day Packages</h3>
                        <CarouselContainer properties={allPackages.filter(item => item.duration === "5 Days")} type="packages" duration="5 Days" />
                    </div>
                }
                {
                    durationList.includes("4 Days") &&
                    <div className="carouselFilter">
                        <h3>4 Day Packages</h3>
                        <CarouselContainer properties={allPackages.filter(item => item.duration === "4 Days")} type="packages" duration="4 Days" />
                    </div>
                }
                {
                    durationList.includes("3 Days") &&
                    <div className="carouselFilter">
                        <h3>3 Day Packages</h3>
                        <CarouselContainer properties={allPackages.filter(item => item.duration === "3 Days")} type="packages" duration="3 Days" />
                    </div>
                }
                {
                    durationList.includes("2 Days") &&
                    <div className="carouselFilter">
                        <h3>2 Day Packages</h3>
                        <CarouselContainer properties={allPackages.filter(item => item.duration === "2 Days")} type="packages" duration="2 Days" />
                    </div>
                }
                {
                    durationList.includes("1 Day") &&
                    <div className="carouselFilter">
                        <h3>1 Day Packages</h3>
                        <CarouselContainer properties={allPackages.filter(item => item.duration === "1 Day")} type="packages" duration="1 Day" />
                    </div>
                }
            </div>

            <MainFooter />
        </>
    )

}

export default LandingPage;