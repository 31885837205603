import React from 'react'
import { Link } from 'react-router-dom';


function MainFooter() {
    return (
        <>
        <footer className='mainFooterContainer'>
            <div className='footerInfo'>
                {/* <div className='footerMenu'>
                    <div className='footerLogo'>
                        <Link to='/'><img src={`${process.env.PUBLIC_URL}/resources/TravLogoWhite.png`} alt=''/></Link>
                        <div className='logoText'>
                            <h1>TravGo Maldives</h1>
                            <h2>Travel and Tours</h2>
                        </div>
                    </div>
                </div> */}
                <div className='footerDetails'>
                <div className='footerLogo'>
                        <Link to='/'><img src={`${process.env.PUBLIC_URL}/resources/TravLogoWhite.png`} alt=''/></Link>
                        <div className='logoText'>
                            <h1>TravGo Maldives</h1>
                            <h2>Travel and Tours</h2>
                        </div>
                    </div>
                    <div className='footerText'>
                        <h2>Important Links</h2>

                                <p><a href='https://tourism.gov.mv/' target='_blank' rel='noopener noreferrer'>Maldives Ministry of Tourism</a></p>
                                <p><a href='https://immigration.gov.mv/tourist-visa/' target='_blank' rel='noopener noreferrer'>Maldives Immigration</a></p>
                                <p><a href='https://macl.aero/' target='_blank' rel='noopener noreferrer'>Maldives Airports Company</a></p>
                                <p><a href='http://fis.com.mv/via/' target='_blank' rel='noopener noreferrer'>Flights Information</a></p>
                    </div>
                    <div className='footerText footerContent'>
                                <h2>Contact Details</h2>
                                <p>Ma. Feeroaz Vaadhee,<br/>
                                Male', 20229<br/>
                                Republic of Maldives<br/>
                                <br/>
                                Email: <a href='mailto:reservations@travgomaldives.com' target='_blank'>reservations@travgomaldives.com</a><br/>
                                Phone: <a href='tel:+9607971789'>+(960) 7971789</a>,<a href='tel:+9609990179'>+(960) 9990179</a></p>
                                <div className='socialIcons'> 
                                    <a href='https://facebook.com/travgomaldives' target='_blank'><i className="ri-facebook-box-fill"></i></a>
                                    <a href='https://instagram.com/travgomaldives' target='_blank'><i className="ri-instagram-fill"></i></a>
                                    <a href='https://twitter.com/travgomaldives?s=08' target='_blank'><i className="ri-twitter-fill"></i></a>
                                </div>
                    </div>
                </div>
            </div>
            <div className='copyright'>
                <p>Copyright © {new Date().getFullYear()} TravGo Maldives Private Limited All Rights Reserved.</p>
            </div>
        </footer>
        </>
    )

}

export default MainFooter;