import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../../context/Context';
import SelectedTransportItem from './SelectedTransportItem';
import TransportInputElement from './TransportInputElement';

import { useLocation } from 'react-router';



function TransportInput(props) {

    const {propId, setTransportTypes, transportTypes} = props
    const {allTransportTypes} = useContext(Context)
    const [allTransports, setAllTransports] = useState([])
    const [selectedTransports, setSelectedTransports] = useState([])
    const [saveTransports, setSaveTransports] = useState([])
    const path = useLocation().pathname


    useEffect(() => {
        setAllTransports(allTransportTypes)
    },[allTransportTypes])


    useEffect(() => {
        if(path.includes('edit')) {
            if(typeof(transportTypes) !== 'undefined') {
                var slugs = transportTypes.map(item => item.slug)
                setSelectedTransports(transportTypes)
                setAllTransports(allTransportTypes.filter(val => !slugs.includes(val.slug)))
            }
        }
    }, [propId, transportTypes])

    useEffect(() => {
        setTransportTypes(saveTransports)
    },[saveTransports])

    const selectElements =  allTransports.map(item => {
        return(
            <TransportInputElement 
            key={item.slug} 
            transportObj={item} 
            setSelected={setSelectedTransports} 
            selected={selectedTransports}
            all={allTransports}
            setAll={setAllTransports}
            />
        )
    })

    const cancelAction = 
    <div data-tip='Cancel' data-place='bottom' className='transportInputCancel'>
        <i className="ri-close-circle-line"></i>
    </div> 

    const saveAction = 
    <div data-tip='Add' data-place='bottom'>
        <i className="ri-checkbox-circle-line"></i>
    </div>

    const selectedElements = selectedTransports.map((item, index) => {
        return(
            <SelectedTransportItem 
            key={index} 
            index={index}
            transportObj={item}
            all={allTransports}
            setAll={setAllTransports}
            selected={selectedTransports}
            setSelected={setSelectedTransports}
            save={saveTransports}
            setSave={setSaveTransports}
            />
        )
    })

    return(
        <div className='transportInputContainer'>
            <div className='transportSelectionContainer'>
                <label>
                    Click item to add transport type:
                </label>
                <div className='transportSelectArea'>
                    {selectElements}
                </div>
                <div className='propTypeContainer'>
                    <label>
                        Selected transport types:
                    </label>
                    <div className='transTypeList'>
                        <table>
                            <thead>
                                <tr>
                                    <th colSpan='2'>Transport</th>
                                    <th>Duration</th>
                                    <th></th>

                                </tr>
                            </thead>
                            <tbody>
                                {selectedElements}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
    
}

export default TransportInput;