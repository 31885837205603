import React from 'react'
import AdminActions from '../../base/AdminActions'
import { Route, Switch } from 'react-router'
import PackagesForm from './PackagesForm/PackagesForm'
import PackagesList from './PackagesList/PackagesList'



function PackagesContainer() {
    return(
        <div className='propertiesContainer'>
            <AdminActions />
            <Switch>
                <Route exact path='/travadm/packages' component={PackagesList}/>
                <Route exact path='/travadm/packages/add' component={PackagesForm}/>
                <Route exact path='/travadm/packages/:slug/edit' component={PackagesForm}/>
            </Switch> 
        </div>
    )
}

export default PackagesContainer