import React, {useContext, useState, useRef} from 'react';

import {Context} from '../../context/Context'

function AddUserForm() {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [name, setName] = useState('')
    const [level, setLevel] = useState(0)
    const [validationPassed, setValidationPassed] = useState('')

    const emailRef = useRef(null)
    const nameRef = useRef(null)
    const passwordRef = useRef(null)
    const levelRef = useRef(null)    



    const {handleToggle, handleRegister} = useContext(Context)

    function handleCancelClick(event) {
        event.preventDefault()
        if (name !== '' || email !== '' || level !== 0 || password !== '') {
            if(window.confirm('Are you sure you want to cancel? All your changes will be lost')){
                handleToggle(false)
            }
        } else {
            handleToggle(false)
        }
    }

    function validate(event) {

        event.preventDefault()

        const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const namePattern = /^[_A-z]*((\s)*[_A-z])*$/;
        var namePassed = false
        var emailPassed = false
        var levelPassed = false
        var passwordPassed = false




        if (emailPattern.test(email)) {
            emailRef.current.classList.remove('invalidInput')
            emailPassed = true
        } else {
            emailRef.current.classList.add('invalidInput')
            emailPassed = false
        }

        if (password !== '') {
            passwordRef.current.classList.remove('invalidInput')
            passwordPassed = true
        } else {
            passwordRef.current.classList.add('invalidInput')
            passwordPassed = false
        }

        if(level === 0) {
            levelRef.current.classList.add('invalidInput')
            levelPassed = false
        } else {
            levelRef.current.classList.remove('invalidInput')
            levelPassed = true
        }

        if (name === '') {
            nameRef.current.classList.add('invalidInput')
            namePassed = false
        } else {
            if (namePattern.test(name)) {
                nameRef.current.classList.remove('invalidInput')
                namePassed = true
            } else {
                nameRef.current.classList.add('invalidInput')
                namePassed = false
            }
        }

        if(namePassed && emailPassed && passwordPassed && levelPassed) {
            setValidationPassed(true)
            handleSaveClick()
        } else {
            setValidationPassed(false)

        }

    }

    function handleSaveClick() {


        if(name !== '' && email !== '' && level !== 0 && password !== '') {
            if(window.confirm('Are you sure you want to save this user?')) {
                handleRegister(email, password, name, level)
            }
        } else {
            window.alert('Please fill in all the fields.')
        }

    }

    function handleEmailChange(event) {
        const {value} = event.target
        setEmail(value)
    }

    function handlePasswordChange(event) {
        const {value} = event.target
        setPassword(value)
    }

    function handleLevelChange(event) {
        const {value} = event.target
        setLevel(value)
    }

    function handleNameChange(event) {
        const {value} = event.target
        setName(value)
    }

    return (
        <div className='userFormContainer'>
            {validationPassed === false && <p className='validationText'>Please correct all fields highlighted in red.</p>}
            <form>
            <div className='userFieldGroup'>
                    <input 
                        className='formInput userInput' 
                        placeholder='Name (ex: Ahusan Mohamed)'
                        name = 'name'
                        type='text'
                        onChange={handleNameChange}
                        value={name}
                        ref={nameRef}
                    />

                    <select className='formInput userInput' value={level} onChange={handleLevelChange} ref={levelRef}>
                        <option value="0" disabled hidden>User Level</option>
                        <option value="1">Support</option>
                        <option value="2">Admin</option>
                        <option value="3">Maintainer</option>
                    </select>
                </div>

                <div className='userFieldGroup'>
                    <input 
                        className='formInput userInput' 
                        placeholder='Email (ex: ahusan@example.com)'
                        type='email'
                        onChange={handleEmailChange}
                        value={email}
                        ref={emailRef}
                    />
                    <input 
                        className='formInput userInput' 
                        placeholder='Password'
                        type='password'
                        onChange={handlePasswordChange}
                        value={password}
                        ref={passwordRef}
                    />

                </div>
                <div className='userFormButtonRow'>
                    <button className='formButton saveButton' onClick={validate}>Add User</button>
                    <button className='formButton cancelButton' onClick={handleCancelClick}>Cancel</button>
                </div>
            </form>
        </div>
    )

}

export default AddUserForm;