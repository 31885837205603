


import React from 'react'
// import { IconPickerItem } from 'react-fa-icon-picker'
import ListItemActions from '../../../base/ListItemActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas)


function AmenitiesListItems(props) {

    const {id, icon, name, desc, slug} = props

    const nameDataItem = 
    <div className='listItemTextContainer'>
        <div className='listItemTextWrapper'><p data-tip={desc} data-place='bottom'>{name}</p></div>
    </div>

    const slugDataItem =
    <div className='listItemTextWrapper'>
        <p>{slug}</p>
    </div>

    const iconDataItem =
    <div className = 'listItemImageContainer'>
        <FontAwesomeIcon 
                icon = {icon}
                size="2x"
        />
    </div>

    return (
        <>
            <tr className='holTypeItem'>
                <td colSpan='2'>{iconDataItem}</td>
                <td>{nameDataItem}</td>
                <td>{slugDataItem}</td>
                <td><ListItemActions actionType='amenities' itemId={id} slug={slug}/></td>
            </tr>
        </>

    )

}

export default AmenitiesListItems;