import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router';
import { Context } from '../../../context/Context';
import LoadingCard from './LoadingCard';
import PropertyCard from './PropertyCard'

function CarouselContainer(props) {
    const { contextLoad } = useContext(Context)
    const { properties, type, duration } = props
    const [start, setStart] = useState(0)
    const [end, setEnd] = useState(6)
    const [startInactive, setStartInactive] = useState(false)
    const [endInactive, setEndInactive] = useState(false)

    const path = useLocation().pathname

    const slicedProperties = properties.slice(start, end)

    useEffect(() => {
        if (start === 0) {
            setStartInactive(true)
        } else {
            setStartInactive(false)
        }

        if (slicedProperties.length < 6) {
            setEndInactive(true)

        } else {
            setEndInactive(false)
        }

        if (properties.length <= 6) {
            setEndInactive(true)
            setStartInactive(true)
        } else {
            setEndInactive(false)
            setStartInactive(false)
        }

    }, [start, slicedProperties])

    function createUUID() {
        var dt = new Date().getTime();
        var uuid = 'yxxyxx'.replace(/[xy]/g, function (c) {
            var r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            // eslint-disable-next-line no-mixed-operators
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    }

    console.log(properties, type)


    const cardElements = properties.map((item) => {
        return (
            <PropertyCard key={item.id} name={item.name} rating={item.rating} slug={item.slug} imageUrl={item.downloadUrl} />
        )
    })

    const paginatedElements = properties.slice(start, end).map((item) => {
        const uniqueKey = createUUID()
        return (
            <div key={item.id} className='paginatedContainer'>
                <PropertyCard key={uniqueKey} name={item.name} rating={item.rating} slug={item.slug} imageUrl={item.downloadUrl} />
            </div>
        )
    })

    const packageElements = properties.filter(p => p.duration === duration).slice(start, end).map((item) => {
        const uniqueKey = createUUID()
        console.log(item, type)
        return (
            <div className='paginatedContainer'>
                <PropertyCard key={uniqueKey} name={item.name} slug={item.slug} property={item.property} price={item.price} type={'package'}/>
            </div>
        )
    })

    const carousel =
        <div className='carouselContainer'>
            {
            type === 'packages' ?
            packageElements
            :
            cardElements
            }
        </div>

    function handleBackClick() {
        if (start !== 0) {
            setStart(start - 6)
            setEnd(end - 6)
        } else {
            setStartInactive(true)
        }

    }

    function handleForwardClick() {
        if (properties.length > 6 && slicedProperties.length === 6) {
            setStart(start + 6)
            setEnd(end + 6)
        } else {
            setEndInactive(true)
        }
    }

    const paginate =
        <div className='paginateContainer'>
            <i className={!startInactive ? "ri-arrow-left-s-line paginateActive" : "ri-arrow-left-s-line paginateInactive"} onClick={handleBackClick} disabled></i>
            <div className='carouselContainer'>
                {type === 'packages' ?
                packageElements
                :
                paginatedElements
                }
            </div>
            <i className={!endInactive ? "ri-arrow-right-s-line paginateActive" : "ri-arrow-right-s-line paginateInactive"} onClick={handleForwardClick}></i>
        </div>


    const loading =
        <div className='carouselContainer'>
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
        </div>

    function shouldPaginate() {
        if (path.includes('stay')) {
            return false
        } else if (window.screen.width < 800) {
            return false
        } else {
            return true
        }
    }

    return (

        contextLoad ?
            shouldPaginate()
                ?
                <>
                    {paginate}
                </>
                :
                <>
                    {carousel}
                </>
            :
            <>
                {loading}
            </>

    )
}

export default CarouselContainer;