import React from 'react';

import AdminHeader from './AdminHeader'

import AdminContentWrapper from './AdminContentWrapper';

function Admin() {
    return(
        <div className='adminContainer'>
            <AdminHeader />
            <AdminContentWrapper />
        </div>
    );
}

export default Admin;