import React from 'react'
import PropertiesFilter from './PropertiesFilter';
import PropertiesList from './PropertiesList'

function PropertiesListContainer() {
    return(
        <div>
            <PropertiesList />
        </div>
    )
}

export default PropertiesListContainer;