import React, { useState } from 'react'
import { Link } from 'react-router-dom'

function SubMenu(props) {

    const {items, hoverState, setHoverState, active} = props

    const menuItems = items.map((item, index) => {
        return(
            <Link key={index} to={item.link}>{item.name}</Link>
        )
    })

    return(
        <div 
        className='subNavContainer'
        onMouseEnter={() => setHoverState(true)}
        onMouseLeave={() => setHoverState(false)}
        > 
            <div className={active ? 'subNavContent headerActive active': 'subNavContent'}>
                {menuItems}
            </div>
        </div>
    )
}

export default SubMenu;