import React, { useEffect } from 'react'
import MoreInfoLink from '../shared/MoreInfoLink'
import Modal from 'react-modal';
import { useState } from 'react';
import RoomDetails from './RoomDetails';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'



function RoomItem(props) {
    
    const {id, name, size, downloadUrl, adultCap, childCap, code, propName, propSlug, allAmenities, amenities, featuredAmenities} = props
    const [roomAmenities, setRoomAmenities] = useState([])

    useEffect(() => {
        var currentAmenities = getAmenities(featuredAmenities)
        setRoomAmenities(currentAmenities)

    }, [allAmenities])


    function getAmenities(roomAmenities) {
        var filteredAmenities = []
        if (roomAmenities) {
            roomAmenities.map(item => filteredAmenities.push(allAmenities.find(amenity => amenity.id === item)))
            return(filteredAmenities)
        }
    }

    console.log(roomAmenities)

    function setDetailItems(n) {
        try {
            if(typeof(roomAmenities) !== undefined) {
                if(typeof(roomAmenities[n]) !== undefined) {
                    return(
                        <>
                            <FontAwesomeIcon 
                            icon={roomAmenities[n].icon} 
                            />
                            <p>{roomAmenities[n].name}</p>  
                        </>
                    ) 
                }
            }
        } catch (e) {
        }
    }


    return (
        <div className='roomItemContainer'>
                <div className='roomItemImage'>
                    <Link to = {`/properties/${propSlug}/${code}`}>  
                        <img src={downloadUrl ? downloadUrl : `${process.env.PUBLIC_URL}/resources/placeholderImage.png`} alt='' />
                    </Link>  
                </div>
            <div className='roomInfo'>
                <h1>{name}</h1>
                <div className='roomSummary'>
                    <div className='roomInfoItem'>
                        <div className='detailItem'>
                                <FontAwesomeIcon 
                                icon={'border-style'} 
                                />
                                <p>{size} sq ft</p>  
                        </div>
                        <div className='detailItem'>
                            {setDetailItems(0)}
                        </div>
                    </div>    
                    <div className='roomInfoItem'>
                        <div className='detailItem'>
                                <FontAwesomeIcon 
                                icon={'user-friends'} 
                                />
                                <p>{`Sleeps ${parseInt(adultCap) + parseInt(childCap)}`}</p>  
                        </div>
                        <div className='detailItem'>
                            {setDetailItems(1)}
                        </div>
                    </div>
                    <div className='moreInfoRow'>
                        <Link to = {`/properties/${propSlug}/${code}`}>  
                            <MoreInfoLink text={'Explore'} icon={'ri-arrow-right-s-line'} style={{padding: '20px 0'}}/>
                        </Link>
                    </div>
                </div>
            </div>

        </div>
    )

}

export default RoomItem