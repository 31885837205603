import { functions } from 'firebase'
import React, { useContext, useState } from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router'
import { Context } from '../../context/Context'
import CarouselContainer from '../frontpage/dealcarousel/CarouselContainer'
import MainFooter from '../shared/MainFooter'
import MainHeader from '../shared/MainHeader'

function StayPage() {
    const {allProperties, allPropertyTypes, allAtolls} = useContext(Context)
    const [elements, setElements] = useState([])
    const [filterValue, setFilterValue] = useState('')
    const [toggleFilters, setToggleFilters] = useState(false)
    const slugParam = useParams().slug
    
    useEffect(() => {
        const currProperties = allProperties.filter(item => item.type.replace(/\s+/g, '-').toLowerCase() === slugParam)
        setElements(currProperties.filter(item => !item.isTrash))
    }, [allPropertyTypes, allProperties, slugParam])

    function handleFilterChange(event) {
        const {value} = event.target
        setFilterValue(value)
        
    }

    function handleFilterClick() {
        setToggleFilters(!toggleFilters)
    }

    const atollItems = allAtolls.map(item => {
        return(
            <div key={item.id}>
                <input value={item.name} type='checkbox'/>
                <label>{item.name}</label>
            </div>
        )
    })

    function handleCheckboxChange(event) {
        const {id, checked} = event.target
        console.log(event.target.id, checked)

        if (id === 'rating7' && checked) {

        }

        if (id === 'rating6' && checked) {
            
        }

        if (id === 'rating5' && checked) {
            
        }

        if (id === 'rating4' && checked) {
            
        }

        if (id === 'rating3' && checked) {
            
        }

        if (id === 'rating2' && checked) {
            
        }

        if (id === 'rating1' && checked) {
            
        }
    }

    const nonEmpty = 
    <div className='stayContainer'>
        <div className='filterBar'>
            <div className='searchBar'>
                <label>Search Properties</label>
                <input className='formInput stayFilter' value={filterValue} onChange={handleFilterChange}/>
            </div>
            {/* <div className='filterSection'>
                <button onClick={handleFilterClick} className='filterButton formButton'>Filter</button>
            </div> */}
            {/* <div className='filterContainer' hidden={!toggleFilters}>
                <div className='filterCard'>
                    <div className='ratingFilter'>
                        <h3>Star Rating</h3>
                        <div className='filterItems'>
                            <div>
                                <input id='rating7' value='7' type='checkbox' onChange={handleCheckboxChange}/>
                                <label>7 Stars</label>
                            </div>

                            <div>
                                <input id='rating6' value='6' type='checkbox' onChange={handleCheckboxChange}/>
                                <label>6 Stars</label>
                            </div>

                            <div>
                                <input id='rating5' value='5' type='checkbox' onChange={handleCheckboxChange}/>
                                <label>5 Stars</label>
                            </div>

                            <div>
                                <input id='rating4' value='4' type='checkbox' onChange={handleCheckboxChange}/>
                                <label>4 Stars</label>
                            </div>

                            <div>
                                <input id='rating3' value='3' type='checkbox' onChange={handleCheckboxChange}/>
                                <label>3 Stars</label>
                            </div>

                            <div>
                                <input id='rating2' value='2' type='checkbox' onChange={handleCheckboxChange}/>
                                <label>2 Stars</label>
                            </div>

                            <div>
                                <input id='rating1' value='1' type='checkbox' onChange={handleCheckboxChange}/>
                                <label>1 Star</label>
                            </div>
                        </div>
                    </div>
                    <div className='atollFilter'>
                        <h3>Regions/Atolls</h3>
                        <div className='filterItems'>
                            {atollItems}
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
        <div className='stayWrapper'>
            <CarouselContainer properties={
                filterValue ?
                elements.filter(item => item.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(filterValue.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")))
                :
                elements
            }/>
            {/* .filter(item => item.name.toLowerCase().includes(filterValue.toLowerCase())) */}
        </div>
    </div>

    const empty = 
    <div className='stayContainer'>
        <div className='filterBar'>
            <div className='searchBar'>
                <label>Search Properties</label>
                <input className='formInput stayFilter' value={filterValue} onChange={handleFilterChange}/>
            </div>
            {/* <div className='filterSection'>
                <button onClick={handleFilterClick} className='filterButton formButton'>Filter</button>
            </div>
            <div className='filterContainer' hidden={!toggleFilters}>
                <div className='filterCard'>
                    <div className='ratingFilter'>
                        <h3>Star Rating</h3>
                        <div className='filterItems'>
                            <div>
                                <input value='7' type='checkbox' onChange={handleCheckboxChange}/>
                                <label>7 Stars</label>
                            </div>

                            <div>
                                <input value='6' type='checkbox' onChange={handleCheckboxChange}/>
                                <label>6 Stars</label>
                            </div>

                            <div>
                                <input value='5' type='checkbox' onChange={handleCheckboxChange}/>
                                <label>5 Stars</label>
                            </div>

                            <div>
                                <input value='4' type='checkbox' onChange={handleCheckboxChange}/>
                                <label>4 Stars</label>
                            </div>

                            <div>
                                <input value='3' type='checkbox' onChange={handleCheckboxChange}/>
                                <label>3 Stars</label>
                            </div>

                            <div>
                                <input value='2' type='checkbox' onChange={handleCheckboxChange}/>
                                <label>2 Stars</label>
                            </div>

                            <div>
                                <input value='1' type='checkbox' onChange={handleCheckboxChange}/>
                                <label>1 Star</label>
                            </div>
                        </div>
                    </div>
                    <div className='atollFilter'>
                        <h3>Regions/Atolls</h3>
                        <div className='filterItems'>
                            {atollItems}
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
        <div className='stayWrapper'>
            {`No properties available match provided conditions.`}
        </div>
    </div>


    return(
        <>            
            <MainHeader />
            {
                elements.filter(item => item.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(filterValue.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))).length > 0 ?
                    elements.length > 0 ?
                    nonEmpty
                    :
                    empty
                :
                empty
            }
            <MainFooter />
        </>
    )
}

export default StayPage